import { Box, Typography } from "@mui/material";
import { ModalDialog } from "../../../components/ModalDialog/ModalDialog";
import {
  Container,
  MainInfo,
  ItemRow,
  AdditionalLaws,
  Footer,
  Label,
  Info,
  OptionContainer,
  ButtonContainer,
  ItemColumn,
  ImagePreviewContainer,
  ImagePreview,
  UploadedImages,
  UploadedImagesContainer,
  Versions,
} from "./EntityViewPopup.styles";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

import { LAButton } from "../../../components/LAButton/LAButton";
import { shallow } from "zustand/shallow";
import useEntityStore from "../../../state/compliance/compliance-store";
import dayjs from "dayjs";
import { Fragment, useState } from "react";
import { Lightbox } from "yet-another-react-lightbox";
import { MultiSelect } from "../../../components/MultiSelect/MultiSelect";

export const EntityViewPopup = ({ onClose, onEdit, onCancel }) => {
  const [
    entityToView,
    complianceVersions,
    getEntity,
    currentVersionId,
    draftId,
  ] = useEntityStore(
    (state) => [
      state.entityToView,
      state.complianceVersions,
      state.getEntity,
      state.currentVersionId,
      state.draftId,
    ],
    shallow
  );
  const [loadingEntity, setLoadingEntity] = useState(false);

  const [selectedVersion, setSelectedVersion] = useState(
    complianceVersions[0].value
  );
  const [openIndex, setOpenIndex] = useState(-1);

  return (
    <ModalDialog
      title="Entity Details"
      onClose={onClose}
      additionalTopHeader={`Created: [${dayjs(entityToView?.created_at).format(
        "MM/DD/YYYY"
      )}] ${
        entityToView?.updated_at
          ? `Updated: [${dayjs(entityToView?.updated_at).format("MM/DD/YYYY")}]`
          : ""
      }`}
      width={752}
      isLoading={loadingEntity}
    >
      {complianceVersions.length > 1 ? (
        <Versions>
          <MultiSelect
            multiple={false}
            options={complianceVersions}
            showErrorMessage
            size="small"
            value={selectedVersion}
            onChange={async (value) => {
              setLoadingEntity(true);
              await getEntity(value.target.value);
              setSelectedVersion(value.target.value);
              setLoadingEntity(false);
            }}
          />
        </Versions>
      ) : null}

      <Container>
        <MainInfo>
          <ItemRow>
            <DataOption label="Entity type" info={entityToView?.type} />
            <DataOption
              label="Contact name"
              info={entityToView?.contact_name}
            />
          </ItemRow>
          <ItemRow>
            {entityToView?.address ? (
              <DataOption label="Address" info={entityToView?.address} />
            ) : (
              <DataOption label="Name" info={entityToView?.name} />
            )}

            <DataOption
              label="Contact email"
              info={entityToView?.contact_email}
            />
          </ItemRow>
          <ItemRow>
            {entityToView?.address ? (
              <DataOption label="Name" info={entityToView?.name} />
            ) : (
              <DataOption label="State" info={entityToView?.state} />
            )}

            <DataOption
              label="Contact phone"
              info={entityToView?.contact_phone}
            />
          </ItemRow>
          <ItemRow>
            <DataOption
              label="STR allowed?"
              info={entityToView?.is_str_allowed ? "Yes" : "No"}
            />
            <DataOption
              label="Date law was added"
              info={
                entityToView?.law_added_at &&
                dayjs(entityToView?.law_added_at).format("MM/DD/YYYY")
              }
            />
          </ItemRow>
          {entityToView?.is_permit_required !== null ? (
            <ItemRow>
              <DataOption
                label="Permit required?"
                info={entityToView.is_permit_required ? "Yes" : "No"}
              />
            </ItemRow>
          ) : null}
          <ItemRow>
            <DataOption
              label="Legislation name"
              info={entityToView.legislation_name}
            />
          </ItemRow>
          <ItemRow>
            <DataOption
              label="Legislation website"
              info={entityToView.legislation_web_url}
            />
          </ItemRow>
          <ItemRow>
            <DataOption
              label="Legislation file upload"
              info={entityToView.legislation_upload_url}
            />
          </ItemRow>
        </MainInfo>
        <AdditionalLaws>
          <Typography variant="body2">Additional STR Laws</Typography>
          <Box display={"flex"} width={"100%"} gap={2.5}>
            <ItemColumn>
              <DataOption
                label="Is Owner Occupancy Required?"
                info={
                  entityToView.is_owner_occupancy_required === null
                    ? ""
                    : entityToView.is_owner_occupancy_required
                    ? "Yes"
                    : "No"
                }
              />
              {entityToView?.is_owner_occupancy_required ? (
                <DataOption
                  label="Notes"
                  info={entityToView.owner_occupancy_required_notes}
                />
              ) : null}
              <DataOption
                label="Entity Limits on Number of Permit?"
                info={
                  entityToView.is_entity_limits_number_permit === null
                    ? ""
                    : entityToView.is_entity_limits_number_permit
                    ? "Yes"
                    : "No"
                }
              />
              {entityToView?.is_entity_limits_number_permit ? (
                <DataOption
                  label="Notes"
                  info={entityToView.entity_limits_number_permit_notes}
                />
              ) : null}
              <DataOption
                label="Limit on number or length of stays?"
                info={
                  entityToView.is_limit_length_stay === null
                    ? ""
                    : entityToView.is_limit_length_stay
                    ? "Yes"
                    : "No"
                }
              />
              {entityToView?.is_limit_length_stay ? (
                <DataOption
                  label="Notes"
                  info={entityToView.limit_length_stay_notes}
                />
              ) : null}
            </ItemColumn>
            <ItemColumn>
              <DataOption
                label="License Transferability to other Parties?"
                info={
                  entityToView.is_license_transferability === null
                    ? ""
                    : entityToView.is_license_transferability
                    ? "Yes"
                    : "No"
                }
              />
              {entityToView?.is_license_transferability ? (
                <DataOption
                  label="Notes"
                  info={entityToView.license_transferability_notes}
                />
              ) : null}
              <DataOption
                label="Entity Limits on Permits per Owner?"
                info={
                  entityToView.is_entity_limits_permit_owner === null
                    ? ""
                    : entityToView.is_entity_limits_permit_owner
                    ? "Yes"
                    : "No"
                }
              />
              {entityToView?.is_entity_limits_permit_owner ? (
                <DataOption
                  label="Notes"
                  info={entityToView.entity_limits_permit_owner_notes}
                />
              ) : null}
              <DataOption
                label="Are there Zoning Restrictions?"
                info={
                  entityToView.is_zone_restricted === null
                    ? ""
                    : entityToView.is_zone_restricted
                    ? "Yes"
                    : "No"
                }
              />
              {entityToView?.is_zone_restricted ? (
                <DataOption
                  label="Notes"
                  info={entityToView.zone_restriction_notes}
                />
              ) : null}
            </ItemColumn>
          </Box>
        </AdditionalLaws>
        {entityToView?.images.length ? (
          <UploadedImagesContainer>
            <Typography variant="body2">Uploaded images</Typography>
            <UploadedImages>
              {entityToView?.images.map(({ id, url }, index) => (
                <ImagePreviewContainer key={id}>
                  <ImagePreview
                    src={url}
                    onClick={() => {
                      setOpenIndex(index);
                    }}
                  />
                </ImagePreviewContainer>
              ))}
            </UploadedImages>
          </UploadedImagesContainer>
        ) : null}

        <Lightbox
          index={openIndex}
          open={openIndex >= 0}
          plugins={[Fullscreen]}
          close={() => {
            setOpenIndex(-1);
          }}
          slides={entityToView?.images.map((el) => {
            return {
              src: el.url,
            };
          })}
        />
        <Footer>
          <ItemRow>
            <DataOption label="Notes" info={entityToView.notes} />
          </ItemRow>
          {entityToView.is_draft ||
          (currentVersionId === selectedVersion && !draftId) ? (
            <Box
              display={"flex"}
              gap={2.5}
              width={"100%"}
              justifyContent={"center"}
              mt={6}
            >
              <ButtonContainer>
                <LAButton
                  text="Cancel"
                  variant="secondary"
                  fullWidth
                  onClick={onCancel}
                />
              </ButtonContainer>
              <ButtonContainer>
                <LAButton
                  text={entityToView.is_draft ? "Edit Draft" : "Edit Entity"}
                  fullWidth
                  onClick={onEdit}
                />
              </ButtonContainer>
            </Box>
          ) : null}
        </Footer>
      </Container>
    </ModalDialog>
  );
};

export const DataOption = ({ label, info }) => {
  return (
    <OptionContainer>
      <Label component="p">
        {label}
        <Info component="span">
          {info?.split("\n").map((part, i) => {
            return (
              <Fragment key={part + i}>
                {part}
                <br />
              </Fragment>
            );
          })}
        </Info>
      </Label>
    </OptionContainer>
  );
};
