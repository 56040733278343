import { Typography } from "@mui/material";
import logo from "../../assets/icons/Logo.svg";
import menuIcon from "../../assets/icons/MenuIcon.svg";
import chevronDown from "../../assets/icons/ChevronDown.svg";
import chevronUp from "../../assets/icons/ChevronUp.svg";
import logoutIcon from "../../assets/icons/LogoutIcon.svg";
import gearIcon from "../../assets/icons/GearIcon.svg";
import * as Styles from "./Header.styles";
import { useState } from "react";
import useUserStore from "../../state/user/user-store";
import { shallow } from "zustand/shallow";
import { useLocation, useNavigate } from "react-router-dom";
import { useDisclosure } from "../../hooks/useDisclosure";
import { SideBarMenu } from "../SideBarMenu/SideBarMenu";
import { COLORS } from "../../theme/colors";
import useQueryStore from "../../state/query/query-store";

export const SignInHeader = ({ children, withBorder = true }) => {
  return (
    <>
      <Styles.HeaderContainer withBorder={withBorder}>
        <Styles.HeaderInnerContainer>
          <Styles.VerticallyCentered>
            <Styles.VerticallyCentered>
              <Styles.Logo src={logo} alt="PropertyGuardLogo" />
            </Styles.VerticallyCentered>
          </Styles.VerticallyCentered>
          {children}
        </Styles.HeaderInnerContainer>
      </Styles.HeaderContainer>
    </>
  );
};

export const Header = ({ children, withBorder = true }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const sideMenu = useDisclosure();

  const [resetPageProps] = useQueryStore(
    (state) => [state.resetPageProps],
    shallow
  );

  const [signout, is_staff, first_name, last_name] = useUserStore(
    (state) => [
      state.signout,
      state.is_staff,
      state.first_name,
      state.last_name,
    ],
    shallow
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
    signout();
    navigate("/signin");
  };
  return (
    <>
      <Styles.HeaderContainer withBorder={withBorder}>
        <Styles.HeaderInnerContainer>
          <Styles.HeaderContent>
            <Styles.HeaderLeftPart>
              <Styles.StyledIconButton onClick={sideMenu.open}>
                <Styles.MenuButton src={menuIcon} alt="menuIcon" />
              </Styles.StyledIconButton>
              {location?.pathname === "/home" ? (
                <Styles.VerticallyCenteredLink
                  onClick={resetPageProps}
                  data-testid="header-image-link"
                >
                  <Styles.Logo src={logo} alt="PropertyGuardLogo" />
                </Styles.VerticallyCenteredLink>
              ) : (
                <Styles.VerticallyCenteredLink
                  to="/home"
                  data-testid="header-image-link"
                >
                  <Styles.Logo src={logo} alt="PropertyGuardLogo" />
                </Styles.VerticallyCenteredLink>
              )}
            </Styles.HeaderLeftPart>

            <Styles.StyledProfile onClick={handleClick}>
              <Styles.StyledAvatar>
                <Styles.CapitalizeText variant="body7">
                  {`${first_name ? first_name[0] : "P"}${
                    last_name ? last_name[0] : "G"
                  } `}
                </Styles.CapitalizeText>
              </Styles.StyledAvatar>
              <Typography variant="body2">
                {`${first_name ?? "Name"} ${last_name ?? "Surname"}`}
              </Typography>
              <Styles.ChevronIcon src={chevronDown} alt="chevronDownIcon" />
            </Styles.StyledProfile>
            <Styles.StyledMenu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MainMenuItem
                onClose={handleClose}
                firstName={first_name}
                lastName={last_name}
              />
              {is_staff ? (
                <MenuItem
                  onClickHandle={() => navigate("/settings")}
                  text="Settings"
                  icon={gearIcon}
                  alt="setting-icon"
                />
              ) : null}
              <MenuItem
                onClickHandle={handleLogOut}
                text="Log Out"
                icon={logoutIcon}
                alt="logout-icon"
              />
            </Styles.StyledMenu>
          </Styles.HeaderContent>
          {children}
        </Styles.HeaderInnerContainer>
      </Styles.HeaderContainer>
      <Styles.StyledDrawer
        open={sideMenu.isOpen}
        onClose={sideMenu.close}
        BackdropProps={{
          style: { backgroundColor: COLORS.NEUTRAL6, opacity: 0.6 },
        }}
      >
        <SideBarMenu onClose={sideMenu.close} />
      </Styles.StyledDrawer>
    </>
  );
};

const MainMenuItem = ({ firstName, lastName, onClose }) => {
  return (
    <Styles.StyledMainMenuItem onClick={onClose}>
      <Styles.StyledAvatar>
        <Styles.CapitalizeText variant="body7">
          {`${firstName ? firstName[0] : "P"}${lastName ? lastName[0] : "G"} `}
        </Styles.CapitalizeText>
      </Styles.StyledAvatar>
      <Typography variant="body2">
        {`${firstName ?? "Name"} ${lastName ?? "Surname"}`}
      </Typography>
      <Styles.ChevronIcon src={chevronUp} alt="chevronDownIcon" />
    </Styles.StyledMainMenuItem>
  );
};

const MenuItem = ({ icon, text, onClickHandle, alt }) => {
  return (
    <Styles.StyledMenuItem onClick={onClickHandle}>
      <Styles.IconContainer>
        <Styles.StyledIcon src={icon} alt={alt || "menu-icon"} />
      </Styles.IconContainer>
      <Typography variant="body3">{text}</Typography>
    </Styles.StyledMenuItem>
  );
};
