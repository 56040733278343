const updateOptions = (set, get) => async (type) => {
  set({
    [`optionsToShow${type}`]: {
      currentPage: 1,
      search: null,
      options: [],
      nextPage: 2,
    },
  });
};

export default updateOptions;
