import "react-datepicker/dist/react-datepicker.css";
import {
  CalendarButton,
  CalendarHeader,
  CalendarIcon,
  CalenderContainer,
  CustomDatePicker,
  ErrorLabel,
  InputLabel,
  StyledIconButton,
} from "./CalenderInput.styles";
import dayjs from "dayjs";
import arrow from "../../assets/icons/ChevronDown.svg";
import calendarIcon from "../../assets/icons/CalendarIcon.svg";
import { Typography } from "@mui/material";

const Calendar = ({
  startDate,
  endDate,
  placeholder,
  selectsRange = true,
  changeHandler,
  label,
  value,
  onChange,
  disabled,
  showErrorMessage,
  error,
  ...props
}) => {
  const isAfterToday = (date) => {
    const selectedDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (selectsRange) {
      return selectedDate > today;
    } else {
      return false;
    }
  };

  const outsideClickHandler = (e) => {
    const targetElement = e.target;
    if (targetElement.className === "react-datepicker__close-icon") {
      e?.stopPropagation();
      return;
    }

    if (selectsRange && ((startDate && !endDate) || (!startDate && !endDate))) {
      onChange([null, null]);
      return;
    }
  };

  const handleDateChange = (update) => {
    onChange(update);
    if (changeHandler) {
      changeHandler("expiration", update);
    }
  };
  return (
    <CalenderContainer disabled={disabled}>
      <InputLabel variant="body7">{label}</InputLabel>
      <CustomDatePicker
        selectsRange={selectsRange}
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateChange}
        dateFormat="MM/dd/yyyy"
        className="datePickerInput"
        showPopperArrow={false}
        popperClassName="calendarPopper"
        placeholderText={placeholder ? placeholder : "Select date range"}
        isClearable
        shouldCloseOnSelect
        maxDate={selectsRange ? new Date() : null}
        dayClassName={(date) => (isAfterToday(date) ? "disabled-day" : "")}
        onClickOutside={(e) => outsideClickHandler(e)}
        selected={value}
        showIcon
        icon={<CalendarIcon src={calendarIcon} alt="calendarIcon" />}
        disabled={disabled}
        {...props}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <CalendarHeader>
            <StyledIconButton
              disableRipple
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <CalendarButton src={arrow} alt="xMark" position />
            </StyledIconButton>
            <Typography variant="body6">
              {dayjs(date).format("MMMM")} {dayjs(date).format("YYYY")}
            </Typography>
            <StyledIconButton
              disableRipple
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <CalendarButton src={arrow} alt="xMark" />
            </StyledIconButton>
          </CalendarHeader>
        )}
      />
      {showErrorMessage ? (
        <ErrorLabel variant="small1">{error?.message}</ErrorLabel>
      ) : null}
    </CalenderContainer>
  );
};

export default Calendar;
