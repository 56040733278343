import axios from "axios";
import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";
import { toast } from "sonner";
import Toaster from "../../../components/Toaster/Toaster";

const uploadImagesToCompliance =
  (set) => async (id, existedFiles, files, isNewDraft) => {
    try {
      set({ isLoading: true });

      const oldFiles = [];

      let totalProgress = 0;

      const convertedFiles = [];

      if (isNewDraft) {
        for (let i = 0; i < files.length; i++) {
          if (files[i]?.url && files[i]?.id) {
            try {
              const response = await fetch(files[i]?.url, {
                headers: {
                  "Cache-Control": "no-cache",
                  Pragma: "no-cache",
                  Expires: "0",
                },
              });
              const blob = await response.blob();
              const file = new File([blob], "image.webp", {
                type: "image/webp",
              });
              convertedFiles.push(file);
            } catch (error) {
              toast.custom((t) => (
                <Toaster
                  t={t}
                  message={`Something wrong with ${files[i]?.url}`}
                />
              ));
            }
          } else {
            convertedFiles.push(files[i]);
          }
        }
        for (let i = 0; i < convertedFiles.length; i++) {
          if (convertedFiles[i]?.url && convertedFiles[i]?.id) {
            oldFiles.push(convertedFiles[i]);
            continue;
          }
          let currentFile = convertedFiles[i];

          if (convertedFiles[i].type !== "image/webp") {
            try {
              const imageDataUrl = await readFileAsDataURL(convertedFiles[i]);
              const compressedDataUrl = await compressImage(imageDataUrl);
              const compressedFile = dataURLToFile(
                compressedDataUrl,
                convertedFiles[i].name
              );
              currentFile = compressedFile;
            } catch (err) {
              toast.custom((t) => (
                <Toaster
                  t={t}
                  message={`Something wrong with ${convertedFiles[i].name}`}
                />
              ));
            }
          }

          const { data } = await api.get(`/compliances/presigned-url/`);
          await axios.put(data?.url, currentFile, {
            headers: {
              "Content-Type": currentFile.type,
            },
          });
          await api.post(`/compliances/${id}/images/`, {
            url: data?.url.split(".webp")?.[0] + ".webp",
          });
          totalProgress += 100 / files.length;

          set({
            imageLoadingPercent: totalProgress,
          });
        }
      } else {
        for (let i = 0; i < files.length; i++) {
          if (files[i]?.url && files[i]?.id) {
            oldFiles.push(files[i]);
            continue;
          }
          let currentFile = files[i];

          if (files[i].type !== "image/webp") {
            try {
              const imageDataUrl = await readFileAsDataURL(files[i]);
              const compressedDataUrl = await compressImage(imageDataUrl);
              const compressedFile = dataURLToFile(
                compressedDataUrl,
                files[i].name
              );
              currentFile = compressedFile;
            } catch (err) {
              toast.custom((t) => (
                <Toaster
                  t={t}
                  message={`Something wrong with ${files[i].name}`}
                />
              ));
            }
          }

          const { data } = await api.get(`/compliances/presigned-url/`);
          await axios.put(data?.url, currentFile, {
            headers: {
              "Content-Type": currentFile.type,
            },
          });
          await api.post(`/compliances/${id}/images/`, {
            url: data?.url.split(".webp")?.[0] + ".webp",
          });
          totalProgress += 100 / files.length;

          set({
            imageLoadingPercent: totalProgress,
          });
        }

        const filesToDelete = existedFiles.filter(
          (file) =>
            !oldFiles.find((el) => el?.url === file?.url && el?.id === file?.id)
        );

        filesToDelete.forEach(async (file) => {
          await api.delete(`/compliances/images/${file.id}/`);
        });
      }

      set({
        isLoading: false,
        imageLoadingPercent: null,
      });
    } catch (error) {
      set({ isLoading: false, imageLoadingPercent: null });
      throw errorFormater(error.response);
    }
  };

export default uploadImagesToCompliance;

const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const compressImage = (imageDataUrl) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageDataUrl;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const width = img.width;
      const height = img.height;
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      const compressedDataUrl = canvas.toDataURL("image/webp");

      resolve(compressedDataUrl);
    };
  });
};

const dataURLToFile = (dataUrl, filename) => {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
