import { LAButton } from "../../components/LAButton/LAButton";
import {
  Container,
  PageHeader,
  StyledHeader,
  HeaderRow,
  QueriesCount,
  Actions,
  SearchContainer,
  Filters,
  FiltersCount,
  Xmark,
  FilterInfo,
} from "./HomePage.styles";
import addIcon from "../../assets/icons/AddListingIcon.svg";
import { Box } from "@mui/material";
import { useDisclosure } from "../../hooks/useDisclosure";
import { AddQueryPopup } from "./AddQuerryPopup/AddQueryPopup";
import { HomePageTable } from "./HomePageTable/HomePageTable";
import useQueryStore from "../../state/query/query-store";
import { shallow } from "zustand/shallow";
import { NoDataFound } from "./NoDataFound/NoDataFound";
import DownLoadIcon from "../../assets/CustomIcons/DownLoad";
import filterIcon from "../../assets/icons/SortIcon.svg";
import xMark from "../../assets/icons/XmarkIcon.svg";
import { COLORS } from "../../theme/colors";
import { useCallback, useEffect, useMemo } from "react";
import LoadingPage from "../LoadingPage/LoadingPage";
import { StyledDrawer } from "../../components/Header/Header.styles";
import { FiltersMenu } from "./FiltersMenu/FiltersMenu";
import { defaultFilters } from "../../state/query/state";
import { useLocation, useNavigate } from "react-router-dom";
import PagesLayout from "../../Layouts/PagesLayout";
import { errorApiHandler } from "../../services/errorHandler";
import { ExportReportsPopup } from "./ExportReportsPopup/ExportReportsPopup";
import useQueueStore from "../../state/queue/queue-store";
import { ReportTemplate } from "../../templates/ReportTemplate/ReportTemplate";
import { PDFLinkStyled } from "../../components/PDFGenerator/PDFGenerator";
import { MyQuerryToggle } from "./MyQuerryToggle/MyQuerryToggle";
import { SearchComponent } from "../../components/SeachComponent/SearchComponent";
const HomePage = () => {
  const [
    totalQueries,
    selectedQuerriesLength,
    selectedQuerries,
    getList,
    isLoading,
    filters,
    updateFilters,
    getReports,
    updateSelectedQueriesInLocalStore,
    updateSelectedQueriesFromLocalStore,
    updateCurrentFiltersInLocalStore,
    resetPageProps,
    isQuerryExistedLength,
  ] = useQueryStore(
    (state) => [
      state.totalQueries,
      state.selectedQuerries.length,
      state.selectedQuerries,
      state.getList,
      state.isLoading,
      state.filters,
      state.updateFilters,
      state.getReports,
      state.updateSelectedQueriesInLocalStore,
      state.updateSelectedQueriesFromLocalStore,
      state.updateCurrentFiltersInLocalStore,
      state.resetPageProps,
      state.isQuerryExistedLength,
    ],
    shallow
  );
  const [getHomeDetails, queueDetails, isReportLoading] = useQueueStore(
    (state) => [state.getHomeDetails, state.queueDetails, state.isLoading],
    shallow
  );
  const navigation = useNavigate();
  const location = useLocation();
  const addQueryDialog = useDisclosure();
  const warningDialog = useDisclosure();

  const filterCounter = useMemo(() => {
    const isTypeUsed = filters.single || filters.batch;
    const isStatusUsed = filters.in_progress || filters.completed;
    const isDateUsed = !!filters.date[0] || !!filters.date[1];
    return +isTypeUsed + +isStatusUsed + +isDateUsed;
  }, [filters]);

  const filterMenu = useDisclosure();

  const clearFilters = useCallback(
    async (e, options) => {
      e && e?.stopPropagation();
      updateFilters(defaultFilters, options);
      try {
        await getList();
      } catch (error) {
        errorApiHandler(error, navigation);
      }
    },
    [updateFilters, getList, navigation]
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const isFromReport = JSON.parse(localStorage.getItem("prevIsReport"));

        isFromReport ? await getList(true) : await getList();
        isFromReport && updateSelectedQueriesFromLocalStore();
      } catch (error) {
        errorApiHandler(error, navigation);
      }
    };
    getData();
  }, [getList, navigation, updateSelectedQueriesFromLocalStore]);

  useEffect(() => {
    if (selectedQuerriesLength === 1) {
      getHomeDetails(selectedQuerries[0]);
    }
  }, [selectedQuerriesLength, getHomeDetails, selectedQuerries]);

  useEffect(() => {
    window.addEventListener("beforeunload", resetPageProps);
    return () => {
      window.removeEventListener("beforeunload", resetPageProps);
      resetPageProps();
    };
  }, [resetPageProps]);
  return (
    <PagesLayout>
      <Container>
        <PageHeader>
          <HeaderRow>
            <StyledHeader variant="h1">Home</StyledHeader>
            <Box>
              {selectedQuerriesLength === 1 &&
              queueDetails &&
              !isReportLoading ? (
                <PDFLinkStyled
                  document={<ReportTemplate reportTMP={queueDetails} />}
                  fileName={`report_(${queueDetails.id}).pdf`}
                >
                  {() => {
                    return (
                      <LAButton
                        fullWidth
                        disabled={isReportLoading}
                        text={
                          "Export Reports " +
                          (!!selectedQuerriesLength
                            ? `(${selectedQuerriesLength})`
                            : "")
                        }
                        size="sm"
                        icon={
                          <DownLoadIcon
                            color={
                              selectedQuerriesLength
                                ? COLORS.NEUTRAL0
                                : COLORS.BLUE7
                            }
                          />
                        }
                      />
                    );
                  }}
                </PDFLinkStyled>
              ) : (
                <LAButton
                  variant={selectedQuerriesLength ? "primary" : "trietary"}
                  size="sm"
                  text={
                    "Export Reports " +
                    (!!selectedQuerriesLength
                      ? `(${selectedQuerriesLength})`
                      : "")
                  }
                  icon={
                    <DownLoadIcon
                      color={
                        isQuerryExistedLength === 0
                          ? COLORS.NEUTRAL4
                          : selectedQuerriesLength
                          ? COLORS.NEUTRAL0
                          : COLORS.BLUE7
                      }
                    />
                  }
                  onClick={async () => {
                    if (selectedQuerriesLength === 0) {
                      warningDialog.open();
                    } else if (selectedQuerriesLength === 1) {
                      getHomeDetails(selectedQuerries[0]);
                    } else {
                      try {
                        await getReports(false);
                      } catch (error) {
                        errorApiHandler(error, navigation);
                      }
                    }
                  }}
                  disabled={isQuerryExistedLength === 0}
                />
              )}
            </Box>
          </HeaderRow>
          <HeaderRow>
            <Actions>
              <QueriesCount variant="h3">{totalQueries} queries</QueriesCount>
              <Box>
                <LAButton
                  size="sm"
                  text="Create Query"
                  icon={<img src={addIcon} alt="addIcon" />}
                  onClick={addQueryDialog.open}
                />
              </Box>
            </Actions>
            <Filters>
              <MyQuerryToggle />

              <SearchContainer>
                <SearchComponent store={useQueryStore} />
              </SearchContainer>
              <LAButton
                variant={"secondary"}
                size="sm"
                text={
                  <FilterInfo>
                    Filter
                    {filterCounter ? (
                      <>
                        <FiltersCount>{filterCounter}</FiltersCount>
                        <Xmark
                          src={xMark}
                          alt="xMarkIcon"
                          onClick={clearFilters}
                        />
                      </>
                    ) : null}
                  </FilterInfo>
                }
                onClick={filterMenu.open}
                icon={<img src={filterIcon} alt="filterIcon" />}
              />
            </Filters>
          </HeaderRow>
        </PageHeader>
        <Box>
          {totalQueries ? (
            <HomePageTable
              handleViewDetails={(id) => {
                updateSelectedQueriesInLocalStore();
                updateCurrentFiltersInLocalStore();
                location.pathname === "/home"
                  ? navigation(`${id}`)
                  : navigation(`home/${id}`);
              }}
            />
          ) : (
            !isLoading && <NoDataFound />
          )}
        </Box>
      </Container>
      <StyledDrawer
        open={filterMenu.isOpen}
        onClose={filterMenu.close}
        anchor="right"
        BackdropProps={{
          style: { backgroundColor: COLORS.NEUTRAL6, opacity: 0.6 },
        }}
      >
        <FiltersMenu onClose={filterMenu.close} />
      </StyledDrawer>
      {<LoadingPage isLoading={isLoading} />}
      {addQueryDialog.isOpen ? (
        <AddQueryPopup onClose={addQueryDialog.close} />
      ) : null}

      {warningDialog.isOpen ? (
        <ExportReportsPopup
          onClose={warningDialog.close}
          isLoading={isLoading}
          onExport={async () => {
            try {
              await getReports(true, warningDialog.close);
            } catch (error) {
              errorApiHandler(error, navigation);
            }
          }}
        />
      ) : null}
    </PagesLayout>
  );
};

export default HomePage;
