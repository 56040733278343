import { Box, Typography, styled } from "@mui/material";

export const ButtonContainer = styled(Box)(({ theme }) => ({
  width: 118,
}));
export const Subtitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 7),
  textAlign: "center",
  ...theme.typography.subtitle3,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.subtitle4,
  },
}));
