import { Box, TableCell, Tooltip, styled } from "@mui/material";
import { elipsedText } from "../../../styles";

export const PageHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

export const TableBodyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `none`,
  ...theme.typography.body4,
  color: theme.palette.COLORS.NEUTRAL9,
  padding: theme.spacing(1, 0, 1, 2),
  [theme.breakpoints.down("md")]: {
    ...theme.typography.body8,
  },
}));
export const HeaderCellContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.75),
  width: "100%",
  whiteSpace: "nowrap",
}));
const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip
    classes={{ tooltip: className }}
    enterDelay={500}
    enterNextDelay={500}
    {...props}
  />
);
export const CustomTooltipForTable = styled(ToBeStyledTooltip)(({ theme }) => ({
  backgroundColor: theme.palette.COLORS.NEUTRAL05,
  color: theme.palette.COLORS.NEUTRAL9,
  border: `1px solid ${theme.palette.COLORS.NEUTRAL15}`,
  maxWidth: 500,
  borderRadius: theme.spacing(0.5),

  "& p": {
    margin: 0,
    ...theme.typography.body4,
    [theme.breakpoints.down("md")]: {
      ...theme.typography.body8,
    },
  },
  padding: theme.spacing(0.75, 1.5),
  "& .MuiTooltip-arrow::before": {
    color: theme.palette.COLORS.NEUTRAL05,
    border: `1px solid ${theme.palette.COLORS.NEUTRAL15}`,
  },
}));
export const CustomErrorTooltipForTable = styled(CustomTooltipForTable)(
  ({ theme }) => ({
    backgroundColor: theme.palette.COLORS.RED0,
    color: theme.palette.COLORS.RED5,
    border: `1px solid ${theme.palette.COLORS.RED5}`,

    "& .MuiTooltip-arrow::before": {
      color: theme.palette.COLORS.RED0,
      border: `1px solid ${theme.palette.COLORS.RED5}`,
    },
  })
);
export const Owners = styled("span")(({ theme }) => ({
  ...theme.typography.body4,
  color: theme.palette.COLORS.BLUE7,
  cursor: "pointer",
}));

export const CityContainer = styled(Box)(({ theme }) => ({
  maxWidth: 177,
  ...elipsedText,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 96,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 86,
  },
}));
export const AddressContainer = styled(Box)(({ theme }) => ({
  maxWidth: 345,
  ...elipsedText,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 258,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 218,
  },
}));
