import { createWithEqualityFn } from "zustand/traditional";
import { createQueueStoreActions } from "./actions";
import { queueInitialData } from "./state";

const useQueueStore = createWithEqualityFn((set, get) => ({
  ...queueInitialData,
  ...createQueueStoreActions(set, get),
}));

export default useQueueStore;
