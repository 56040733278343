import { Box, styled } from "@mui/material";
import { elipsedText } from "../../../styles";

export const CompanyContainer = styled(Box)(({ theme }) => ({
  maxWidth: 303,
  ...elipsedText,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 238,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 203,
  },
}));
