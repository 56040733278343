import {
  Box,
  IconButton,
  InputAdornment,
  Select,
  Typography,
} from "@mui/material";
import { forwardRef, useState } from "react";
import { LACheckbox } from "../LACheckbox";
import XMark from "../../assets/icons/XmarkIcon.svg";
import ChevronDown from "../../assets/icons/ChevronDown.svg";
import ChevronUp from "../../assets/icons/ChevronUp.svg";
import { useDisclosure } from "../../hooks/useDisclosure";
import {
  ChipsContainer,
  Container,
  ErrorLabel,
  ItemContainer,
  Label,
  PlaceholderLabel,
  SearchMenuItem,
  SelectedItem,
  StyledChip,
  StyledIcon,
  StyledMenuItem,
  StyledSelectInput,
} from "./MultiSelect.styles";
import { COLORS } from "../../theme/colors";
import { SearchInput } from "../SearchInput/SearchInput";
import styled from "@emotion/styled";
import { smallCustomScroll } from "../../styles";
import { useDebouncedEffect } from "../../hooks/useDebouncedValue";
import { usePrevious } from "../../hooks/useDebouncedValue";

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;

const MenuProps = (withSearch = false, loadAdditionalOptions) => ({
  sx: {
    "& .MuiMenu-list": {
      paddingTop: withSearch ? 0 : 1,
      marginTop: withSearch ? "40px" : 0,
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP - 50,
      position: "initial",
      ...smallCustomScroll,
      overflowY: "scroll",
    },
    "& .MuiMenu-paper": {
      position: "relative",
    },
  },
  MenuListProps: {
    onScroll: (e) => {
      const bottom =
        e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
      if (bottom) {
        loadAdditionalOptions();
      }
    },
  },
  PaperProps: {
    style: {
      marginTop: 8,
      width: 100,
      boxSizing: "content-box",
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      overflow: "hidden",
      border: `1px solid ${COLORS.NEUTRAL15}`,
      boxShadow: "none",
    },
  },
  elevation: 2,
});

export const MultiSelect = forwardRef(
  (
    {
      multiple = true,
      noItemsSelectedLabel = "None",
      onDelete,
      error,
      helperText,
      options,
      value,
      label,
      labelIcon,
      name,
      canClear,
      onClear,
      showErrorMessage,
      withSearch = false,
      onSearchOptions,
      updateOptions,
      isLoading,
      withEmptyValue,
      valueToCompare,
      size = "dafault",
      ...props
    },
    forwardedRef
  ) => {
    const { isOpen, open, close } = useDisclosure();
    const getLabelByValue = (value) => {
      const option = options.find((option) => option.value === value);
      return option?.label || value;
    };
    const [searchOption, setSearchOption] = useState("");

    const previousValue = usePrevious(searchOption);
    useDebouncedEffect(
      async () => {
        if (searchOption !== previousValue) {
          if (onSearchOptions) {
            if (updateOptions) {
              updateOptions();
            }
            onSearchOptions(searchOption);
          }
        }
      },
      [searchOption],
      700
    );

    return (
      <Container disabled={props.disabled}>
        {label ? (
          <Box alignItems={"center"} display={"flex"} gap={1}>
            <Label
              htmlFor={name}
              id={`${name}-label`}
              variant="body2"
              component="label"
            >
              {label}
              {labelIcon && (
                <img
                  src={labelIcon}
                  alt="icon"
                  style={{
                    position: "absolute",
                    top: 2,
                    right: -20,
                  }}
                />
              )}
            </Label>
          </Box>
        ) : null}
        <Box position={"relative"} width={"100%"}>
          <Select
            multiple={multiple}
            value={value}
            open={isOpen}
            onOpen={() => {
              onSearchOptions && onSearchOptions(searchOption);
              open();
            }}
            onClose={() => {
              close();
              if (updateOptions) {
                updateOptions();
              }
              setSearchOption("");
            }}
            onKeyDown={(e) => e?.stopPropagation()}
            input={
              <StyledSelectInput
                placeholder={noItemsSelectedLabel}
                error={!!error}
                endAdornment={
                  canClear ? (
                    <InputAdornment
                      position="end"
                      style={{ display: value === "" ? "none" : "block" }}
                    >
                      {value !== "" ? (
                        <IconButtonS onClick={onClear}>
                          <img src={XMark} alt="XMarkIcon" />
                        </IconButtonS>
                      ) : null}
                    </InputAdornment>
                  ) : null
                }
                size={size}
              />
            }
            IconComponent={() => (
              <StyledIcon
                src={isOpen ? ChevronUp : ChevronDown}
                onClick={
                  !props.disabled
                    ? isOpen
                      ? close
                      : () => {
                          onSearchOptions && onSearchOptions(searchOption);
                          open();
                        }
                    : null
                }
                sx={{ cursor: props.disabled ? "default" : "pointer" }}
              />
            )}
            renderValue={(selected) => {
              if (selected?.length === 0) {
                return (
                  <PlaceholderLabel>{noItemsSelectedLabel}</PlaceholderLabel>
                );
              }

              if (multiple) {
                return (
                  <ChipsContainer>
                    {selected?.map((value) => (
                      <StyledChip
                        key={value}
                        label={getLabelByValue(value)}
                        onDelete={(e) => onDelete(e, value)}
                        deleteIcon={
                          <img
                            src={XMark}
                            onMouseDown={(event) => event?.stopPropagation()}
                            alt="XMarkIcon"
                          ></img>
                        }
                      />
                    ))}
                  </ChipsContainer>
                );
              }

              return (
                <SelectedItem variant={size === "small" ? "body7" : "body3"}>
                  {getLabelByValue(selected)}
                </SelectedItem>
              );
            }}
            MenuProps={MenuProps(withSearch, () => {
              onSearchOptions && onSearchOptions(searchOption);
            })}
            displayEmpty
            name={name}
            id={name}
            labelId={`${name}-label`}
            {...props}
            onChange={(e, v) => {
              props.onChange(e, v, getLabelByValue(e.target.value));
              setSearchOption("");
            }}
            inputRef={forwardedRef}
          >
            {withSearch ? (
              <SearchContainer disabled>
                <SearchInput value={searchOption} onChange={setSearchOption} />
              </SearchContainer>
            ) : null}
            {multiple ? (
              <SearchMenuItem
                value=""
                key={"default_value"}
                disableRipple
                onKeyDown={(e) => e?.stopPropagation()}
              >
                <SearchContainer value={10}>
                  <SearchInput
                    value={searchOption}
                    onChange={setSearchOption}
                    isInMenu
                  />
                </SearchContainer>
              </SearchMenuItem>
            ) : null}

            {withEmptyValue ? (
              <StyledMenuItem key={"null"} disableRipple value={""}>
                <ItemContainer>
                  <Typography variant="body7">None</Typography>
                </ItemContainer>
              </StyledMenuItem>
            ) : null}
            {options.map((option) => {
              return multiple ? (
                <StyledMenuItem
                  key={option.value}
                  value={option.value}
                  disableRipple
                >
                  <ItemContainer>
                    <LACheckbox
                      checkboxProps={{
                        checked: value.indexOf(option.value) > -1,
                      }}
                    />
                    <Typography variant="body7">{option.label}</Typography>
                  </ItemContainer>
                </StyledMenuItem>
              ) : (
                <StyledMenuItem
                  key={option.value}
                  value={option.value}
                  disableRipple
                  size={size}
                  isSelected={
                    valueToCompare ? valueToCompare === option.value : false
                  }
                >
                  <ItemContainer>
                    {option.label} {option?.subTitle}
                  </ItemContainer>
                </StyledMenuItem>
              );
            })}
            {isLoading ? (
              <StyledMenuItem key={"loading"} disabled disableRipple>
                <ItemContainer>
                  <Typography variant="body7">Loading</Typography>
                </ItemContainer>
              </StyledMenuItem>
            ) : null}
            {!options.length && !isLoading ? (
              <StyledMenuItem key={"no_options"} disabled disableRipple>
                <ItemContainer>
                  <Typography variant="body7">No Options</Typography>
                </ItemContainer>
              </StyledMenuItem>
            ) : null}
          </Select>
          {showErrorMessage ? (
            <ErrorLabel variant="small1">{error?.message}</ErrorLabel>
          ) : null}
        </Box>
      </Container>
    );
  }
);
export const SearchContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  top: 0,
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  zIndex: 10,
}));

const IconButtonS = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  width: 20,
  height: 20,
  right: 48,
  top: "50%",
  transform: "translate(0,-50%)",
  "&:hover": {
    backgroundColor: theme.palette.COLORS.NEUTRAL05,
  },
}));
