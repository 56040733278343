import { Radio, styled } from "@mui/material";
import Check from "../assets/icons/ActiveRadio.svg";
import unCheck from "../assets/icons/UnCheckedRadio.svg";

export const LARadio = ({ ...radioProps }) => {
  return (
    <StyledRadio
      disableRipple
      icon={<img src={unCheck} alt="CheckIcon"></img>}
      checkedIcon={<img src={Check} alt="CheckIcon"></img>}
      {...radioProps}
    />
  );
};
export const StyledRadio = styled(Radio)(({ theme }) => ({
  height: 20,
  width: 20,
  stroke: theme.palette.COLORS.NEUTRAL05,
  strokeWidth: 1,
  borderRadius: 0,
  color: theme.palette.COLORS.BLUE2,
  "&.Mui-disabled": {
    color: theme.palette.COLORS.NEUTRAL1,
    filter: "grayscale(90%) opacity(50%)",
  },
}));
