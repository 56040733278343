import { Box, Typography, styled } from "@mui/material";

export const NewPasswordContent = styled(
  ({ confirmed: __confirmed, ...restProps }) => <Box {...restProps} />
)(({ theme, confirmed }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  padding: `64px ${confirmed ? 62 : 163}px`,
  width: confirmed ? 571 : 734,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    width: confirmed ? 582 : 608,
    padding: `52px 100px`,
  },
}));

export const CustomForm = styled("form")(() => ({
  width: 408,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: 20,
}));

export const Header = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h3,
  },
}));

export const FormGroup = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  width: 194,
}));
export const InputsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
}));

export const FormFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing(2.5),
  marginTop: theme.spacing(6),
}));
