import {
  Body,
  Container,
  Footer,
  FiltersList,
  MenuHeader,
  StyledIconButton,
  FiltersHeader,
  FilterTitle,
  StyledAccordionSummary,
  StyledAccordion,
  CheckboxLabel,
  StyledAccordionDetails,
  MunuSubheader,
  HyperLink,
} from "../../HomePage/FiltersMenu/FiltersMenu.styles";

import { Box } from "@mui/material";
import { LAButton } from "../../../components/LAButton/LAButton";
import { useState } from "react";

import arrowIcon from "../../../assets/icons/ChevronDown.svg";
import { LACheckbox } from "../../../components/LACheckbox";
import { Controller, useForm } from "react-hook-form";
import Calendar from "../../../components/CalendarInput/CalenderInput";
import { shallow } from "zustand/shallow";
import useQueueStore from "../../../state/queue/queue-store";
import XMark from "../../../assets/CustomIcons/XMark";
import { COLORS } from "../../../theme/colors";
import { MultipleSelect } from "../../../components/MultiSelect/MultipleSelect";
import { defaultFilters } from "../../../state/queue/state";
import useAppStore from "../../../state/app-store/query-store";
import { errorApiHandler } from "../../../services/errorHandler";
import { useNavigate } from "react-router-dom";

export const FiltersMenu = ({ onClose }) => {
  const navigation = useNavigate();
  const [expanded, setExpanded] = useState("");
  const [updateFilters, getList, filters] = useQueueStore(
    (state) => [state.updateFilters, state.getList, state.filters],
    shallow
  );
  const [companiesList] = useAppStore((state) => [state.companies], shallow);

  const [dateRange, setDateRange] = useState(filters.date);
  const [startDate, endDate] = dateRange;

  const { handleSubmit, control, reset, setValue, getValues, watch } = useForm({
    defaultValues: filters,
    mode: "onChange",
  });

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleApplyFilters = async (filters) => {
    const combinedFilters = {
      ...filters,
      date: [...dateRange],
    };
    updateFilters(combinedFilters);
    try {
      await getList();
    } catch (error) {
      errorApiHandler(error, navigation);
    }
  };

  const { single, batch, in_progress, received, companies } = watch();

  const filterCounter = () => {
    const isTypeUsed = single || batch;
    const isStatusUsed = in_progress || received;
    const isDateUsed = dateRange[0] || dateRange[1];
    const isCompany = companies?.length > 0;
    return +isTypeUsed + +isStatusUsed + +isDateUsed + +isCompany;
  };

  const handleClearFilters = () => {
    setDateRange([null, null]);
    reset(defaultFilters);
  };
  return (
    <Container>
      <form onSubmit={handleSubmit((values) => handleApplyFilters(values))}>
        <Body>
          <MenuHeader>
            <StyledIconButton onClick={onClose} disableRipple>
              <XMark stroke={COLORS.NEUTRAL9} />
            </StyledIconButton>
          </MenuHeader>
          <FiltersList>
            <MunuSubheader>
              <FiltersHeader>Filters</FiltersHeader>
              {filterCounter() ? (
                <HyperLink onClick={handleClearFilters}>
                  Clear filters
                </HyperLink>
              ) : null}
            </MunuSubheader>
            <Box>
              <StyledAccordion
                disableGutters
                elevation={0}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <StyledAccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={<img src={arrowIcon} alt="arrowIcon" />}
                >
                  <FilterTitle>Date range</FilterTitle>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <Calendar
                    label="Select date range"
                    placeholder="MM/DD/YYYY"
                    startDate={startDate}
                    endDate={endDate}
                    setDateRange={setDateRange}
                    onChange={setDateRange}
                  />
                </StyledAccordionDetails>
              </StyledAccordion>
              <StyledAccordion
                disableGutters
                elevation={0}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <StyledAccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  expandIcon={<img src={arrowIcon} alt="arrowIcon" />}
                >
                  <FilterTitle>Status</FilterTitle>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <Controller
                    control={control}
                    name={`received`}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxLabel
                        checked={value}
                        control={
                          <LACheckbox checked={value} onChange={onChange} />
                        }
                        label="Received"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`in_progress`}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxLabel
                        checked={value}
                        control={
                          <LACheckbox checked={value} onChange={onChange} />
                        }
                        label="In progress"
                      />
                    )}
                  />
                </StyledAccordionDetails>
              </StyledAccordion>
              <StyledAccordion
                disableGutters
                elevation={0}
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <StyledAccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  expandIcon={<img src={arrowIcon} alt="arrowIcon" />}
                >
                  <FilterTitle>Query type</FilterTitle>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <Controller
                    control={control}
                    name={`single`}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxLabel
                        checked={value}
                        control={
                          <LACheckbox checked={value} onChange={onChange} />
                        }
                        label="Single"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`batch`}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxLabel
                        checked={value}
                        control={
                          <LACheckbox checked={value} onChange={onChange} />
                        }
                        label="Batch"
                      />
                    )}
                  />
                </StyledAccordionDetails>
              </StyledAccordion>
              <StyledAccordion
                disableGutters
                elevation={0}
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <StyledAccordionSummary
                  aria-controls="panel4d-content"
                  id="panel4d-header"
                  expandIcon={<img src={arrowIcon} alt="arrowIcon" />}
                >
                  <FilterTitle>Company</FilterTitle>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <Controller
                    control={control}
                    name="companies"
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <MultipleSelect
                          options={companiesList}
                          label="Select company"
                          onDelete={(id) => {
                            setValue(
                              field.name,
                              getValues(field.name).filter((v) => v.id !== id)
                            );
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                </StyledAccordionDetails>
              </StyledAccordion>
            </Box>
          </FiltersList>
        </Body>
        <Footer>
          <Box width={105}>
            <LAButton type="submit" text={"Done"} onClick={onClose} fullWidth />
          </Box>
        </Footer>
      </form>
    </Container>
  );
};
