import { Box, Typography, styled } from "@mui/material";

export const StyledPaper = styled(({ disabled: _disabled, ...props }) => (
  <Box {...props} />
))(({ theme, disabled }) => ({
  width: "100%",
  textAlign: "center",
  cursor: disabled ? "not-allowed" : "pointer",
  border: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0),
  background: theme.palette.COLORS.NEUTRAL05,
  opacity: disabled ? 0.4 : 1,
}));

export const ChoouseFileText = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL5,
}));

export const StyledLabel = styled(({ disabled: _disabled, ...props }) => (
  <label {...props} />
))(({ disabled, theme }) => ({
  cursor: disabled ? "not-allowed" : "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2, 0),
}));
