import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const updateEntityStatus = (set, get) => async (state, id) => {
  try {
    const { rows } = get();

    set({ isLoading: true });
    await api.put(`/compliances/${id}/status/`, {
      is_active: state,
    });
    set({
      isLoading: false,
      rows: rows.map((entity) =>
        entity.id === id ? { ...entity, is_active: !entity.is_active } : entity
      ),
    });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default updateEntityStatus;
