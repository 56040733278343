import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

export const handleChangeMyQuerryFilter = (set) => async (state) => {
  try {
    set({ isLoading: true });
    const response = await api.put("/users/set-my-query/", {
      show_my_query: state,
    });
    set({ isLoading: false, myQuerryOnly: response?.data?.show_my_query });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};
