import { Box, Typography, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2.5),
}));
export const PageHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));
export const HeaderRow = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
export const Actions = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(4),
}));
export const SearchContainer = styled(Box)(({ theme }) => ({
  width: 374,
  [theme.breakpoints.down("lg")]: {
    width: 254,
  },
}));
export const Filters = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));
export const FilterInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
export const MyQuerryContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1.5),
  paddingRight: theme.spacing(2),
}));

export const FiltersCount = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 18,
  height: 18,
  backgroundColor: theme.palette.COLORS.BLUE6,
  color: theme.palette.COLORS.NEUTRAL0,
  borderRadius: "50%",
  ...theme.typography.small1,
  marginLeft: theme.spacing(0.5),
}));

export const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h2,
  },
}));
export const ToggleTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL7,
}));
export const QueriesCount = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
}));
export const Xmark = styled("img")(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  marginLeft: theme.spacing(1),
}));
