import { createWithEqualityFn } from "zustand/traditional";
import { createEntityStoreActions } from "./actions";
import { entityInitialData } from "./state";

const useEntityStore = createWithEqualityFn((set, get) => ({
  ...entityInitialData,
  ...createEntityStoreActions(set, get),
}));

export default useEntityStore;
