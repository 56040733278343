import SignInLayout from "../../Layouts/SignInLayout";
import { SignInForm } from "./Components/SignInForm";
import { SignInContent } from "./SignIn.styles";

const SignIn = () => {
  return (
    <SignInLayout>
      <SignInContent>
        <SignInForm />
      </SignInContent>
    </SignInLayout>
  );
};

export default SignIn;
