import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const getReports = (set, get) => async (isAll, afterLoadingFunction) => {
  const { selectedQuerries } = get();
  try {
    set({ isLoading: true });
    const response = await api.post(`/home/generate-report/`, {
      ids: isAll ? [] : selectedQuerries,
    });
    set({ isLoading: false });
    afterLoadingFunction && afterLoadingFunction();
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = `data:text/csv;charset=utf-8,${escape(response.data)}`;
    link.href = url;
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (err) {
    set({ isLoading: false });
    throw errorFormater(err.response);
  }
};

export default getReports;
