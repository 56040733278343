import * as React from "react";
import {
  Container,
  ImgContainer,
  InputLabel,
  LabelContainer,
  StyledAutocomplete,
  StyledInput,
  StyledPaper,
  StyledIcon,
  Title,
  Chips,
} from "./MultipleSelect.styles";
import { Box, Typography } from "@mui/material";
import { LACheckbox } from "../LACheckbox";
import {
  ChipsContainer,
  ItemContainer,
  StyledChip,
} from "./MultiSelect.styles";
import XMark from "../../assets/icons/XmarkIcon.svg";
import zoomIcon from "../../assets/icons/ZoomIcon.svg";
import ChevronDown from "../../assets/icons/ChevronDown.svg";

export const MultipleSelect = ({
  label,
  name,
  options,
  onDelete,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState("");
  return (
    <>
      {label ? (
        <LabelContainer>
          <InputLabel variant="body7" htmlFor={name}>
            {label}
          </InputLabel>
        </LabelContainer>
      ) : null}
      <StyledAutocomplete
        multiple
        inputValue={inputValue}
        onInputChange={(_, value, reason) => {
          if (reason === "reset") return;
          setInputValue(value);
        }}
        limitTags={1}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderOption={(properties, option, { selected }) => {
          return (
            <li {...properties} key={option?.id}>
              <ItemContainer>
                <LACheckbox checked={selected} />
                <Typography variant="body7">{option.label}</Typography>
              </ItemContainer>
            </li>
          );
        }}
        isOptionEqualToValue={(option, value) => {
          return JSON.stringify(option) === JSON.stringify(value);
        }}
        noOptionsText={
          <Container>
            <ImgContainer>
              <img src={zoomIcon} alt={"zoomIcon"} />
            </ImgContainer>
            <Title>No Data Found...</Title>
          </Container>
        }
        renderTags={(value) => {
          const reversed = value.toReversed();
          return (
            <Chips>
              {reversed.map((option) => (
                <ChipsContainer key={option.id}>
                  <StyledChip
                    label={option.label}
                    onDelete={() => {
                      onDelete(option.id);
                    }}
                    deleteIcon={
                      <img src={XMark} alt="XMarkIcon" style={{ margin: 0 }} />
                    }
                  />
                </ChipsContainer>
              ))}
            </Chips>
          );
        }}
        PaperComponent={StyledPaper}
        popupIcon={<StyledIcon src={ChevronDown} />}
        renderInput={(params) => (
          <Box position={"relative"}>
            <StyledInput
              {...params}
              placeholder="Select Company"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          </Box>
        )}
        onChange={(e, v) => {
          props.onChange(v);
        }}
        value={props.value}
      />
    </>
  );
};
