import { Button, styled } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  width: 66,
  display: "flex",
  gap: theme.spacing(0.5),
  ...theme.typography.body3,
  textTransform: "initial",
  color: theme.palette.COLORS.NEUTRAL6,
  padding: 0,
  "& img": {
    width: 24,
    height: 24,
    transform: "rotate(90deg)",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
}));
