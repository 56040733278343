import { Box, styled } from "@mui/material";
import { elipsedText } from "../../../styles";

export const CityContainer = styled(Box)(({ theme }) => ({
  maxWidth: 128,
  ...elipsedText,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 92,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 80,
  },
}));
export const CompanyContainer = styled(Box)(({ theme }) => ({
  maxWidth: 203,
  ...elipsedText,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 143,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 128,
  },
}));
export const AddressContainer = styled(Box)(({ theme }) => ({
  maxWidth: 311,
  ...elipsedText,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 192,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 144,
  },
}));
