import { defaultFilters } from "../state";

export const resetPageProps = (set) => () => {
  set({
    page: 1,
    sortingField: "created_at",
    sortingOrder: true,
    searchField: "",
    filters: defaultFilters,
  });
};
