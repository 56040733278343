import { COLORS } from "../../../theme/colors";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { ColumnCell } from "./ColumnCell";
import dayjs from "dayjs";
import {
  STRLabels,
  jurisdictionLabels,
  jurisdictions,
} from "../../../mock/ReportData";

const styles = StyleSheet.create({
  Container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  PermitTable: {
    fontFamily: "Inter",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    border: `1px solid ${COLORS.NEUTRAL2}`,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  StrInfoTable: {
    fontFamily: "Inter",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    border: `1px solid ${COLORS.NEUTRAL2}`,
    borderTop: "none",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
  },
  LabelColumn: {
    display: "flex",
    flexDirection: "column",
    minWidth: "55mm",
    "& > div": {
      borderTopLeftRadius: "4px",
    },
  },
  ColumnHeader: {
    width: "100%",
    padding: "3mm 4mm",
    backgroundColor: COLORS.NEUTRAL05,
    borderBottom: `1px solid ${COLORS.NEUTRAL2}`,
  },
  TypographyBody5: {
    fontSize: 7,
    fontWeight: 600,
    color: COLORS.NEUTRAL7,
    textAlign: "left",
  },
  InfoColumns: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    position: "relative",
  },
  Column: {
    display: "flex",
    flexDirection: "column",
    width: "25%",
    borderLeft: `1px solid ${COLORS.NEUTRAL2}`,
    position: "relative",
  },
  TypographyHeaderTitle: {
    fontSize: 7,
    fontWeight: 600,
    color: COLORS.NEUTRAL9,
    textAlign: "left",
  },
  TypographyHeaderText: {
    fontSize: 7,
    fontWeight: 500,
    color: COLORS.NEUTRAL6,
    textAlign: "left",
  },
  StrTable: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  HeaderRow: {
    width: "100%",
    padding: "3mm 4mm",
    backgroundColor: COLORS.BLUE0,
    border: `1px solid ${COLORS.NEUTRAL2}`,
    borderTop: `none`,
  },
});

export const StrTable = ({
  reportTMP,
  hasPermits,
  permitsExpiredDates,
  isFailed,
}) => {
  return (
    <View style={styles.Container}>
      <View style={styles.PermitTable}>
        <View style={styles.LabelColumn}>
          <View style={{ ...styles.ColumnHeader, borderTopLeftRadius: 3 }}>
            <Text style={styles.TypographyBody5}>Applicable jurisdiction</Text>
          </View>
          {jurisdictionLabels.map((label, index) => (
            <ColumnCell
              key={label}
              text={label}
              bold
              isLast={jurisdictionLabels.length - 1 === index}
            />
          ))}
        </View>
        <View style={styles.InfoColumns}>
          {reportTMP.map(([key, item], i) => {
            return (
              <View style={styles.Column} key={item.id}>
                <View
                  style={{
                    ...styles.ColumnHeader,
                    backgroundColor: isFailed[key]
                      ? COLORS.NEUTRAL05
                      : COLORS.RED05,
                    borderTopRightRadius: i === 3 ? 3 : 0,
                  }}
                >
                  <Text style={styles.TypographyHeaderTitle}>
                    {jurisdictions[key]}{" "}
                    <Text style={styles.TypographyHeaderText}>{item.name}</Text>
                  </Text>
                </View>
                <ColumnCell
                  text={
                    item?.is_str_allowed === null
                      ? ""
                      : item?.is_str_allowed
                      ? "Yes"
                      : "No"
                  }
                  bold
                  empty={item?.is_str_allowed === null}
                  error={!isFailed[key]}
                />
                <ColumnCell
                  text={
                    item?.is_permit_required === null
                      ? ""
                      : item?.is_permit_required
                      ? "Yes"
                      : "No"
                  }
                  bold
                  empty={item?.is_permit_required === null}
                  error={!isFailed[key]}
                />
                <ColumnCell
                  text={
                    hasPermits[key] === null
                      ? "-"
                      : hasPermits[key]
                      ? "Yes"
                      : "No"
                  }
                  bold
                  empty={hasPermits[key] === null}
                  error={!isFailed[key]}
                />
                <ColumnCell
                  text={
                    permitsExpiredDates[key]
                      ? dayjs(permitsExpiredDates[key]).format("MMM DD, YYYY")
                      : "-"
                  }
                  empty={!permitsExpiredDates[key]}
                  error={!isFailed[key]}
                />
                <ColumnCell
                  text={
                    item?.law_added_at
                      ? dayjs(item?.law_added_at).format("MMM DD, YYYY")
                      : "-"
                  }
                  error={!isFailed[key]}
                  empty={!item?.law_added_at}
                  isLast
                />
              </View>
            );
          })}
          {reportTMP.length < 4 ? (
            <View
              style={{
                ...styles.Column,
                width: `${100 - reportTMP.length * 25}%`,
              }}
            >
              <View
                style={{
                  ...styles.ColumnHeader,
                  color: "transparent",
                  borderTopRightRadius: 3,
                }}
              >
                <Text style={{ ...styles.TypographyHeaderTitle }}> </Text>
              </View>
              {Array(5)
                .fill("none")
                .map((i, index) => (
                  <ColumnCell
                    text={i}
                    bold
                    key={index}
                    empty
                    isLast={index === 4}
                  />
                ))}
            </View>
          ) : null}
        </View>
      </View>
      <View style={styles.StrTable}>
        <View style={styles.HeaderRow}>
          <Text style={styles.TypographyBody5}>Additional STR Laws</Text>
        </View>
        <View style={styles.StrInfoTable}>
          <View style={styles.LabelColumn}>
            {STRLabels.map((label, index) => (
              <ColumnCell
                key={label}
                text={label}
                bold
                isLast={STRLabels.length - 1 === index}
                sx={{
                  borderBottomLeftRadius:
                    STRLabels.length - 1 === index ? 3 : 0,
                }}
              />
            ))}
          </View>
          <View style={styles.InfoColumns}>
            {reportTMP.map(([key, item], i, arr) => (
              <View style={styles.Column} key={item.id}>
                <ColumnCell
                  text={
                    item?.is_owner_occupancy_required === null
                      ? "-"
                      : item?.is_owner_occupancy_required
                      ? "Yes"
                      : "No"
                  }
                  subText={
                    item?.owner_occupancy_required_notes
                      ? "(see entity notes)"
                      : null
                  }
                  bold
                  empty={item?.is_owner_occupancy_required === null}
                />
                <ColumnCell
                  text={
                    item?.is_limit_length_stay === null
                      ? "-"
                      : item?.is_limit_length_stay
                      ? "Yes"
                      : "No"
                  }
                  subText={
                    item?.limit_length_stay_notes ? "(see entity notes)" : null
                  }
                  empty={item?.is_limit_length_stay === null}
                  bold
                />
                <ColumnCell
                  text={
                    item?.is_entity_limits_permit_owner === null
                      ? "-"
                      : item?.is_entity_limits_permit_owner
                      ? "Yes"
                      : "No"
                  }
                  subText={
                    item?.entity_limits_permit_owner_notes
                      ? "(see entity notes)"
                      : null
                  }
                  empty={item?.is_entity_limits_permit_owner === null}
                  bold
                />
                <ColumnCell
                  text={
                    item?.is_entity_limits_number_permit === null
                      ? "-"
                      : item?.is_entity_limits_number_permit
                      ? "Yes"
                      : "No"
                  }
                  subText={
                    item?.entity_limits_number_permit_notes
                      ? "(see entity notes)"
                      : null
                  }
                  empty={item?.is_entity_limits_number_permit === null}
                  bold
                />
                <ColumnCell
                  text={
                    item?.is_license_transferability === null
                      ? "-"
                      : item?.is_license_transferability
                      ? "Yes"
                      : "No"
                  }
                  subText={
                    item?.license_transferability_notes
                      ? "(see entity notes)"
                      : null
                  }
                  empty={item?.is_license_transferability === null}
                  bold
                />
                <ColumnCell
                  text={
                    item?.is_zone_restricted === null
                      ? "-"
                      : item?.is_zone_restricted
                      ? "Yes"
                      : "No"
                  }
                  subText={
                    item?.zone_restriction_notes ? "(see entity notes)" : null
                  }
                  empty={item?.is_zone_restricted === null}
                  bold
                  isLast
                  sx={{
                    borderBottomRightRadius: arr.length - 1 === i ? 3 : 0,
                  }}
                />
              </View>
            ))}
            {reportTMP.length < 4 ? (
              <View
                style={{
                  ...styles.Column,
                  width: `${100 - reportTMP.length * 25}%`,
                  borderBottomLeftRadius: 3,
                }}
              >
                {Array(6)
                  .fill("none")
                  .map((i, index) => (
                    <ColumnCell
                      text={i}
                      bold
                      key={index}
                      empty
                      isLast={index === 5}
                      sx={{
                        borderBottomRightRadius: index === 5 ? 3 : 0,
                      }}
                    />
                  ))}
              </View>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
};
