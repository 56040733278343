import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const createQuery = (set) => async (data) => {
  const querry = {
    ...data,
  };
  if (querry.is_occupied === null) {
    delete querry.is_occupied;
  }
  try {
    set({ isLoading: true });
    await api.post("/home/", querry);
    set({ isLoading: false });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default createQuery;
