import { createWithEqualityFn } from "zustand/traditional";
import { createUserStoreActions } from "./actions";
import { userInitialData } from "./state";

const useUserStore = createWithEqualityFn((set, get) => ({
  ...userInitialData,
  ...createUserStoreActions(set, get),
}));

export default useUserStore;
