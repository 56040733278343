import { useNavigate } from "react-router-dom";
import { Container, PageHeader, PageTitle } from "./HomePageReport.styles";
import { BackButton } from "../../../components/BackButton/BackButton";
import { PdfGenerator } from "../../../components/PDFGenerator/PDFGenerator";
import { ReportData } from "../../../components/ReportData/ReportData";
import PagesLayout from "../../../Layouts/PagesLayout";
import useQueueStore from "../../../state/queue/queue-store";
import { useEffect } from "react";
import { shallow } from "zustand/shallow";
import useQueryStore from "../../../state/query/query-store";

export const HomePageReport = () => {
  const navigate = useNavigate();
  const [queueDetails, isLoading] = useQueueStore(
    (state) => [state.queueDetails, state.isLoading],
    shallow
  );
  const [getStateFromLocalStore] = useQueryStore(
    (state) => [state.getStateFromLocalStore],
    shallow
  );

  useEffect(() => {
    if (queueDetails === null && !isLoading) {
      navigate("/*");
    }
  }, [queueDetails, navigate, isLoading]);
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("prevIsReport", JSON.stringify(true));
  }, []);
  return (
    <PagesLayout>
      <Container>
        <BackButton
          onClick={() => {
            getStateFromLocalStore();
            navigate("/home", { state: { prev: "report" } });
          }}
        />
        <PageTitle>
          <PageHeader>Short term rental compliance report</PageHeader>
          <PdfGenerator
            reportTMP={queueDetails}
            disabled={!queueDetails?.is_completed}
          />
        </PageTitle>

        <ReportData report={queueDetails} noNotes />
      </Container>
    </PagesLayout>
  );
};
