import {
  Container,
  ItemList,
  Logo,
  MenuButton,
  MenuHeader,
  MenuItem,
  StyledIcon,
  StyledIconButton,
  StyledLink,
} from "./SideBarMenu.styles";

import logo from "../../assets/icons/Logo.svg";
import blueHome from "../../assets/icons/BlueHomeIcon.svg";
import gear from "../../assets/icons/BlueGearIcon.svg";
import entity from "../../assets/icons/BlueEntityIcon.svg";
import queue from "../../assets/icons/QueueIcon.svg";
import response from "../../assets/icons/ResponseIcon.svg";
import menuIcon from "../../assets/icons/MenuIcon.svg";
import { Typography } from "@mui/material";
import useUserStore from "../../state/user/user-store";

const menu = {
  true: {
    home: {
      title: "Home",
      to: "/home",
      icon: blueHome,
    },
    compliance: {
      title: "Compliance",
      to: "/compliance",
      icon: entity,
    },
    queue: {
      title: "Queue",
      to: "/queue",
      icon: queue,
    },
    responses: {
      title: "Responses",
      to: "/responses",
      icon: response,
    },
  },
  false: {
    home: {
      title: "Home",
      to: "/home",
      icon: blueHome,
    },
    settings: {
      title: "Settings",
      to: "/settings",
      icon: gear,
    },
  },
};

export const SideBarMenu = ({ onClose }) => {
  const [is_staff] = useUserStore((state) => [state.is_staff]);
  return (
    <Container>
      <MenuHeader>
        <StyledIconButton onClick={onClose}>
          <MenuButton src={menuIcon} alt="menuIcon" />
        </StyledIconButton>
        <Logo src={logo} alt="PropertyGuardLogo" />
      </MenuHeader>
      <ItemList>
        {Object.entries(menu[is_staff.toString()]).map(([item, data]) => (
          <StyledLink key={item} to={data.to} onClick={onClose}>
            <MenuItem>
              <StyledIcon src={data.icon} />
              <Typography variant="subtitle3">{data.title}</Typography>
            </MenuItem>
          </StyledLink>
        ))}
      </ItemList>
    </Container>
  );
};
