export const updateSorting = async (field, set, get) => {
  const { sortingField, sortingOrder } = get();

  if (sortingField === "default") {
    set({ sortingField: field, sortingOrder: true, page: 1 });
    return;
  }
  if (sortingField === field && sortingOrder) {
    set({ sortingOrder: !sortingOrder, page: 1 });
    return;
  }
  if (sortingField === field && !sortingOrder) {
    set({
      sortingField: "default",
      sortingOrder: true,
      page: 1,
    });
    return;
  }
  set({ sortingField: field, sortingOrder: true, page: 1 });
};
