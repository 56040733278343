import { Box, styled } from "@mui/material";
import { HEADER_HEIGHT } from "../styles";
import { SignInHeader } from "../components/Header/Header";

const SignInLayout = ({ children }) => {
  return (
    <>
      <SignInHeader withBorder={false} />
      <Container>{children}</Container>
    </>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: `calc(100svh - ${HEADER_HEIGHT})`,
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(3),
  },
}));

export default SignInLayout;
