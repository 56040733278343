import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

export const updateUser = async (set) => {
  const refresh = localStorage.getItem("@Auth:refresh");
  try {
    set({ isLoading: true });
    const response = await api.post("/token/refresh/", {
      refresh,
    });
    const { access } = response.data;
    localStorage.setItem("@Auth:access", access);
    set({ access, isLoading: false });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};
