import ClearIcon from "@mui/icons-material/Clear";
import {
  IconImg,
  StyledIconButton,
  StyledTypography,
  ToastContainer,
} from "./Toaster.styles";
import WarningIcon from "../../assets/icons/WarningIcon.svg";
import BlueWarningIcon from "../../assets/icons/BlueWarningIcon.svg";
import { toast } from "sonner";

const iconsByType = {
  error: WarningIcon,
  info: BlueWarningIcon,
};
const Toaster = ({ t, message, type = "error" }) => {
  return (
    <ToastContainer type={type}>
      <IconImg src={iconsByType[type]} />
      <StyledTypography variant="body2" type={type}>
        {message}
      </StyledTypography>
      <StyledIconButton
        color="error"
        size="small"
        onClick={() => toast.dismiss(t)}
      >
        <ClearIcon fontSize="h3" />
      </StyledIconButton>
    </ToastContainer>
  );
};

export default Toaster;
