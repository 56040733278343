import api from "../../../services/api";
import { RESPONSES_PER_PAGE } from "../state";
import { errorFormater } from "../../../services/errorHandler";

const getList = (set, get) => async (isFiltersFromLocalStore) => {
  const prevIsReport = JSON.parse(localStorage.getItem("prevIsReport"));

  const { page, sortingField, sortingOrder, searchField } =
    isFiltersFromLocalStore && prevIsReport
      ? JSON.parse(localStorage.getItem("response-filters"))
      : get();
  const sorting =
    sortingField === "default"
      ? ""
      : `${sortingOrder ? "-" : ""}${sortingField}`;
  try {
    set({ isLoading: true });

    const response = await api.get(`/responses/`, {
      params: {
        page,
        order_by: sorting,
        q: searchField,
      },
    });

    localStorage.setItem("prevIsReport", JSON.stringify(false));
    prevIsReport &&
      set({
        page,
        sortingField,
        sortingOrder,
        searchField,
      });
    localStorage.setItem(
      "response-filters",
      JSON.stringify({
        page: 1,
        sortingField: "created_at",
        sortingOrder: true,
        searchField: "",
      })
    );

    const { count: totalResponses, results } = response.data;
    set({
      totalResponses,
      pageCount: Math.ceil(totalResponses / RESPONSES_PER_PAGE),
      responses: results,
      isLoading: false,
    });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default getList;
