import { createWithEqualityFn } from "zustand/traditional";
import { queryInitialData } from "./state";
import { createQueryStoreActions } from "./actions";

const useQueryStore = createWithEqualityFn((set, get) => ({
  ...queryInitialData,
  ...createQueryStoreActions(set, get),
}));

export default useQueryStore;
