import { Box, styled } from "@mui/material";

const SignInIcon = ({ src, alt }) => {
  return (
    <IconContainer>
      <StyledIcon src={src} alt={alt} />
    </IconContainer>
  );
};

const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 101,
  height: 101,
  borderRadius: "50%",
  backgroundColor: theme.palette.COLORS.BLUE0,
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down("md")]: {
    width: 80,
    height: 80,
    marginBottom: theme.spacing(1.5),
  },
}));

const StyledIcon = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

export default SignInIcon;
