import { useCallback } from "react";
import { Box } from "@mui/material";
import {
  ChoouseFileText,
  StyledLabel,
  StyledPaper,
} from "./DragDropFile.styles";
import cloudIcon from "../../assets/icons/CloudIcon.svg";

import { toast } from "sonner";
import Toaster from "../Toaster/Toaster";
import { isFileIsImage } from "../../utils/fileTypeCheck";

function DragDropFileUpload({
  onFileUpload,
  disabled,
  requirementsText,
  filesLenght,
}) {
  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      const files = Array.from(e.dataTransfer.files);
      formatAndValidateFiles(files, onFileUpload, filesLenght);
    },
    [onFileUpload, filesLenght]
  );

  const handleChange = useCallback(
    (event) => {
      if (event.target.files) {
        const files = Array.from(event.target.files);
        formatAndValidateFiles(files, onFileUpload, filesLenght);
        event.target.value = null;
      }
    },
    [onFileUpload, filesLenght]
  );

  return (
    <StyledPaper
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      disabled={disabled}
    >
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="button-file"
        multiple
        type="file"
        onChange={handleChange}
        disabled={disabled}
      />
      <StyledLabel htmlFor="button-file" disabled={disabled}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <img src={cloudIcon} alt="cloudIcon" />
          <ChoouseFileText variant="body3">
            Choose a file or drag it here
          </ChoouseFileText>
          <ChoouseFileText variant="small1">{requirementsText}</ChoouseFileText>
        </Box>
      </StyledLabel>
    </StyledPaper>
  );
}

export default DragDropFileUpload;

const formatAndValidateFiles = async (files, onFileUpload, filesLenght) => {
  const images = [];

  for (const file of files) {
    const isImg = await isFileIsImage(file);
    if (isImg) {
      images.push(file);
    } else {
      toast.custom((t) => (
        <Toaster t={t} message={`${file.name} is not an image`} />
      ));
    }
  }

  const passedImages = images.slice(0, 6 - filesLenght);

  onFileUpload((prev) => prev.concat(passedImages));
};
