import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  IconButton,
  Typography,
  styled,
} from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: 426,
  display: "flex",
  flexDirection: "column",
}));
export const Body = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
  gap: theme.spacing(4),
  height: "calc(100svh - 81px)",
}));
export const Footer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(2, 3),
  borderTop: `1px solid ${theme.palette.COLORS.NEUTRAL3}`,
}));
export const MenuHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
  padding: theme.spacing(1, 0),
}));
export const MunuSubheader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: theme.spacing(0.75),
  backgroundColor: theme.palette.COLORS.NEUTRAL15,
  "&:hover": {
    backgroundColor: theme.palette.COLORS.NEUTRAL1,
  },
}));
export const FiltersList = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL3}`,
}));
export const FiltersHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
  ...theme.typography.h2,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h3,
  },
}));
export const FilterTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle3,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.subtitle4,
  },
}));

export const HyperLink = styled(Typography)(({ theme }) => ({
  ...theme.typography.body7,
  color: theme.palette.COLORS.BLUE6,
  textDecoration: "underline",
  cursor: "pointer",
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0,
    padding: theme.spacing(2, 0),
  },
  "&.Mui-expanded": {
    minHeight: 62,
    margin: 0,
  },
}));
export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&::before": {
    height: 1,
    backgroundColor: theme.palette.COLORS.NEUTRAL3,
  },
  "&.Mui-expanded": {
    "&::before": {
      opacity: 1,
    },
  },
}));
export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  padding: 0,
  paddingBottom: theme.spacing(2),
}));

export const CheckboxLabel = styled(FormControlLabel)(({ theme, checked }) => ({
  width: "100%",
  backgroundColor: checked
    ? theme.palette.COLORS.BLUE05
    : theme.palette.COLORS.NEUTRAL05,
  border: `1px solid ${
    checked ? theme.palette.COLORS.BLUE6 : theme.palette.COLORS.NEUTRAL15
  }`,
  padding: theme.spacing(1, 1, 1, 2),
  borderRadius: theme.spacing(0.5),
  margin: 0,
  gap: theme.spacing(1),
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  "& .MuiTypography-root": {
    ...theme.typography.body2,
  },
}));
