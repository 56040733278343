import { TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/system";

export const LATableRow = ({ isActive, children }) => {
  return <TableBodyRow isActive={isActive}>{children}</TableBodyRow>;
};

const ToBeStyledTableRow = ({ isActive: _isActive, ...props }) => (
  <TableRow {...props} />
);

export const TableBodyRow = styled(ToBeStyledTableRow)(
  ({ isActive = true, theme }) => ({
    backgroundColor: theme.palette.COLORS.NEUTRAL0,
    borderRadius: theme.spacing(0.5),
    opacity: isActive ? 1 : 0.4,
    "& td:first-of-type": {
      borderRadius: theme.spacing(0.5, 0, 0, 0.5),
      padding: theme.spacing(1, 0, 1, 2.5),
    },
    "& td:last-child": {
      borderRadius: theme.spacing(0, 0.5, 0.5, 0),
      padding: theme.spacing(1, 2.5),
      width: 170,
      [theme.breakpoints.down("md")]: {
        minWidth: 160,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.COLORS.NEUTRAL05,
      "& td": {
        boxShadow: `0px 1px 0px 0px ${theme.palette.COLORS.NEUTRAL15}, 0px -1px 0px 0px ${theme.palette.COLORS.NEUTRAL15}`,
      },
      "& td:first-of-type": {
        boxShadow: `-1px 0px 0px 0px ${theme.palette.COLORS.NEUTRAL15}, 0px 1px 0px 0px ${theme.palette.COLORS.NEUTRAL15}, 0px -1px 0px 0px ${theme.palette.COLORS.NEUTRAL15}`,
      },
      "& td:last-child": {
        boxShadow: `1px 0px 0px 0px ${theme.palette.COLORS.NEUTRAL15}, 0px 1px 0px 0px ${theme.palette.COLORS.NEUTRAL15}, 0px -1px 0px 0px ${theme.palette.COLORS.NEUTRAL15}`,
      },
    },
  })
);

export const TableBodyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `none`,
  ...theme.typography.body4,
  color: theme.palette.COLORS.NEUTRAL9,
  padding: theme.spacing(1, 0, 1, 2),
  [theme.breakpoints.down("md")]: {
    ...theme.typography.body8,
  },
}));
