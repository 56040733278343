import { shallow } from "zustand/shallow";
import { LASwitch } from "../../../components/LASwitch";
import useUserStore from "../../../state/user/user-store";
import { MyQuerryContainer, ToggleTitle } from "../HomePage.styles";
import useQueryStore from "../../../state/query/query-store";
import { errorApiHandler } from "../../../services/errorHandler";
import { useNavigate } from "react-router-dom";

export const MyQuerryToggle = () => {
  const navigation = useNavigate();
  const [myQuerryOnly, handleChangeMyQuerryFilter] = useUserStore(
    (state) => [state.myQuerryOnly, state.handleChangeMyQuerryFilter],
    shallow
  );

  const [getList, changePage] = useQueryStore(
    (state) => [state.getList, state.changePage],
    shallow
  );

  const onToggleHandle = async (state) => {
    try {
      await handleChangeMyQuerryFilter(state);
      changePage(1);
      await getList();
    } catch (error) {
      errorApiHandler(error, navigation);
    }
  };

  return (
    <MyQuerryContainer>
      <ToggleTitle variant="body4">My queries only</ToggleTitle>
      <LASwitch
        checked={myQuerryOnly}
        onChange={(e) => {
          onToggleHandle(e.target.checked);
        }}
      />
    </MyQuerryContainer>
  );
};
