import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  styled,
} from "@mui/material";
import SearchIconGrey from "../../assets/icons/SearchIcon.svg";
import SearchIconBlue from "../../assets/icons/SearchIconBlue.svg";
import XMark from "../../assets/icons/XmarkIcon.svg";
import { useDisclosure } from "../../hooks/useDisclosure";

export const SearchInput = ({ value, onChange }) => {
  const focused = useDisclosure();

  return (
    <StyledTextField
      focused={focused.isOpen}
      onFocus={focused.open}
      onBlur={focused.close}
      placeholder="Search"
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onKeyDown={(e) => e?.stopPropagation()}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img
              src={focused.isOpen ? SearchIconBlue : SearchIconGrey}
              alt="SearchIcon"
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {value ? (
              <IconButtonStyled
                onClick={(e) => {
                  e.stopPropagation();
                  onChange("");
                }}
              >
                <img src={XMark} alt="XMarkIcon" />
              </IconButtonStyled>
            ) : (
              <EmptyIcon />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export const EmptyIcon = styled(Box)(({ theme }) => ({
  width: 32,
  height: 32,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  borderRadius: theme.spacing(1),
  width: "100%",
  "& .MuiInputBase-root": {
    paddingRight: theme.spacing(1),
    border: "none",
    "&:not(.Mui-focused):hover fieldset": {
      border: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  },
  "& .Mui-focused > .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.COLORS.BLUE6}`,
  },
  "& fieldset": {
    borderColor: "transparent",
    borderRadius: theme.spacing(1),
    borderWidth: 0,
  },
  "& input": {
    height: 40,
    padding: 0,
    ...theme.typography.body3,
  },
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.COLORS.NEUTRAL0,
  },
}));
