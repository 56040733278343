import { Box, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const FormGroup = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
export const FormFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing(2.5),
  marginTop: theme.spacing(6),
}));
export const SignInHeader = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));
export const CustomForm = styled("form")(() => ({
  width: 408,
  display: "flex",
  flexDirection: "column",
  gap: 20,
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  width: 194,
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.COLORS.BLUE7,
}));
export const Header = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h3,
  },
}));
