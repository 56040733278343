import { Box, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const ResetContent = styled(({ sent: __sent, ...restProps }) => (
  <Box {...restProps} />
))(({ theme, sent }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  padding: `64px ${sent ? 62 : 70}px`,
  width: sent ? 525 : 700,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    width: sent ? 561 : 761,
    padding: `52px 100px`,
  },
}));

export const FormFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2.5),
  marginTop: theme.spacing(3.5),
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  width: 140,
}));

export const InputContainer = styled(Box)(({ theme }) => ({
  width: 374,
  marginTop: theme.spacing(1),
}));
export const CustomForm = styled("form")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing(2.5),
}));

export const EMailIconContainer = styled(Box)(({ theme }) => ({
  width: 101,
  height: 101,
  borderRadius: "50%",
  backgroundColor: theme.palette.COLORS.BLUE0,
  marginBottom: theme.spacing(1.5),
}));

export const EMailIcon = styled("img")(({ theme }) => ({}));

export const Header = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h3,
  },
}));
export const SubText = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
}));
export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.COLORS.BLUE9,
}));
