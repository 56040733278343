import EmailIcon from "../../assets/icons/EmailIcon.svg";

import {
  ButtonContainer,
  CustomForm,
  FormFooter,
  Header,
  InputContainer,
  ResetContent,
  StyledLink,
  SubText,
} from "./ResetPasswordEmail.styles";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { InputController } from "../../components/BaseInput/InputController";
import { LAButton } from "../../components/LAButton/LAButton";
import CheckEmailContent from "./CheckEmailContent/CheckEmailContent";
import { useState } from "react";
import SignInLayout from "../../Layouts/SignInLayout";
import SignInIcon from "../../components/SignInIcon/SignInIcon";
import { yupResolver } from "@hookform/resolvers/yup";
import { shallow } from "zustand/shallow";
import useUserStore from "../../state/user/user-store";
import { useNavigate } from "react-router-dom";
import { errorApiHandler } from "../../services/errorHandler";

const ResetPasswordEmail = () => {
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();
  const [sendEmail, isLoading] = useUserStore(
    (state) => [state.sendEmailToResetPassword, state.isLoading],
    shallow
  );
  const {
    handleSubmit,
    control,
    formState: { isValid },
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email("Enter a valid email")
          .required("Email is required"),
      })
    ),
  });

  const handleSendEmail = async (values) => {
    try {
      await sendEmail(values);
      setSent(true);
    } catch (error) {
      errorApiHandler(error, navigate, setError);
    }
  };

  return (
    <SignInLayout>
      <ResetContent sent={sent}>
        {sent ? (
          <CheckEmailContent email={getValues("email")} />
        ) : (
          <CustomForm
            onSubmit={handleSubmit((values) => handleSendEmail(values))}
          >
            <SignInIcon src={EmailIcon} alt="email-icon" />

            <Header variant="h2" align="center">
              Reset password
            </Header>
            <SubText variant="subtitle3" component="p" align="center">
              Enter the email associated with your account and we will send an
              email with instructions to reset your password.
            </SubText>
            <InputContainer>
              <InputController
                control={control}
                label="Email"
                name="email"
                placeholder="Enter email"
                type="email"
                inputProps={{
                  "data-testid": "signin-email-field",
                }}
                onBlur={(e) =>
                  setValue("email", e.target.value.trim().replace(/ /g, ""), {
                    shouldValidate: true,
                  })
                }
                showErrorMessage
              />
            </InputContainer>

            <FormFooter>
              <ButtonContainer justifyContent="center">
                <StyledLink to="/signin">
                  <LAButton
                    text="Cancel"
                    type="button"
                    variant="secondary"
                    data-testid="cancel-reset"
                    fullWidth
                  />
                </StyledLink>
              </ButtonContainer>
              <ButtonContainer justifyContent="center">
                <LAButton
                  text="Continue"
                  type="submit"
                  variant="primary"
                  disabled={!isValid}
                  data-testid="send-email"
                  fullWidth
                  loading={isLoading}
                />
              </ButtonContainer>
            </FormFooter>
          </CustomForm>
        )}
      </ResetContent>
    </SignInLayout>
  );
};

export default ResetPasswordEmail;
