import changePage from "../query/actions/changePage";
import onSearchChange from "../query/actions/onSearchChange";
import { updateSorting } from "../query/actions/updateSorting";
import createEntity from "./actions/createEntity";
import deleteEntityDraft from "./actions/deleteEntityDraft";
import deleteImagesFromDraft from "./actions/deleteImagesFromDraft";
import editEntity from "./actions/editEntity";
import getComplianceGroup from "./actions/getComplianceGroup";
import getList from "./actions/getComplianceList";
import getComplianceListForReport from "./actions/getComplianceListForReport";
import getEntity from "./actions/getEntity";
import { resetSorting } from "./actions/resetSorting";
import updateEntityStatus from "./actions/updateEntityStatus";
import updateOptions from "./actions/updateOptions";
import uploadImagesToCompliance from "./actions/uploadImagesToCompliance";

export const createEntityStoreActions = (set, get) => ({
  updateSorting: (field) => updateSorting(field, set, get),
  changePage: changePage(set),
  getList: getList(set, get),
  getComplianceListForReport: getComplianceListForReport(set, get),
  onSearchChange: onSearchChange(set),
  createEntity: createEntity(set),
  getEntity: getEntity(set, get),
  editEntity: editEntity(set),
  updateEntityStatus: updateEntityStatus(set, get),
  updateOptions: updateOptions(set, get),
  resetSorting: resetSorting(set),
  setEntityToView: (data) => {
    set({
      entityToView: data,
    });
  },
  uploadImagesToCompliance: uploadImagesToCompliance(set),
  deleteImagesFromDraft: deleteImagesFromDraft(set),
  getComplianceGroup: getComplianceGroup(set),
  deleteEntityDraft: deleteEntityDraft(set),
  clearState: () => {
    set({
      entityId: null,
      currentVersionId: null,
      entityToView: null,
      draftId: null,
      complianceVersions: [],
    });
  },
});
