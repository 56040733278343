import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

export const signin = async (email, password, set) => {
  const credentials = {
    email: email.toLocaleLowerCase(),
    password,
  };
  try {
    set({ isLoading: true });
    const response = await api.post("/token/", credentials);
    const { access, refresh } = response.data;
    localStorage.setItem("@Auth:access", access);
    localStorage.setItem("@Auth:refresh", refresh);
    set({ access, refresh, isLoading: false });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};
