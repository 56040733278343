const selectAll = (set, get) => (checked) => {
  const { completedQuerries } = get();
  if (checked) {
    set({ selectedQuerries: completedQuerries.map((n) => n.id) });
  } else {
    set({ selectedQuerries: [] });
  }
};

export default selectAll;
