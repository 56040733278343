import dayjs from "dayjs";
import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const editEntity = (set) => async (data, id, isDraft) => {
  const entity = {
    ...data,
  };
  Object.entries(entity).forEach(([key, value]) => {
    if (key.startsWith("is_") && value === "") {
      entity[key] = null;
    }
  });
  if (!entity.law_added_at) {
    entity.law_added_at = null;
  } else {
    entity.law_added_at = dayjs(entity.law_added_at).format("YYYY-MM-DD");
  }
  delete entity.images;

  try {
    set({ isLoading: true });
    const { data: result } = await api.put(`/compliances/${id}/`, {
      ...entity,
      is_draft: !!isDraft,
    });
    set({ isLoading: false });
    return result;
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default editEntity;
