export const HEADER_HEIGHT = "90px";
export const MAX_CONTENT_WIDTH = "1920px";

const NUMBER_OF_WORDS_WITH_8_BITS = 256;
const HEX_RADIX = 16;

export const transparencyToHex = (percentageValue) => {
  if (percentageValue >= 1) {
    return "FF";
  }
  if (percentageValue <= 0) {
    return "00";
  }
  const val = Math.round(
    percentageValue * NUMBER_OF_WORDS_WITH_8_BITS
  ).toString(HEX_RADIX);

  if (val.length === 1) {
    return `0${val}`;
  }
  return val;
};

export const elipsedText = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export const customScroll = {
  "&::-webkit-scrollbar": {
    width: 6,
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    cursor: "pointer",
    background: "#C3C5C8",
    borderRadius: 3,
  },
  "&::-webkit-scrollbar-track": {
    margin: "10px 0",
    background: "transparent",
    borderRadius: 3,
  },
};
export const smallCustomScroll = {
  ...customScroll,
  "&::-webkit-scrollbar": {
    width: 4,
    background: "transparent",
  },
};

export const smallHorizontalCustomScroll = {
  "&::-webkit-scrollbar": {
    height: 4,
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    cursor: "pointer",
    background: "#C3C5C8",
    borderRadius: 3,
  },
  "&::-webkit-scrollbar-track": {
    margin: "0 10px",
    background: "transparent",
    borderRadius: 3,
  },
};
