import { Box, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing(2.5),
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3.5),
  width: 168,
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(2.5),
  },
}));

export const Header = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h3,
  },
}));
export const SubText = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.subtitle4,
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));
