import { Box, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2.5),
}));
export const Main = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 200,
  paddingBottom: theme.spacing(5),
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL15}`,

  [theme.breakpoints.down("lg")]: {
    width: "100%",
    gap: theme.spacing(17),
    justifyContent: "space-between",
  },
  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(11),
  },
}));
export const InputsContainer = styled(Box)(({ theme }) => ({
  minWidth: 400,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
export const Footer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
}));
export const CustomFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

export const Content = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  padding: theme.spacing(5, 6.5),
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(5),
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(4),
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(3.5),
  },
}));

export const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h2,
  },
}));
export const StyledText = styled(Typography)(({ theme }) => ({
  whiteSpace: "nowrap",
  [theme.breakpoints.down("md")]: {
    ...theme.typography.subtitle4,
  },
}));
export const ButtonContainer = styled(Box)(({ theme }) => ({
  width: 118,
}));

export const RulesBlock = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.COLORS.NEUTRAL05,
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.spacing(1),
  gap: theme.spacing(1),
}));
export const RulesHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL9,
}));
export const RulesText = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL8,
}));
export const Rules = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.25),
}));
export const RuleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1.25),
  alignItems: "start",
}));
export const Point = styled(Box)(({ theme }) => ({
  minWidth: theme.spacing(1),
  minHeight: theme.spacing(1),
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: 2,
  marginTop: theme.spacing(1),
  backgroundColor: theme.palette.COLORS.NEUTRAL2,
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));
