import {
  Container,
  PageHeader,
  StyledHeader,
  HeaderRow,
  QueriesCount,
  Actions,
  Filters,
} from "../HomePage/HomePage.styles";
import { Box } from "@mui/material";
import { useDisclosure } from "../../hooks/useDisclosure";
import { shallow } from "zustand/shallow";
import { useEffect } from "react";
import LoadingPage from "../LoadingPage/LoadingPage";
import { NoDataFound } from "../HomePage/NoDataFound/NoDataFound";
import { useNavigate } from "react-router-dom";
import PagesLayout from "../../Layouts/PagesLayout";
import { ResponsesPageTable } from "./ResponsesPageTable/ResponsesPageTable";
import { errorApiHandler } from "../../services/errorHandler";
import useResponseStore from "../../state/responses/responses-store";
import { SearchContainer } from "./ResponsesPage.styles";
import { FinaliseReportPopup } from "../QueuePage/FinaliseReportPopup/FinaliseReportPopup";
import useQueueStore from "../../state/queue/queue-store";
import { SearchComponent } from "../../components/SeachComponent/SearchComponent";
const ResponsesPage = () => {
  const [
    totalResponses,
    getList,
    isLoading,
    getResponseDetails,
    resetPageProps,
    updateCurrentFiltersInLocalStore,
  ] = useResponseStore(
    (state) => [
      state.totalResponses,
      state.getList,
      state.isLoading,
      state.getDetails,
      state.resetPageProps,
      state.updateCurrentFiltersInLocalStore,
    ],
    shallow
  );

  const [isQueueLoading, getQueueFinalise] = useQueueStore(
    (state) => [state.isLoading, state.getQueueFinalise],
    shallow
  );
  const navigation = useNavigate();
  const finaliseReportModal = useDisclosure();

  const handleGetFinaliseRoport = async (id) => {
    try {
      await getResponseDetails(id);
      await getQueueFinalise(id);
      finaliseReportModal.open();
    } catch (error) {
      errorApiHandler(error, navigation);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const isFromReport = JSON.parse(localStorage.getItem("prevIsReport"));
        isFromReport ? await getList(true) : await getList();
      } catch (error) {
        errorApiHandler(error, navigation);
      }
    };
    getData();
  }, [getList, navigation]);

  useEffect(() => {
    return () => {
      resetPageProps();
    };
  }, [resetPageProps]);
  return (
    <PagesLayout>
      <Container>
        <PageHeader>
          <HeaderRow>
            <StyledHeader variant="h1">Responses</StyledHeader>
          </HeaderRow>
          <HeaderRow>
            <Actions>
              <QueriesCount variant="h3">
                {totalResponses} responses
              </QueriesCount>
            </Actions>
            <Filters>
              <SearchContainer>
                <SearchComponent store={useResponseStore} />
              </SearchContainer>
            </Filters>
          </HeaderRow>
        </PageHeader>
        <Box>
          {totalResponses ? (
            <ResponsesPageTable
              handleFinalise={(id) => {
                handleGetFinaliseRoport(id);
              }}
              handleViewDetails={(id) => {
                updateCurrentFiltersInLocalStore();
                navigation(`${id}`);
              }}
            />
          ) : (
            !isLoading && <NoDataFound />
          )}
        </Box>
      </Container>
      {<LoadingPage isLoading={isLoading || isQueueLoading} />}
      {finaliseReportModal.isOpen ? (
        <FinaliseReportPopup onClose={finaliseReportModal.close} inResponse />
      ) : null}
    </PagesLayout>
  );
};

export default ResponsesPage;
