import { Container, PageHeader, PageTitle } from "./QueuePageReport.styles";
import { ReportData } from "../../components/ReportData/ReportData";
import { BackButton } from "../../components/BackButton/BackButton";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PagesLayout from "../../Layouts/PagesLayout";
import { shallow } from "zustand/shallow";
import useQueueStore from "../../state/queue/queue-store";

export const jurisdictionLabels = [
  "STR allowed",
  "Permit required",
  "Has permit",
  "Permit expiration date",
  "Date law was added",
];
export const STRLabels = [
  "Is Owner Occupancy Required?",
  "Limit on number or length of stays?",
  "Entity Limits on Permits per Owner?",
  "Entity Limits on Number of Permit?",
  "License Transferability to other Parties?",
  "Are there Zoning Restrictions?",
];

export const QueuePageReport = () => {
  const navigate = useNavigate();
  const [queueDetails, isLoading, getStateFromLocalStore] = useQueueStore(
    (state) => [
      state.queueDetails,
      state.isLoading,
      state.getStateFromLocalStore,
    ],
    shallow
  );
  useEffect(() => {
    if (queueDetails === null && !isLoading) {
      navigate("/*");
    }
  }, [queueDetails, navigate, isLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("prevIsReport", JSON.stringify(true));
  }, []);

  return (
    <PagesLayout>
      <Container>
        <BackButton
          onClick={() => {
            getStateFromLocalStore();
            navigate("/queue", { state: { prev: "report" } });
          }}
        />
        <PageTitle>
          <PageHeader>Short term rental compliance report</PageHeader>
        </PageTitle>

        <ReportData report={queueDetails} store={useQueueStore} />
      </Container>
    </PagesLayout>
  );
};
