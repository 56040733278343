import { shallow } from "zustand/shallow";
import { LATable } from "../../../components/Table/Table";
import useEntityStore from "../../../state/compliance/compliance-store";
import {
  LATableRow,
  TableBodyCell,
} from "../../../components/Table/TableRow/TableRow";
import { Box, TableBody, Typography } from "@mui/material";
import { HeaderCellContainer } from "../../../components/Table/TableHead/TableHead.styles";
import { LAButton } from "../../../components/LAButton/LAButton";
import { LASwitch } from "../../../components/LASwitch";
import { NameContainer, StateContainer } from "./ComplianceTable.styles";
import { useDisclosure } from "../../../hooks/useDisclosure";
import { DeleteEntityPopup } from "../DeleteEntityPopup/DeleteEntityPopup";
import { useState } from "react";
import { errorApiHandler } from "../../../services/errorHandler";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../theme/colors";

export const ComplianceTable = ({ handleViewDetails, handleEdit }) => {
  const navigation = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [entities, updateEntityStatus, rows, getList] = useEntityStore(
    (state) => [
      state.entities,
      state.updateEntityStatus,
      state.rows,
      state.getList,
    ],
    shallow
  );

  const warningDialog = useDisclosure();

  const handleChangeActivity = async (id) => {
    try {
      await updateEntityStatus(
        !rows.find((e) => e.id === (id || selectedRow)).is_active,
        id || selectedRow
      );
      await getList();
      warningDialog.close();
      setSelectedRow(null);
    } catch (error) {
      errorApiHandler(error, navigation);
    }
  };

  const tableHeaders = [
    {
      title: "Name",
      name: "name",
      isSortable: true,
    },
    {
      title: "Version",
      name: "version",
      isSortable: false,
    },
    {
      title: "Type",
      name: "type",
      isSortable: true,
    },
    {
      title: "State",
      name: "state",
      isSortable: false,
    },
    {
      title: "STR allowed",
      name: "str_allowed",
      isSortable: false,
    },
    {
      title: "Permit required",
      name: "permit_is_required",
      isSortable: false,
    },
    {
      title: "Active",
      name: "is_active",
      isSortable: false,
    },
    {
      title: "",
      name: "",
      isSortable: false,
    },
  ];

  const [hasDraft, setHasDraft] = useState(false);
  return (
    <LATable
      itemsCount={entities.length}
      store={useEntityStore}
      tableHeaders={tableHeaders}
    >
      {!!entities.length ? (
        <TableBody>
          {entities.map((row) => {
            const currentRowIsActive = rows.find(
              (e) => e.id === row.id
            )?.is_active;

            return (
              <LATableRow key={row.id}>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <NameContainer>{row.name}</NameContainer>
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer
                    style={{
                      color: !row.version ? COLORS.RED6 : COLORS.NEUTRAL9,
                    }}
                  >
                    {!row.version ? "Draft" : `Version ${row.version}`}
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>{row.type}</HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <StateContainer>{row.state}</StateContainer>
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    {row.is_str_allowed ? "Yes" : "No"}
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    {row.is_permit_required === null
                      ? ""
                      : row.is_permit_required
                      ? "Yes"
                      : "No"}
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    {
                      <LASwitch
                        checked={currentRowIsActive}
                        onChange={(e) => {
                          setSelectedRow(row.id);
                          if (!e.target.checked) {
                            setHasDraft(!!row.draft);
                            warningDialog.open();
                          } else {
                            handleChangeActivity(row.id);
                          }
                        }}
                      />
                    }
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <Box display={"flex"} gap={"20px"} alignItems={"center"}>
                      <LAButton
                        text="View Details"
                        fullWidth
                        size="sm"
                        onClick={() => handleViewDetails(row.draft || row.id)}
                      />
                      <Typography
                        sx={{
                          color: row.draft ? COLORS.RED6 : COLORS.NEUTRAL9,
                          opacity: currentRowIsActive ? 1 : 0.4,
                          "&:hover": {
                            cursor: currentRowIsActive ? "pointer" : "default",
                          },
                        }}
                        variant="body2"
                        onClick={() => {
                          if (currentRowIsActive) {
                            handleEdit(row.draft || row.id);
                          }
                        }}
                      >
                        {row.draft ? "View draft" : "Edit"}
                      </Typography>
                    </Box>
                  </HeaderCellContainer>
                </TableBodyCell>
              </LATableRow>
            );
          })}
        </TableBody>
      ) : null}
      {warningDialog.isOpen ? (
        <DeleteEntityPopup
          onClose={warningDialog.close}
          onDelete={() => handleChangeActivity()}
          isDraft={hasDraft}
        />
      ) : null}
    </LATable>
  );
};
