export const updateSelectedQueriesInLocalStore = (get) => () => {
  const { selectedQuerries } = get();
  localStorage.setItem("selectedQuerries", JSON.stringify(selectedQuerries));
};
export const updateSelectedQueriesFromLocalStore = (set) => () => {
  const selectedQuerries = JSON.parse(localStorage.getItem("selectedQuerries"));
  set({
    selectedQuerries,
  });
};

export const updateCurrentFiltersInLocalStore = (get) => () => {
  const { page, sortingField, sortingOrder, searchField, filters } = get();

  localStorage.setItem(
    "query-filters",
    JSON.stringify({
      page,
      filters,
      sortingField,
      sortingOrder,
      searchField,
    })
  );
};

export const getStateFromLocalStore = (set) => () => {
  const { page, sortingField, sortingOrder, searchField, filters } = JSON.parse(
    localStorage.getItem("query-filters")
  );
  set({
    page,
    sortingField,
    sortingOrder,
    searchField,
    filters,
  });
};
