import { createTheme } from "@mui/material/styles";
import baseTheme from "./baseTheme";

let theme = createTheme(baseTheme);

theme = {
  ...theme,
  props: {
    MuiCheckbox: {
      color: "primary",
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
};
export default theme;
