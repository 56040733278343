import {
  StyledForm,
  FormRow,
  InputContainer,
  LabelContainer,
  InputLabel,
  RadioGroupContainer,
  CustomRadioGroup,
  RadioLabel,
  ButtonContainer,
  StrLaws,
  ContainerWithNotes,
} from "../../../CompliancePage/CreateEntityPopup/CreateEntityPopup.styles";
import { Box } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { toast } from "sonner";

import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";
import useEntityStore from "../../../../state/compliance/compliance-store";
import { entitySchema } from "../../../CompliancePage/CreateEntityPopup/EntityScheme";
import Toaster from "../../../../components/Toaster/Toaster";
import { errorApiHandler } from "../../../../services/errorHandler";
import { InputController } from "../../../../components/BaseInput/InputController";
import { MultiSelect } from "../../../../components/MultiSelect/MultiSelect";
import { USStates } from "../../../../mock/States";
import CustomAutocomplete from "../../../../components/Autocomplete/Autocomplete";
import { ModalDialog } from "../../../../components/ModalDialog/ModalDialog";
import { LARadio } from "../../../../components/LARadio";
import { StyledError } from "../../../HomePage/AddQuerryPopup/AddQueryPopup.styles";
import Calendar from "../../../../components/CalendarInput/CalenderInput";
import { LAButton } from "../../../../components/LAButton/LAButton";
import useQueueStore from "../../../../state/queue/queue-store";

const options = [
  {
    label: "City",
    value: "City",
  },
  {
    label: "County",
    value: "County",
  },
  {
    label: "State",
    value: "State",
  },
  {
    label: "HOA",
    value: "HOA",
  },
];
export const yes_no_options = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const formatEntity = (entity) => {
  const newEntity = {
    ...entity,
    law_added_at: entity?.law_added_at
      ? new Date(dayjs(entity.law_added_at))
      : null,
  };
  Object.entries(newEntity).forEach(([key, value]) => {
    if (key.startsWith("is_") && value === null) {
      newEntity[key] = "";
    }
  });
  return newEntity;
};
export const OverrideEntityPopup = ({ onClose, onSuccess }) => {
  const navigate = useNavigate();

  const [isQueueLoading, overrideEntity, idToOverride] = useQueueStore(
    (state) => [state.isLoading, state.overrideEntity, state.idToOverride],
    shallow
  );

  const [entityToView] = useEntityStore(
    (state) => [state.entityToView],
    shallow
  );
  const {
    handleSubmit,
    control,
    setError,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: formatEntity(entityToView),
    mode: "onChange",
    resolver: yupResolver(entitySchema),
  });

  const { type, is_str_allowed } = watch();
  const handleSaveOverride = async (values) => {
    try {
      await overrideEntity({
        id: idToOverride,
        type: values.type?.toLowerCase(),
        data: values,
      });
      toast.custom((t) => (
        <Toaster
          t={t}
          message={"Entity has been successfully overriden."}
          type="info"
        />
      ));
      await onSuccess();
      onClose();
    } catch (error) {
      errorApiHandler(error, navigate, setError);
    }
  };
  useEffect(() => {
    if (is_str_allowed === "false") {
      reset({
        ...getValues(),
        is_permit_required: "",
      });
    }
  }, [is_str_allowed, reset, getValues]);

  useEffect(() => {
    const firstErrorKey = Object.keys(errors).find((key) => errors[key]);
    if (firstErrorKey) {
      document.querySelector(`input[name="${firstErrorKey}"]`)?.focus();
    }
  }, [errors]);

  const renderFromTypeFirstRow = (type) => {
    switch (type) {
      case "HOA": {
        return (
          <InputContainer disabled>
            <InputController
              control={control}
              label="Name*"
              name="name"
              placeholder="Enter name"
              showErrorMessage
              disabled
            />
          </InputContainer>
        );
      }
      case "State": {
        return (
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error } }) => {
              return (
                <MultiSelect
                  multiple={false}
                  options={USStates}
                  label="Name*"
                  noItemsSelectedLabel="Enter name"
                  {...field}
                  onChange={(e) => {
                    setValue(field.name, e.target.value);
                    setValue("state", e.target.value);
                  }}
                  showErrorMessage
                  error={error}
                  disabled
                />
              );
            }}
          />
        );
      }
      case "": {
        return null;
      }
      default: {
        return (
          <InputContainer disabled>
            <Controller
              control={control}
              name="address"
              render={({ field: { ref, ...field }, fieldState: { error } }) => {
                return (
                  <CustomAutocomplete
                    refer={ref}
                    label="Address*"
                    name="address"
                    options={[]}
                    showErrorMessage
                    error={error}
                    disabled
                    {...field}
                  />
                );
              }}
            />
          </InputContainer>
        );
      }
    }
  };
  const renderFromTypeSecondRow = (type) => {
    switch (type) {
      case "HOA": {
        return (
          <FormRow>
            <InputContainer disabled>
              <Controller
                control={control}
                name="state"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={USStates}
                      label="State*"
                      noItemsSelectedLabel="Enter state"
                      showErrorMessage
                      error={error}
                      disabled
                      {...field}
                    />
                  );
                }}
              />
            </InputContainer>
            <Box width={"100%"} />
          </FormRow>
        );
      }
      case "State": {
        return (
          <FormRow>
            <InputContainer disabled>
              <InputController
                control={control}
                label="State*"
                name="state"
                placeholder="Enter state"
                disabled
                showErrorMessage
              />
            </InputContainer>
            <Box width={"100%"} />
          </FormRow>
        );
      }
      case "": {
        return null;
      }
      default: {
        return (
          <FormRow>
            <InputContainer disabled>
              <InputController
                control={control}
                label="Name*"
                name="name"
                placeholder="Enter name"
                showErrorMessage
                disabled
              />
            </InputContainer>
            <InputContainer disabled>
              <InputController
                control={control}
                label="State*"
                name="state"
                placeholder="Enter state"
                showErrorMessage
                disabled
              />
            </InputContainer>
          </FormRow>
        );
      }
    }
  };
  return (
    <ModalDialog title={"Override Entity"} onClose={onClose}>
      <StyledForm
        onSubmit={handleSubmit((values) => handleSaveOverride(values))}
      >
        <FormRow>
          <Controller
            control={control}
            name="type"
            render={({ field, fieldState: { error } }) => {
              return (
                <MultiSelect
                  multiple={false}
                  options={options}
                  label="Entity type*"
                  noItemsSelectedLabel="Select entity type"
                  showErrorMessage
                  error={error}
                  disabled
                  {...field}
                />
              );
            }}
          />
          <Box width={"100%"}>{renderFromTypeFirstRow(type)}</Box>
        </FormRow>
        {renderFromTypeSecondRow(type)}
        <FormRow>
          <RadioGroupContainer>
            <LabelContainer>
              <InputLabel variant="body7" htmlFor={"is_str_allowed"}>
                STR allowed?*
              </InputLabel>
            </LabelContainer>
            <Controller
              control={control}
              name="is_str_allowed"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Box justifyContent={"flex-start"} position={"relative"}>
                    <CustomRadioGroup {...field}>
                      <RadioLabel
                        value={true}
                        control={<LARadio />}
                        label="Yes"
                      />
                      <RadioLabel
                        value={false}
                        control={<LARadio />}
                        label="No"
                      />
                      <StyledError variant="small1" paragraph>
                        {error?.message}
                      </StyledError>
                    </CustomRadioGroup>
                  </Box>
                );
              }}
            />
          </RadioGroupContainer>
          {is_str_allowed === "true" || is_str_allowed === true ? (
            <RadioGroupContainer>
              <LabelContainer>
                <InputLabel variant="body7" htmlFor={"is_permit_required"}>
                  Permit required?*
                </InputLabel>
              </LabelContainer>
              <Controller
                control={control}
                name="is_permit_required"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Box justifyContent={"flex-start"} position={"relative"}>
                      <CustomRadioGroup {...field}>
                        <RadioLabel
                          value="true"
                          control={<LARadio />}
                          label="Yes"
                        />
                        <RadioLabel
                          value="false"
                          control={<LARadio />}
                          label="No"
                        />
                        <StyledError variant="small1" paragraph>
                          {error?.message}
                        </StyledError>
                      </CustomRadioGroup>
                    </Box>
                  );
                }}
              />
            </RadioGroupContainer>
          ) : null}
        </FormRow>
        <FormRow>
          <InputContainer disabled>
            <InputController
              control={control}
              label="Legislation name"
              name="legislation_name"
              placeholder="Enter legislation name"
              showErrorMessage
              disabled
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer disabled>
            <InputController
              control={control}
              label="Legislation website"
              name="legislation_web_url"
              placeholder="Enter legislation website link"
              showErrorMessage
              disabled
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer disabled>
            <InputController
              control={control}
              label="Legislation file upload"
              name="legislation_upload_url"
              placeholder="Enter legislation link"
              showErrorMessage
              disabled
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer disabled>
            <InputController
              control={control}
              label="Contact name"
              name="contact_name"
              placeholder="Enter contact name"
              showErrorMessage
              disabled
            />
          </InputContainer>
          <InputContainer disabled>
            <InputController
              control={control}
              label="Contact email"
              name="contact_email"
              placeholder="Enter contact email"
              showErrorMessage
              disabled
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer disabled>
            <InputController
              control={control}
              label="Contact phone"
              name="contact_phone"
              placeholder="Enter contact phone"
              showErrorMessage
              disabled
            />
          </InputContainer>
          <InputContainer disabled>
            <Controller
              control={control}
              name="law_added_at"
              render={({ field }) => (
                <Calendar
                  label="Date law was added"
                  placeholder="MM/DD/YYYY"
                  startDate={field.value}
                  selectsRange={false}
                  {...field}
                  disabled
                />
              )}
            />
          </InputContainer>
        </FormRow>
        <StrLaws>Additional STR Laws</StrLaws>
        <FormRow>
          <ContainerWithNotes>
            <Box>
              <Controller
                control={control}
                name="is_owner_occupancy_required"
                render={({ field }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={yes_no_options}
                      label="Is Owner Occupancy Required?"
                      noItemsSelectedLabel="Select an option"
                      canClear
                      {...field}
                      onClear={() => {
                        setValue(field.name, "");
                        setValue("owner_occupancy_required_notes", "");
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!e.target.value) {
                          setValue("owner_occupancy_required_notes", "");
                        }
                      }}
                    />
                  );
                }}
              />
            </Box>
            {watch("is_owner_occupancy_required") === true ? (
              <Box>
                <InputController
                  control={control}
                  label="Notes"
                  name="owner_occupancy_required_notes"
                  placeholder="Enter notes"
                  multiline
                  showErrorMessage
                />
              </Box>
            ) : null}
          </ContainerWithNotes>
          <ContainerWithNotes>
            <Box>
              <Controller
                control={control}
                name="is_entity_limits_number_permit"
                render={({ field }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={yes_no_options}
                      label="Entity Limits on Number of Permit?"
                      noItemsSelectedLabel="Select an option"
                      canClear
                      {...field}
                      onClear={() => {
                        setValue(field.name, "");
                        setValue("entity_limits_number_permit_notes", "");
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!e.target.value) {
                          setValue("entity_limits_number_permit_notes", "");
                        }
                      }}
                    />
                  );
                }}
              />
            </Box>
            {watch("is_entity_limits_number_permit") === true ? (
              <Box>
                <InputController
                  control={control}
                  label="Notes"
                  name="entity_limits_number_permit_notes"
                  placeholder="Enter notes"
                  multiline
                  showErrorMessage
                />
              </Box>
            ) : null}
          </ContainerWithNotes>
        </FormRow>
        <FormRow>
          <ContainerWithNotes>
            <Box>
              <Controller
                control={control}
                name="is_limit_length_stay"
                render={({ field }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={yes_no_options}
                      label="Limit on number or length of stays?"
                      noItemsSelectedLabel="Select an option"
                      canClear
                      {...field}
                      onClear={() => {
                        setValue(field.name, "");
                        setValue("limit_length_stay_notes", "");
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!e.target.value) {
                          setValue("limit_length_stay_notes", "");
                        }
                      }}
                    />
                  );
                }}
              />
            </Box>
            {watch("is_limit_length_stay") === true ? (
              <Box>
                <InputController
                  control={control}
                  label="Notes"
                  name="limit_length_stay_notes"
                  placeholder="Enter notes"
                  multiline
                  showErrorMessage
                />
              </Box>
            ) : null}
          </ContainerWithNotes>
          <ContainerWithNotes>
            <Box>
              <Controller
                control={control}
                name="is_license_transferability"
                render={({ field }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={yes_no_options}
                      label="License Transferability to other Parties?"
                      noItemsSelectedLabel="Select an option"
                      canClear
                      {...field}
                      onClear={() => {
                        setValue(field.name, "");
                        setValue("license_transferability_notes", "");
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!e.target.value) {
                          setValue("license_transferability_notes", "");
                        }
                      }}
                    />
                  );
                }}
              />
            </Box>
            {watch("is_license_transferability") === true ? (
              <Box>
                <InputController
                  control={control}
                  label="Notes"
                  name="license_transferability_notes"
                  placeholder="Enter notes"
                  multiline
                  showErrorMessage
                />
              </Box>
            ) : null}
          </ContainerWithNotes>
        </FormRow>
        <FormRow>
          <ContainerWithNotes>
            <Box>
              <Controller
                control={control}
                name="is_entity_limits_permit_owner"
                render={({ field }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={yes_no_options}
                      label="Entity Limits on Permits per Owner?"
                      noItemsSelectedLabel="Select an option"
                      canClear
                      {...field}
                      onClear={() => {
                        setValue(field.name, "");
                        setValue("entity_limits_permit_owner_notes", "");
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!e.target.value) {
                          setValue("entity_limits_permit_owner_notes", "");
                        }
                      }}
                    />
                  );
                }}
              />
            </Box>
            {watch("is_entity_limits_permit_owner") === true ? (
              <Box>
                <InputController
                  control={control}
                  label="Notes"
                  name="entity_limits_permit_owner_notes"
                  placeholder="Enter notes"
                  multiline
                  showErrorMessage
                />
              </Box>
            ) : null}
          </ContainerWithNotes>
          <ContainerWithNotes>
            <Box>
              <Controller
                control={control}
                name="is_zone_restricted"
                render={({ field }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={yes_no_options}
                      label="Are there Zoning Restrictions?"
                      noItemsSelectedLabel="Select an option"
                      canClear
                      {...field}
                      onClear={() => {
                        setValue(field.name, "");
                        setValue("zone_restriction_notes", "");
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!e.target.value) {
                          setValue("zone_restriction_notes", "");
                        }
                      }}
                    />
                  );
                }}
              />
            </Box>
            {watch("is_zone_restricted") === true ? (
              <Box>
                <InputController
                  control={control}
                  label="Notes"
                  name="zone_restriction_notes"
                  placeholder="Enter notes"
                  multiline
                  showErrorMessage
                />
              </Box>
            ) : null}
          </ContainerWithNotes>
        </FormRow>
        <FormRow>
          <InputContainer>
            <InputController
              control={control}
              label="Notes"
              name="notes"
              placeholder="Enter notes"
              multiline
              showErrorMessage
            />
          </InputContainer>
        </FormRow>
        <FormRow justifyContent={"center"}>
          <ButtonContainer>
            <LAButton
              text="Cancel"
              variant="secondary"
              onClick={onClose}
              fullWidth
              loading={isQueueLoading}
              disabled={isQueueLoading}
            />
          </ButtonContainer>
          <ButtonContainer>
            <LAButton
              text={"Save Changes"}
              fullWidth
              loading={isQueueLoading}
              type="submit"
              disabled={isQueueLoading}
            />
          </ButtonContainer>
        </FormRow>
      </StyledForm>
    </ModalDialog>
  );
};
