import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { popoverClasses } from "@mui/material/Popover";
import { Link } from "react-router-dom";
import { HEADER_HEIGHT } from "../../styles";

export const HeaderContainer = styled(
  ({ withBorder: __withBorder, ...restProps }) => <div {...restProps} />
)(({ theme, withBorder }) => ({
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  width: "100%",
  borderBottom: withBorder
    ? `1px solid ${theme.palette.COLORS.NEUTRAL3}`
    : "none",
  boxShadow: withBorder
    ? `0px 4px 8px 0px ${alpha(theme.palette.COLOwRS.NEUTRAL3, 0.13)}`
    : "none",
}));

export const HeaderInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  height: HEADER_HEIGHT,
  padding: theme.spacing(0, 8),
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(0, 6),
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0, 5),
  },
}));
export const HeaderContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));
export const HeaderLeftPart = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

const verticallyCenteredStyles = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

export const VerticallyCentered = styled(Box)(verticallyCenteredStyles);
export const VerticallyCenteredLink = styled(Link)(verticallyCenteredStyles);

export const Logo = styled("img")({
  height: 38,
});
export const MenuButton = styled("img")({
  height: 32,
});
export const ChevronIcon = styled("img")(({ theme }) => ({
  height: 20,
  width: 20,
  marginLeft: theme.spacing(1.5),
}));

export const RightSide = styled(Box)({
  marginLeft: "auto",
  display: "flex",
});
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  borderRadius: 0,
}));

export const StyledProfile = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  color: theme.palette.COLORS.NEUTRAL9,
  padding: theme.spacing(0, 1.5),
  "&:hover": {
    cursor: "pointer",
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  border: `1px solid ${theme.palette.COLORS.CYAN15}`,
  backgroundColor: theme.palette.COLORS.CYAN05,
  color: theme.palette.COLORS.NEUTRAL9,
  height: 48,
  width: 48,
  marginRight: theme.spacing(2),
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1.5),
  padding: theme.spacing(1.5, 2),
  borderTop: `1px solid ${theme.palette.COLORS.NEUTRAL15}`,
  "&:hover": {
    backgroundColor: theme.palette.COLORS.BLUE0,
  },
}));
export const StyledMainMenuItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1.25, 1.5),
  backgroundColor: theme.palette.COLORS.NEUTRAL05,
  "&:hover": {
    backgroundColor: theme.palette.COLORS.NEUTRAL05,
  },
}));
export const StyledIcon = styled("img")(({ theme }) => ({
  height: 16,
  width: 16,
}));
export const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  backgroundColor: theme.palette.COLORS.BLUE15,
  height: theme.spacing(4),
  width: theme.spacing(4),
}));
export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& ul": {
    padding: 0,
  },
  [`& .${popoverClasses.paper}`]: {
    padding: 0,
    boxShadow: "0px 4px 20px 0px #00000026",
    border: `1px solid ${theme.palette.COLORS.NEUTRAL15}`,
    top: "11px !important",
    borderRadius: theme.spacing(1.5),
  },
}));
export const CapitalizeText = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
}));
export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    boxShadow: "none",
  },
}));
