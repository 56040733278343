import { Box, Typography, styled } from "@mui/material";
import { ModalDialog } from "../../../components/ModalDialog/ModalDialog";
import { LAButton } from "../../../components/LAButton/LAButton";
import { ButtonModalContainer } from "../../QueuePageReport/NotesSection/NotesSection.styles";

export const DiscardDraftPopupWarning = ({ onClose, onDiscard, loading }) => {
  return (
    <ModalDialog title="Warning" onClose={onClose} width={545}>
      <Box mt={-0.5}>
        <Subtitle>
          Are you sure you want to discard this draft? All changes will be lost
          permanently.
        </Subtitle>
        <Box
          display={"flex"}
          gap={2.5}
          width={"100%"}
          justifyContent={"center"}
          mt={5}
          mb={8}
        >
          <ButtonModalContainer>
            <LAButton
              text="Cancel"
              variant="secondary"
              fullWidth
              onClick={onClose}
              loading={loading}
            />
          </ButtonModalContainer>
          <ButtonModalContainer>
            <LAButton
              text="Discard"
              fullWidth
              onClick={onDiscard}
              loading={loading}
            />
          </ButtonModalContainer>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export const Subtitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 5),
  textAlign: "center",
  ...theme.typography.body4,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.body8,
  },
}));
