export const QUEUE_PER_PAGE = 25;

export const defaultFilters = {
  single: false,
  batch: false,
  in_progress: false,
  received: false,
  date: ["", ""],
  companies: [],
};

export const queueInitialData = {
  queue: [],
  totalQueue: 0,
  sortingField: "created_at",
  sortingOrder: true,
  searchField: "",
  page: 1,
  pageCount: 1,
  filters: defaultFilters,
  queueDetails: null,
  queueToFinalise: null,
  idToOverride: null,
  isLoading: false,
};

export const emptyData = {};
