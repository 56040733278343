import { Box } from "@mui/material";
import { LAButton } from "../../../components/LAButton/LAButton";
import { ModalDialog } from "../../../components/ModalDialog/ModalDialog";
import { ButtonContainer, Subtitle } from "./DeleteEntityPopup.styles";

export const DeleteEntityPopup = ({ onClose, onDelete, isDraft }) => {
  return (
    <ModalDialog title="Warning" onClose={onClose} width={712}>
      <Box mt={-0.5}>
        <Subtitle>
          {isDraft
            ? "Any new queries will not be automatically matched with this entity information and the draft will be discarded. Are you sure you want to proceed?"
            : `Continuing will clear any in-progress queries and disable automatic
            matching with this entity information for new queries. Are you sure
            you want to proceed?`}
        </Subtitle>
        <Box
          display={"flex"}
          gap={2.5}
          width={"100%"}
          justifyContent={"center"}
          mt={5}
          mb={8}
        >
          <ButtonContainer>
            <LAButton
              text="Cancel"
              variant="secondary"
              fullWidth
              onClick={onClose}
            />
          </ButtonContainer>
          <ButtonContainer>
            <LAButton text="Proceed" fullWidth onClick={onDelete} />
          </ButtonContainer>
        </Box>
      </Box>
    </ModalDialog>
  );
};
