const selectOne = (set, get) => (checked, id) => {
  const { selectedQuerries } = get();
  if (checked) {
    set({
      selectedQuerries: selectedQuerries.concat(id),
    });
  } else {
    set({
      selectedQuerries: selectedQuerries.filter((item) => item !== id),
    });
  }
};

export default selectOne;
