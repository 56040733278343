import { changePassword } from "./actions/changePassword";
import { handleChangeMyQuerryFilter } from "./actions/handleChangeMyQuerryFilter";
import { loadStoreData } from "./actions/loadStoreData";
import { resetPasswordWithToken } from "./actions/resetPasswordWithToken";
import { sendEmail } from "./actions/sendEmail";
import { signin } from "./actions/signinAction";
import { signout } from "./actions/signout";
import { updateUser } from "./actions/updateUser";

export const createUserStoreActions = (set, get) => ({
  signin: ({ email, password }) => signin(email, password, set),
  signout: () => signout(set),
  updateUser: (refreshToken) => updateUser(refreshToken, set),
  loadStoreData: () => loadStoreData(set),
  sendEmailToResetPassword: (email) => sendEmail(email, set),
  resetPasswordWithToken: (data) => resetPasswordWithToken(data, set),
  changePassword: (passwords) => changePassword(passwords, set),
  setIsRedirected: () => {
    set({ isRedirected: true });
  },
  handleChangeMyQuerryFilter: handleChangeMyQuerryFilter(set),
});
