import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

export const resetPasswordWithToken = async (data, set) => {
  try {
    set({ isLoading: true });
    await api.post("/users/password_reset/confirm/", data);
    set({ isLoading: false });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};
