import PagesLayout from "../../Layouts/PagesLayout";
import homeIcon from "../../assets/icons/HomeIcon.svg";
import {
  ButtonContainer,
  Container,
  Content,
  StyledButton,
  StyledIcon,
  StyledText,
} from "./PageNotFound.styles";
import { useLocation, useNavigate } from "react-router-dom";

const errorhandlers = {
  403: <StyledText variant="h1">Access denied!</StyledText>,
  404: (
    <>
      <StyledText variant="h1">Sorry</StyledText>

      <StyledText variant="h1">This page could not be found</StyledText>
    </>
  ),
  500: (
    <>
      <StyledText variant="h1">Oops...</StyledText>
      <StyledText variant="h1">Something went wrong...</StyledText>
    </>
  ),
};

const PageNotFound = ({ error }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <PagesLayout>
      <Container>
        <Content>
          {errorhandlers?.[location.state?.error] || errorhandlers?.[error] || (
            <>
              <StyledText variant="h1">Oops...</StyledText>
              <StyledText variant="h1">Something went wrong...</StyledText>
            </>
          )}
          <ButtonContainer>
            <StyledButton
              text="Back To Home"
              icon={<Icon />}
              size="sm"
              onClick={() => navigate("/home")}
            />
          </ButtonContainer>
        </Content>
      </Container>
    </PagesLayout>
  );
};

export default PageNotFound;

const Icon = () => {
  return <StyledIcon src={homeIcon} />;
};
