import { Box } from "@mui/material";
import { ModalDialog } from "../../../../components/ModalDialog/ModalDialog";
import { Subtitle } from "../DiscardDraftPopupWarning";
import { ButtonContainer } from "../CreateEntityPopup.styles";
import { LAButton } from "../../../../components/LAButton/LAButton";

export const SaveDraftWarning = ({
  onClose,
  onProceed,
  loadingPercent,
  loading,
}) => {
  return (
    <ModalDialog
      title="Confirm Saving Entity Version as Draft"
      onClose={onClose}
      width={712}
    >
      <Box mt={-0.5}>
        <Subtitle>
          Are you sure you want to save this version of the entity as a draft?
          This action will preserve the changes made to the entity without
          applying them immediately. Please be aware that until published, these
          changes will not be visible to other users. Proceed with saving as
          draft?
        </Subtitle>
        <Box
          display={"flex"}
          gap={2.5}
          width={"100%"}
          justifyContent={"center"}
          mt={5}
          mb={8}
        >
          <ButtonContainer>
            <LAButton
              text="Cancel"
              variant="secondary"
              fullWidth
              onClick={onClose}
              loading={loading}
            />
          </ButtonContainer>
          <ButtonContainer>
            <LAButton
              text="Proceed"
              fullWidth
              onClick={onProceed}
              loadingPercent={loadingPercent}
              loading={loading}
            />
          </ButtonContainer>
        </Box>
      </Box>
    </ModalDialog>
  );
};
