import { Box, IconButton, Typography, styled } from "@mui/material";
import { COLORS } from "../../theme/colors";

const toasterBackground = {
  error: COLORS.RED05,
  info: COLORS.BLUE0,
};
const toasterText = {
  error: COLORS.RED9,
  info: COLORS.BLUE9,
};
const toasterBorder = {
  error: COLORS.RED5,
  info: COLORS.BLUE5,
};

export const ToastContainer = styled(({ type: _type, ...props }) => (
  <Box {...props} />
))(({ theme, type }) => ({
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2.5, 3),
  borderRadius: theme.spacing(1.5),
  border: `1px solid ${toasterBorder[type]}`,
  backgroundColor: toasterBackground[type],
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2, 2.5),
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  marginLeft: theme.spacing(4.3),
}));
export const IconImg = styled("img")(({ theme }) => ({
  width: 20,
  height: 20,
  marginRight: theme.spacing(2),
}));

export const StyledTypography = styled(({ type: _type, ...props }) => (
  <Typography {...props} />
))(({ theme, type }) => ({
  color: toasterText[type],
  [theme.breakpoints.down("md")]: {
    ...theme.typography.body6,
  },
}));
