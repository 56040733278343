import { COLORS } from "./colors";

const FONT_SIZES = /** @type {const} */ ({
  h1: 40,
  h2: 32,
  h3: 28,
  subtitle1: 22,
  subtitle2: 20,
  subtitle4: 18,
  body1: 16,
  body2: 14,
  small: 12,
});
const bodyStyle1 = {
  fontSize: FONT_SIZES.body1,
  lineHeight: "24px",
};
const bodyStyle2 = {
  fontSize: FONT_SIZES.body2,
  lineHeight: "21px",
};

const typography = {
  fontFamily: ["Inter"],
  color: COLORS.NEUTRAL9,
  fontWeightBold: 700,
  h1: {
    fontFamily: ["DM Sans"],
    fontSize: FONT_SIZES.h1,
    fontWeight: 700,
    lineHeight: "48px",
  },
  h2: {
    fontFamily: ["DM Sans"],
    fontSize: FONT_SIZES.h2,
    fontWeight: 700,
    lineHeight: "42px",
  },
  h3: {
    fontFamily: ["DM Sans"],
    fontSize: FONT_SIZES.h3,
    fontWeight: 700,
    lineHeight: "36px",
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: FONT_SIZES.subtitle1,
    lineHeight: "30px",
  },
  subtitle2: {
    fontWeight: 700,
    fontSize: FONT_SIZES.subtitle2,
    lineHeight: "30px",
  },
  subtitle3: {
    fontWeight: 600,
    fontSize: FONT_SIZES.subtitle2,
    lineHeight: "30px",
  },
  subtitle4: {
    fontWeight: 600,
    fontSize: FONT_SIZES.subtitle4,
    lineHeight: "27px",
  },
  body1: {
    fontWeight: 700,
    ...bodyStyle1,
  },
  body2: {
    fontWeight: 600,
    ...bodyStyle1,
  },
  body3: {
    fontWeight: 500,
    ...bodyStyle1,
  },
  body4: {
    fontWeight: 400,
    ...bodyStyle1,
  },
  body5: {
    fontWeight: 700,
    ...bodyStyle2,
  },
  body6: {
    fontWeight: 600,
    ...bodyStyle2,
  },
  body7: {
    fontWeight: 500,
    ...bodyStyle2,
  },
  body8: {
    fontWeight: 400,
    ...bodyStyle2,
  },
  small1: {
    fontSize: FONT_SIZES.small,
    fontWeight: 400,
    lineHeight: "18px",
  },
  small2: {
    fontSize: FONT_SIZES.small,
    fontWeight: 400,
    lineHeight: "15.5px",
  },
};

export default typography;
