const BLUE_PALETTE = /** @type {const} */ ({
  BLUE0: "#EDF3FF",
  BLUE05: "#DFEBFF",
  BLUE1: "#D1E1FF",
  BLUE15: "#C2D8FF",
  BLUE2: "#B3CEFF",
  BLUE3: "#90B3FF",
  BLUE4: "#709AF7",
  BLUE5: "#5181EF",
  BLUE6: "#3469E7",
  BLUE7: "#1B4BB3",
  BLUE8: "#0A3180",
  BLUE9: "#001B4D",
});

const GREEN_PALETTE = /** @type {const} */ ({
  GREEN0: "#EDFFF6",
  GREEN05: "#DAFAE9",
  GREEN1: "#C7F5DD",
  GREEN15: "#B4EFD1",
  GREEN2: "#A3EAC5",
  GREEN3: "#80DBAC",
  GREEN4: "#5FC390",
  GREEN5: "#43AB75",
  GREEN6: "#2B945E",
  GREEN7: "#187C48",
  GREEN8: "#0A6436",
  GREEN9: "#004D25",
});

const RED_PALETTE = /** @type {const} */ ({
  RED0: "#FFEDEF",
  RED05: "#FCD8DC",
  RED1: "#FAC3C9",
  RED15: "#F7AFB6",
  RED2: "#F49BA4",
  RED3: "#E87681",
  RED4: "#DB5461",
  RED5: "#BF3A47",
  RED6: "#A22531",
  RED7: "#861420",
  RED8: "#690812",
  RED9: "#4D0008",
});

const YELLOW_PALETTE = /** @type {const} */ ({
  YELLOW0: "#FFFBED",
  YELLOW05: "#FFF8D6",
  YELLOW1: "#FFF5BE",
  YELLOW15: "#FFF2A7",
  YELLOW2: "#FFEE8E",
  YELLOW3: "#FFDF64",
  YELLOW4: "#E1C24A",
  YELLOW5: "#C4A633",
  YELLOW6: "#A68B21",
  YELLOW7: "#887012",
  YELLOW8: "#6A5607",
  YELLOW9: "#4D3D00",
});

const CYAN_PALETTE = /** @type {const} */ ({
  CYAN0: "#E8FAFF",
  CYAN05: "#CDF3FD",
  CYAN1: "#B2ECFC",
  CYAN15: "#98E5FA",
  CYAN2: "#7EDEF9",
  CYAN3: "#54CCED",
  CYAN4: "#3EB1D1",
  CYAN5: "#2B97B5",
  CYAN6: "#1B7E99",
  CYAN7: "#0F657D",
  CYAN8: "#064D61",
  CYAN9: "#003645",
});

const NEUTRAL_PALETTE = /** @type {const} */ ({
  NEUTRAL0: "#FFFFFF",
  NEUTRAL05: "#F1F2F4",
  NEUTRAL1: "#E3E5E9",
  NEUTRAL15: "#D6D9DE",
  NEUTRAL2: "#C8CCD3",
  NEUTRAL3: "#AFB4BD",
  NEUTRAL4: "#969CA7",
  NEUTRAL5: "#7F8591",
  NEUTRAL6: "#686F7A",
  NEUTRAL7: "#535964",
  NEUTRAL8: "#3F444E",
  NEUTRAL9: "#2C3038",
});

const DATA_PALETTE = /** @type {const} */ ({
  DATA0: "#DEFFF6",
  DATA05: "#D2F9F0",
  DATA1: "#C6F4EB",
  DATA1_5: "#BBEEE6",
  DATA2: "#B0E9E2",
  DATA3: "#9ADEDC",
  DATA4: "#86CFD3",
  DATA5: "#74BCC8",
  DATA6: "#63A9BD",
  DATA7: "#5294B2",
  DATA8: "#4480A7",
  DATA9: "#366B9C",
  DATA10: "#2A5791",
  DATA11: "#1F4385",
  DATA12: "#15317A",
  DATA13: "#0D1F6F",
  DATA14: "#060F64",
  DATA15: "#000159",
});

export const COLORS = /** @type {const} */ ({
  ...BLUE_PALETTE,
  ...GREEN_PALETTE,
  ...RED_PALETTE,
  ...YELLOW_PALETTE,
  ...CYAN_PALETTE,
  ...NEUTRAL_PALETTE,
  ...DATA_PALETTE,
});
