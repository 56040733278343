import React from "react";

export const PaginationArrowLeft = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.70711 14.7071C7.31658 15.0976 6.68342 15.0976 6.2929 14.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L6.29289 5.29289C6.68342 4.90237 7.31658 4.90237 7.70711 5.29289C8.09763 5.68342 8.09763 6.31658 7.70711 6.70711L5.41421 9L17 9C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L7.70711 13.2929C8.09763 13.6834 8.09763 14.3166 7.70711 14.7071Z"
        fill="#686F7A"
      />
    </svg>
  );
};

export const PaginationArrowRight = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2929 5.79289C12.6834 5.40237 13.3166 5.40237 13.7071 5.79289L17.7071 9.79289C18.0976 10.1834 18.0976 10.8166 17.7071 11.2071L13.7071 15.2071C13.3166 15.5976 12.6834 15.5976 12.2929 15.2071C11.9024 14.8166 11.9024 14.1834 12.2929 13.7929L14.5858 11.5H3C2.44772 11.5 2 11.0523 2 10.5C2 9.94772 2.44772 9.5 3 9.5H14.5858L12.2929 7.20711C11.9024 6.81658 11.9024 6.18342 12.2929 5.79289Z"
        fill="#686F7A"
      />
    </svg>
  );
};
