import { Box, styled } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { LAButton } from "../LAButton/LAButton";
import { ReportTemplate } from "../../templates/ReportTemplate/ReportTemplate";
import { COLORS } from "../../theme/colors";

export const PdfGenerator = ({ disabled, reportTMP }) => {
  return (
    <ButtonContiner>
      {disabled ? (
        <LAButton fullWidth disabled={disabled} text={"Save as PDF"} />
      ) : (
        <PDFLinkStyled
          document={<ReportTemplate reportTMP={reportTMP} />}
          fileName={`report_(${reportTMP.id}).pdf`}
          style={{ color: disabled ? COLORS.NEUTRAL4 : COLORS.NEUTRAL0 }}
        >
          {({ blob, url, loading, error }) => {
            return (
              <LAButton
                fullWidth
                disabled={disabled || loading}
                text={loading ? "Loading Report" : "Save as PDF"}
              />
            );
          }}
        </PDFLinkStyled>
      )}
    </ButtonContiner>
  );
};

const ButtonContiner = styled(Box)(({ theme }) => ({
  width: 159,
}));
export const PDFLinkStyled = styled(PDFDownloadLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.COLORS.NEUTRAL0,
  ...theme.typography.body2,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.body5,
  },
}));
