import { createWithEqualityFn } from "zustand/traditional";
import { responseInitialData } from "./state";
import { createResponseStoreActions } from "./actions";

const useResponseStore = createWithEqualityFn((set, get) => ({
  ...responseInitialData,
  ...createResponseStoreActions(set, get),
}));

export default useResponseStore;
