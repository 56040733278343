import {
  Box,
  Divider,
  FormControlLabel,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import { customScroll } from "../../../styles";

export const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  padding: theme.spacing(0, 6.5, 8, 7),
  textAlign: "center",
  maxHeight: 562,
  overflowY: "scroll",
  color: theme.palette.COLORS.NEUTRAL9,
  [theme.breakpoints.down("md")]: {
    maxHeight: 462,
  },
  ...customScroll,
}));

export const FormRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  gap: theme.spacing(2.5),
}));
export const InputContainer = styled(Box)(({ theme }) => ({
  width: "100%",
}));
export const RadioGroupContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));
export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: 146,
}));

export const LabelContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  lineHeight: "21px",
  marginBottom: theme.spacing(1),
  alignItems: "center",
  gap: theme.spacing(1),
}));
export const DragDropContainer = styled(Box)(({ theme, disabled }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  textAlign: "left",
  gap: theme.spacing(1),
  color: theme.palette.COLORS.NEUTRAL7,
  pointerEvents: disabled ? "none" : "auto",
  filter: disabled ? "opacity(40%)" : "none",
}));
export const UploadFileTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
export const InputLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL7,
}));
export const ComingSoon = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.DATA8,
}));
export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
}));
export const RadioLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  gap: theme.spacing(1),
  "& .MuiTypography-root": {
    ...theme.typography.body7,
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL4,
  "&::before, &::after": {
    borderTopColor: theme.palette.COLORS.NEUTRAL4,
  },
  "&>span": {
    padding: theme.spacing(0, 3),
  },
}));

export const StyledError = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.RED5,
  textAlign: "left",
  margin: 0,
  position: "absolute",
  top: 29,
  left: 0,
}));
