import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const getResponseDetails = (set) => async (id) => {
  try {
    set({ isLoading: true });

    const response = await api.get(`/responses/${id}/`);
    const result = response.data;
    set({
      responseDetails: result,
      responseId: id,
      isLoading: false,
    });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default getResponseDetails;
