import changePage from "../query/actions/changePage";
import onSearchChange from "../query/actions/onSearchChange";
import { updateSorting } from "../query/actions/updateSorting";
import createNote from "../queue/actions/createNote";
import deleteNote from "../queue/actions/deleteNote";
import updateNote from "../queue/actions/updateNote";
import getList from "./actions/GetList";
import { resetPageProps } from "./actions/ResetPageProps";
import getResponseDetails from "./actions/getResponseDetails";
import submitResponse from "./actions/submitResponse";
import {
  getStateFromLocalStore,
  updateCurrentFiltersInLocalStore,
} from "./actions/updateFiltersFromLocalStore";

export const createResponseStoreActions = (set, get) => ({
  updateSorting: (field) => updateSorting(field, set, get),
  changePage: changePage(set),
  getList: getList(set, get),
  onSearchChange: (value) => {
    onSearchChange(set)(value);
    getList(set, get)(false);
  },
  getDetails: getResponseDetails(set),
  createNote: createNote(set),
  deleteNote: deleteNote(set),
  updateNote: updateNote(set),
  submitResponse: submitResponse(set),
  resetPageProps: resetPageProps(set),
  updateCurrentFiltersInLocalStore: updateCurrentFiltersInLocalStore(get),
  getStateFromLocalStore: getStateFromLocalStore(set),
});
