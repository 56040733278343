import { Box, Typography, styled } from "@mui/material";

export const ReportInfo = styled(Typography)(({ theme }) => ({
  width: "100%",
  ...theme.typography.small1,
  fontWeight: 500,
  color: theme.palette.COLORS.NEUTRAL5,
}));
export const Main = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(4),
  gap: theme.spacing(4),
  background: theme.palette.COLORS.NEUTRAL0,
  border: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  borderRadius: "7px",
}));
export const InformationBlock = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));
export const InfoRow = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: theme.spacing(2.5),
}));
export const InfoCard = styled(Box)(({ theme, ...params }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.75),
  width: "100%",
  backgroundColor: params.color || theme.palette.COLORS.NEUTRAL05,
  borderRadius: "7px",
  color: theme.palette.COLORS.NEUTRAL7,
  border: `1px solid ${theme.palette.COLORS.NEUTRAL1}`,
  padding: theme.spacing(1.75),
}));
export const PermitTable = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  border: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  borderRadius: "4px 4px 0 0",
}));
export const NotesTable = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  border: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  borderRadius: theme.spacing(0.5),
}));
export const STRTable = styled(PermitTable)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  borderTop: `none`,
  borderRadius: "0 0 4px 4px",
}));
export const LabelColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minWidth: 370,
  "& > div": {
    borderTopLeftRadius: theme.spacing(0.5),
  },
  [theme.breakpoints.down("lg")]: {
    minWidth: 380,
  },
  [theme.breakpoints.down("md")]: {
    minWidth: 320,
  },
}));
export const InfoColumns = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
}));
export const Column = styled(Box)(({ theme, ...props }) => ({
  display: "flex",
  flexDirection: "column",
  width: "25%",
  minWidth: "25%",
  borderLeft: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  position: "relative",
  "&::after": {
    content: props.is_failed ? "''" : "none",
    position: "absolute",
    opacity: 0.5,
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    pointerEvents: "none",
    border: props.is_failed ? `1px solid ${theme.palette.COLORS.RED6}` : "none",
  },
  "& div": {
    backgroundColor: props.is_failed && theme.palette.COLORS.RED05,
    "&:fist-of-type": {
      backgroundColor: theme.palette.COLORS.RED6,
    },
  },
  "&:nth-child(5)": {
    display: "none",
    borderRight: `none`,
  },
  "&:nth-last-of-type(1)": {
    borderRight: `none`,
    width: "100%",
    "& > div": {
      color: "transparent",
      borderTopRightRadius: theme.spacing(0.5),
      "& > p": {
        visibility: "hidden",
      },
    },
  },
}));
export const LastColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",

  borderRight: `none`,
}));
export const ColumnHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1.5, 2.5),
  backgroundColor: theme.palette.COLORS.NEUTRAL05,
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
}));
export const NotesColumnHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1.5, 2.5),
  backgroundColor: theme.palette.COLORS.NEUTRAL05,
  borderRight: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  "&:first-of-type": {
    borderTopLeftRadius: theme.spacing(0.5),
    width: 160,
    minWidth: 160,
  },
  "&:last-of-type": {
    borderRight: `none`,
    borderTopRightRadius: theme.spacing(0.5),
  },
}));
export const HeaderRow = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1.5, 2.5),
  backgroundColor: theme.palette.COLORS.BLUE0,
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
}));
export const NotesHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
}));
export const NotesRow = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  "&:last-of-type": {
    borderBottom: `none`,
    "& div:first-child": {
      borderBottomLeftRadius: theme.spacing(0.5),
    },
    "& div:last-child": {
      borderBottomRightRadius: theme.spacing(0.5),
    },
  },
}));
export const NotesRowCell = styled(NotesColumnHeader)(({ theme }) => ({
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  "&:first-of-type": {
    borderBottom: `none`,
  },
}));
export const TableBody = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
}));
export const ColumnCell = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  color: theme.palette.COLORS.NEUTRAL9,
  padding: theme.spacing(1.5, 2.5),
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  "&:last-of-type": {
    borderBottom: `none`,
  },
}));
