import { Dialog, IconButton, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { transparencyToHex } from "../../styles";

export const RegularDialogStyled = styled(({ width: _width, ...props }) => (
  <Dialog {...props} />
))(({ theme, width, multiline, isLoading }) => ({
  "& .MuiPaper-root.MuiDialog-paper": {
    width: width || 700,
    padding: theme.spacing(4, 1, 0),
    borderRadius: theme.spacing(2),
    margin: 0,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    position: "relative",
    pointerEvents: isLoading ? "none" : "auto",
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.25),
  borderRadius: 0,
  height: 32,
  width: 32,
  marginLeft: "auto",
  "&:hover": {
    background: "none",
  },
}));

export const XButton = styled("img")({
  height: "100%",
  width: "100%",
});
export const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(0, 3),
}));
export const AdditionalHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "absolute",
  left: "50%",
  top: 16,
  transform: "translate(-50%,0)",
}));
export const AdditionalHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL5,
  fontStyle: "italic",
  textAlign: "center",
  ...theme.typography.small2,
}));
export const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
  textAlign: "center",
  padding: theme.spacing(0, 4),
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h3,
  },
}));
export const Subheader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
}));

export const StyledLoading = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "absolute",
  backgroundColor: `${theme.palette.COLORS.NEUTRAL05}${transparencyToHex(0.6)}`,
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  justifyContent: "center",
  alignItems: "center",
}));
