import {
  Box,
  FormControlLabel,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import { customScroll } from "../../../styles";

export const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  padding: "0 53px 64px",
  textAlign: "center",
  maxHeight: 562,
  overflow: "scroll",
  color: theme.palette.COLORS.NEUTRAL9,
  ...customScroll,
  [theme.breakpoints.down("md")]: {
    maxHeight: 462,
  },
}));

export const FormRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "start",
  width: "100%",
  gap: theme.spacing(2.5),
}));
export const InputContainer = styled(Box)(({ theme, ...props }) => ({
  width: "100%",
  filter: props.disabled ? "opacity(40%)" : "none",
}));
export const ContainerWithNotes = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
}));
export const RadioGroupContainer = styled(Box)(({ theme, disabled }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  pointerEvents: disabled ? "none" : "auto",
  filter: disabled ? "opacity(40%)" : "none",
}));
export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: 170,
}));

export const LabelContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  lineHeight: "21px",
  marginBottom: theme.spacing(1),
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const InputLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL7,
}));

export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
}));
export const RadioLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  gap: theme.spacing(1),
  "& .MuiTypography-root": {
    ...theme.typography.body7,
  },
}));

export const StrLaws = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL9,
  ...theme.typography.body2,
  textAlign: "left",
  marginBottom: theme.spacing(-1.5),
}));

export const DiscardDraftButton = styled(Typography)(({ theme, ...props }) => ({
  color: theme.palette.COLORS.RED6,
  ...theme.typography.body6,
  textDecoration: "underline",
}));
export const WarningDraftText = styled(Typography)(({ theme, ...props }) => ({
  color: theme.palette.COLORS.RED6,
  ...theme.typography.body7,
}));

export const DiscardDraft = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(0.75),
  position: "absolute",
  top: 74,
  right: 70,
  cursor: "pointer",
}));
export const WarningDraft = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(0.75),
  justifyContent: "center",
}));
