import { COLORS } from "../../../theme/colors";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { ColumnCell, ColumnNoteCell } from "./ColumnCell";
import { jurisdictions, notes } from "../../../mock/ReportData";

const footerText = `The use of this report is only permitted by active customers of ADPG LLC (dba Property Guard), and is subject to the Terms of Service agreed to by such customer. ADPG LLC disclaims any responsibility for use of this report by individuals who have not agreed to its Terms of Service`;
const styles = StyleSheet.create({
  Container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  NotesRowHeader: {
    fontFamily: "Inter",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    border: `1px solid ${COLORS.NEUTRAL2}`,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  StrInfoTable: {
    fontFamily: "Inter",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    border: `1px solid ${COLORS.NEUTRAL2}`,
    borderTop: "none",
  },
  LabelColumn: {
    display: "flex",
    flexDirection: "column",
    minWidth: "55mm",
    "& > div": {
      borderTopLeftRadius: "4px",
    },
  },
  ColumnHeader: {
    width: "100%",
    padding: "3mm 4mm",
    backgroundColor: COLORS.NEUTRAL05,
  },
  TypographyBody5: {
    fontSize: 7,
    fontWeight: 600,
    color: COLORS.NEUTRAL7,
    textAlign: "left",
  },
  InfoColumns: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    position: "relative",
  },
  Column: {
    display: "flex",
    flexDirection: "column",
    width: "25%",
    borderLeft: `1px solid ${COLORS.NEUTRAL2}`,
    position: "relative",
  },
  TypographyHeaderTitle: {
    fontSize: 7,
    fontWeight: 600,
    color: COLORS.NEUTRAL9,
    textAlign: "left",
  },
  TypographyHeaderText: {
    fontSize: 7,
    fontWeight: 500,
    color: COLORS.NEUTRAL6,
    textAlign: "left",
  },
  StrTable: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  ColumnRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderLeft: `1px solid ${COLORS.NEUTRAL2}`,
    borderRight: `1px solid ${COLORS.NEUTRAL2}`,
  },
  Footer: {
    padding: "3mm 35mm",
    width: "100%",
  },
  FooterText: {
    fontSize: 5,
    color: COLORS.NEUTRAL5,
    textAlign: "center",
  },
});

export const NotesTable = ({ reportTMP, isNotesExist, permitNotes }) => {
  if (isNotesExist === 0) {
    return null;
  }
  const reportsWithNotes = reportTMP.filter(([key, value]) => {
    const allNotes = notes.reduce((acc, n) => {
      if (!value[n]) return acc;
      else {
        return acc + 1;
      }
    }, 0);
    return allNotes > 0 || permitNotes[key];
  });

  return (
    <>
      <View style={styles.Container}>
        {reportsWithNotes.length === 1 ? (
          <View wrap={false}>
            <NotesHeader />
            <NotesRow
              item={reportsWithNotes[0]}
              i={0}
              arr={reportsWithNotes}
              permitNotes={permitNotes}
            />
            <View style={styles.Footer}>
              <Text style={styles.FooterText}>{footerText}</Text>
            </View>
          </View>
        ) : (
          <>
            <View wrap={false}>
              <NotesHeader />
              <NotesRow
                item={reportsWithNotes[0]}
                i={0}
                arr={reportsWithNotes}
                permitNotes={permitNotes}
              />
            </View>
            {reportsWithNotes.map((item, i, arr) => {
              if (i > 0 && i < arr.length - 1) {
                return (
                  <NotesRow
                    key={item[0]}
                    item={item}
                    i={i}
                    arr={arr}
                    permitNotes={permitNotes}
                  />
                );
              }
              return null;
            })}
            <View wrap={false}>
              <NotesRow
                item={reportsWithNotes.at(-1)}
                i={reportsWithNotes.length - 1}
                arr={reportsWithNotes}
                permitNotes={permitNotes}
              />
              <View style={styles.Footer}>
                <Text style={styles.FooterText}>{footerText}</Text>
              </View>
            </View>
          </>
        )}
      </View>
    </>
  );
};

export const NotesHeader = () => {
  return (
    <View
      style={styles.NotesRowHeader}
      render={(props) => {
        return (
          <>
            <View
              style={{
                ...styles.ColumnHeader,
                width: "16%",
                borderTopLeftRadius: 3,
              }}
            >
              <Text style={{ ...styles.TypographyBody5, visibility: "hidden" }}>
                {" "}
              </Text>
            </View>
            <View
              style={{
                ...styles.ColumnHeader,
                width: "42%",
                borderLeft: `1px solid ${COLORS.NEUTRAL2}`,
              }}
            >
              <Text style={styles.TypographyBody5}>Entity Notes</Text>
            </View>
            <View
              style={{
                ...styles.ColumnHeader,
                width: "42%",
                borderTopRightRadius: 3,
                borderLeft: `1px solid ${COLORS.NEUTRAL2}`,
              }}
            >
              <Text style={styles.TypographyBody5}>Permit Notes</Text>
            </View>
          </>
        );
      }}
    ></View>
  );
};

const NotesRow = ({ item, i, arr, permitNotes }) => {
  return (
    <View
      style={{
        ...styles.ColumnRow,
        borderBottomRightRadius: arr.length - 1 === i ? 3 : 0,
        borderBottomLeftRadius: arr.length - 1 === i ? 3 : 0,
      }}
      key={jurisdictions[item[0]]}
      wrap={false}
    >
      <ColumnCell
        text={jurisdictions[item[0]]}
        bold
        sx={{
          width: "16%",
          borderBottomLeftRadius: arr.length - 1 === i ? 3 : 0,
        }}
      />
      <ColumnNoteCell
        item={item}
        sx={{ width: "42%", borderLeft: `1px solid ${COLORS.NEUTRAL2}` }}
        textSx={{ fontSize: 6 }}
      />
      <ColumnCell
        text={permitNotes[item[0]] || "none"}
        empty={!permitNotes[item[0]]}
        sx={{
          width: "42%",
          borderLeft: `1px solid ${COLORS.NEUTRAL2}`,
          borderBottomRightRadius: arr.length - 1 === i ? 3 : 0,
        }}
        textSx={{ fontWeight: 400, fontSize: 6 }}
      />
    </View>
  );
};
