import { Table } from "@mui/material";
import { NoDataFoundRow } from "../../pages/HomePage/NoDataFound/NoDataFound";
import { TablePagination } from "../Pagination/Pagination";
import { LATableHead } from "./TableHead/TableHead";
import { shallow } from "zustand/shallow";

export const LATable = ({ itemsCount, store, tableHeaders, children }) => {
  const [pageCount] = store((state) => [state.pageCount], shallow);
  return (
    <>
      <Table
        sx={{
          minWidth: 650,
          borderCollapse: "separate",
          borderSpacing: "0 6px",
        }}
        aria-label="simple table"
      >
        <LATableHead store={store} headerCells={tableHeaders} />
        {children}
      </Table>
      {!itemsCount ? (
        <NoDataFoundRow />
      ) : pageCount > 1 ? (
        <TablePagination store={store} />
      ) : null}
    </>
  );
};
