import { shallow } from "zustand/shallow";
import { SearchInput } from "../SearchInput/SearchInput";
import { useDebounceValue } from "../../hooks/useDebouncedValue";
import { useEffect, useState } from "react";

export const SearchComponent = ({ store }) => {
  const [onSearchChange, searchField] = store(
    (state) => [state.onSearchChange, state.searchField],
    shallow
  );

  const [localSearch, setLocalSearch] = useState(searchField);

  const debouncedSearch = useDebounceValue(localSearch);
  useEffect(() => {
    const isFromReport = JSON.parse(localStorage.getItem("prevIsReport"));
    if (!isFromReport) {
      onSearchChange(debouncedSearch);
    }
  }, [debouncedSearch, onSearchChange]);
  return (
    <SearchInput
      value={localSearch}
      onChange={(props) => {
        setLocalSearch(props);
      }}
    />
  );
};
