import { Box, Typography, styled } from "@mui/material";
import { LAButton } from "../../components/LAButton/LAButton";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const Content = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  padding: theme.spacing(10, 0),
  width: 632,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    width: 600,
    padding: theme.spacing(6, 0),
  },
  [theme.breakpoints.down("md")]: {
    width: 506,
    padding: theme.spacing(6, 0),
  },
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
  lineHeight: "60px",
  whiteSpace: "nowrap",
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h2,
    lineHeight: "48px",
  },
}));
export const StyledButton = styled(LAButton)(() => ({}));
export const ButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
}));

export const StyledIcon = styled("img")(() => ({}));
