export const COMPLIANCE_PER_PAGE = 25;

export const entityInitialData = {
  entities: [],
  optionsToShowCity: {
    search: null,
    options: [],
    currentPage: 1,
    nextPage: 2,
  },
  optionsToShowHOA: {
    search: null,
    options: [],
    currentPage: 1,
    nextPage: 2,
  },
  optionsToShowState: {
    search: null,
    options: [],
    currentPage: 1,
    nextPage: 2,
  },
  optionsToShowCounty: {
    search: null,
    options: [],
    currentPage: 1,
    nextPage: 2,
  },
  rows: [],
  entityToView: null,
  entityId: null,
  currentVersionId: null,
  totalEntities: 0,
  sortingField: "name",
  sortingOrder: false,
  searchField: "",
  page: 1,
  pageCount: 1,
  isLoading: false,
  imageLoadingPercent: null,
  complianceVersions: [],
  draftId: null,
};

export const emptyData = {};
