import { Box, styled } from "@mui/material";

export const SignInContent = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  padding: theme.spacing(8, 0),
  width: 700,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    width: 608,
    padding: theme.spacing(6.5, 0),
  },
}));
