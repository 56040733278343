import dayjs from "dayjs";
import api from "../../../services/api";
import { QUERY_PER_PAGE, defaultFilters } from "../state";
import { errorFormater } from "../../../services/errorHandler";

const getList = (set, get) => async (isFiltersFromLocalStore) => {
  const prevIsReport = JSON.parse(localStorage.getItem("prevIsReport"));

  const { page, sortingField, sortingOrder, searchField, filters } =
    isFiltersFromLocalStore && prevIsReport
      ? JSON.parse(localStorage.getItem("query-filters"))
      : get();

  const sorting =
    sortingField === "default"
      ? ""
      : `${sortingOrder ? "-" : ""}${sortingField}`;
  try {
    set({ isLoading: true });

    const is_single = filters.single && !filters.batch;
    const is_batch = !filters.single && filters.batch;

    const is_completed = filters.completed && !filters.in_progress;
    const is_in_progress = !filters.completed && filters.in_progress;

    const response = await api.get(`/home/`, {
      params: {
        page,
        order_by: sorting,
        is_single: is_single || is_batch ? is_single : null,
        is_completed: is_completed || is_in_progress ? is_completed : null,
        q: searchField,
        created_at_after: filters.date[0],
        created_at_before: filters.date[1]
          ? new Date(dayjs(filters.date[1]).add(1, "day"))
          : filters.date[1],
      },
    });

    prevIsReport &&
      set({
        page,
        sortingField,
        sortingOrder,
        searchField,
        filters,
      });
    localStorage.setItem("prevIsReport", JSON.stringify(false));
    localStorage.setItem(
      "query-filters",
      JSON.stringify({
        page: 1,
        sortingField: "created_at",
        sortingOrder: true,
        searchField: "",
        filters: defaultFilters,
      })
    );

    if (
      filters.single === false &&
      filters.batch === false &&
      filters.in_progress === false &&
      filters.completed === false &&
      filters.date[0] === "" &&
      filters.date[1] === "" &&
      searchField === ""
    ) {
      set({
        isQuerryExistedLength: response.data?.count,
      });
    }
    const { count: totalQueries, results } = response.data;

    set({
      totalQueries,
      pageCount: Math.ceil(totalQueries / QUERY_PER_PAGE),
      completedQuerries: results.filter((item) => item?.is_completed),
      queries: results,
      isLoading: false,
    });
  } catch (err) {
    set({ isLoading: false });
    throw errorFormater(err.response);
  }
};

export default getList;
