import {
  Box,
  Grid,
  InputBase,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Controller } from "react-hook-form";
import tooltipIcon from "../../assets/icons/BlackInfoIcon.svg";
import { smallCustomScroll } from "../../styles";
export const InputController = ({
  control,
  label = "",
  labelIcon,
  multiline = false,
  name,
  placeholder = "",
  prefix = "",
  rules = { required: false },
  type = "text",
  maxLength,
  inputComponent = null,
  inputProps = null,
  inputRef,
  isLandingPage,
  disabled = false,
  showErrorMessage,
  errorMessage,
  tooltip,
  ...props
}) => {
  return (
    <>
      {label ? (
        <LabelContainer>
          <InputLabel variant="body7" htmlFor={name}>
            {label}
          </InputLabel>
          {labelIcon && <img src={labelIcon} alt="icon" />}
          {tooltip ? (
            <StyledTooltip
              placement="bottom-start"
              title={
                <Typography variant="body8" paragraph>
                  {tooltip}
                </Typography>
              }
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [-4, -6],
                      },
                    },
                  ],
                },
              }}
            >
              <TooltipIcon src={tooltipIcon} />
            </StyledTooltip>
          ) : null}
        </LabelContainer>
      ) : null}

      <Grid item xs={12} position={"relative"}>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Box width="100%">
              <StyledInput
                id={name}
                multiline={multiline}
                rows={3}
                maxLength={maxLength || null}
                {...field}
                inputRef={ref}
                startAdornment={
                  prefix ? (
                    <span style={{ marginRight: 2 }}>{prefix}</span>
                  ) : null
                }
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                error={!!error}
                {...(inputComponent && { inputComponent })}
                {...(inputProps && { inputProps })}
                {...(inputRef && { inputRef })}
                {...props}
              />
              {showErrorMessage && (
                <StyledError variant="small1" multiline={multiline}>
                  {error?.message}
                </StyledError>
              )}
            </Box>
          )}
        />
      </Grid>
    </>
  );
};

export const StyledInput = styled(({ error: _error, ...props }) => (
  <InputBase {...props} />
))(({ theme, error, multiline }) => ({
  height: multiline ? 91 : 44,
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.COLORS.NEUTRAL05,
  ...theme.typography.body3,
  overflow: "hidden",
  padding: theme.spacing(1.25, 2),
  textAlign: "left",
  width: "100%",
  border: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  ...(error && { border: `1px solid ${theme.palette.COLORS.RED5}` }),
  "& > textarea": {
    ...smallCustomScroll,
    "&.Mui-disabled::placeholder": {
      color: theme.palette.COLORS.NEUTRAL9,
      opacity: 1,
    },
  },
  "& > input": {
    backgroundColor: theme.palette.COLORS.NEUTRAL05,
    "&::placeholder": {
      color: theme.palette.COLORS.NEUTRAL5,
    },
    "&:-webkit-autofill, input:-webkit-autofill:focus": {
      "-webkit-box-shadow": `0 0 0 1000px ${theme.palette.COLORS.NEUTRAL05} inset`,
    },
  },
  "&.Mui-focused": {
    border: `1px solid ${
      error ? theme.palette.COLORS.RED5 : theme.palette.COLORS.BLUE6
    }`,
  },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.COLORS.NEUTRAL05,
  },
}));

const LabelContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  lineHeight: "21px",
  marginBottom: theme.spacing(1),
  alignItems: "center",
  gap: theme.spacing(1),
}));
const InputLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL7,
}));

const StyledError = styled(({ multiline: _multiline, ...props }) => (
  <Typography {...props} />
))(({ theme, multiline }) => ({
  color: theme.palette.COLORS.RED5,
  position: "absolute",
  top: multiline ? 97 : 52,
  left: 0,
  textAlign: "left",
}));
const TooltipIcon = styled("img")(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
  },
}));

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);
const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  color: theme.palette.COLORS.NEUTRAL7,
  border: `1px solid ${theme.palette.COLORS.NEUTRAL4}`,
  maxWidth: 500,
  borderRadius: theme.spacing(0.5),
  textAlign: "21px",
  "& p": {
    margin: 0,
  },
  padding: theme.spacing(1, 1.5),
  "& .MuiTooltip-arrow::before": {
    color: theme.palette.COLORS.NEUTRAL0,
    border: `1px solid ${theme.palette.COLORS.NEUTRAL4}`,
  },
}));
