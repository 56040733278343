import { LAButton } from "../../../components/LAButton/LAButton";
import {
  ButtonContainer,
  Container,
  Email,
  Header,
  StyledLink,
  SubText,
} from "./CheckEmailContent.styles";
import EmailIcon from "../../../assets/icons/EmailIcon.svg";
import SignInIcon from "../../../components/SignInIcon/SignInIcon";

const CheckEmailContent = ({ email }) => {
  return (
    <Container>
      <SignInIcon src={EmailIcon} alt="email-icon" />

      <Header variant="h2" align="center">
        Check your e-mail
      </Header>
      <SubText variant="subtitle3" component="p" align="center" mb={-1}>
        The reset link has been sent to this email:
      </SubText>
      <Email variant="subtitle2" component="p" align="center">
        {email}
      </Email>
      <ButtonContainer justifyContent="center">
        <StyledLink to="/signin">
          <LAButton
            text="Back to log in"
            type="button"
            variant="primary"
            data-testid="cancel-reset"
            fullWidth
          />
        </StyledLink>
      </ButtonContainer>
    </Container>
  );
};
export default CheckEmailContent;
