import React from "react";

const ChevronIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,0,0)"
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={color}
      ></path>
    </svg>
  );
};

export default ChevronIcon;
