import { createWithEqualityFn } from "zustand/traditional";
import { InitialData } from "./state";
import { createStoreActions } from "./actions";

const useAppStore = createWithEqualityFn((set, get) => ({
  ...InitialData,
  ...createStoreActions(set, get),
}));

export default useAppStore;
