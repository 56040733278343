import React from "react";

const UpArrow = ({ stroke, width = 8, height = 6 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666667 5.4987C0.466667 5.4987 0.333333 5.43203 0.2 5.2987C-0.0666667 5.03203 -0.0666667 4.63203 0.2 4.36536L3.53333 1.03203C3.8 0.765365 4.2 0.765365 4.46667 1.03203L7.8 4.36536C8.06667 4.63203 8.06667 5.03203 7.8 5.2987C7.53333 5.56536 7.13333 5.56536 6.86667 5.2987L4 2.43203L1.13333 5.2987C1 5.43203 0.866667 5.4987 0.666667 5.4987Z"
        fill={stroke}
      />
    </svg>
  );
};

export default UpArrow;
