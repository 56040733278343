import { Box, Typography, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing(4),
  marginTop: 100,
  [theme.breakpoints.down("md")]: {
    marginTop: 80,
  },
}));
export const ImgContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 101,
  height: 101,
  backgroundColor: theme.palette.COLORS.NEUTRAL1,
  borderRadius: "50%",
}));
export const RowImgContainer = styled(ImgContainer)(({ theme }) => ({
  width: 60,
  height: 60,
  "& img": {
    width: 38,
    height: 38,
  },
}));
export const TextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: 340,
  textAlign: "center",
  gap: theme.spacing(2.5),
}));

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h3,
  },
}));

export const Subtext = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL6,
  ...theme.typography.subtitle3,
}));

export const RowSubtext = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL6,
  ...theme.typography.body4,
}));

export const RowContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing(4),
  padding: theme.spacing(7.5, 0),
}));
