import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const updateNote =
  (set) =>
  async ({ id, text }) => {
    try {
      set({ isLoading: true });
      await api.put(`/notes/${id}/`, { text });
      set({ isLoading: false });
    } catch (error) {
      set({ isLoading: false });
      throw errorFormater(error.response);
    }
  };

export default updateNote;
