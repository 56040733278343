import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/BackButton/BackButton";
import { PdfGenerator } from "../../../components/PDFGenerator/PDFGenerator";
import { ReportData } from "../../../components/ReportData/ReportData";
import PagesLayout from "../../../Layouts/PagesLayout";
import { useEffect } from "react";
import { shallow } from "zustand/shallow";
import useResponseStore from "../../../state/responses/responses-store";
import {
  Container,
  PageHeader,
  PageTitle,
} from "../../HomePage/HomePageReport/HomePageReport.styles";

export const ResponsePageReport = () => {
  const navigate = useNavigate();
  const [responseDetails, isLoading, getStateFromLocalStore] = useResponseStore(
    (state) => [
      state.responseDetails,
      state.isLoading,
      state.getStateFromLocalStore,
    ],
    shallow
  );
  useEffect(() => {
    if (responseDetails === null && !isLoading) {
      navigate("/*");
    }
  }, [responseDetails, navigate, isLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("prevIsReport", JSON.stringify(true));
  }, []);
  return (
    <PagesLayout>
      <Container>
        <BackButton
          onClick={() => {
            getStateFromLocalStore();
            navigate("/responses", { state: { prev: "report" } });
          }}
        />
        <PageTitle>
          <PageHeader>Short term rental compliance report</PageHeader>
          <PdfGenerator reportTMP={responseDetails} />
        </PageTitle>

        <ReportData report={responseDetails} store={useResponseStore} />
      </Container>
    </PagesLayout>
  );
};
