import { CircularProgress, Typography } from "@mui/material";
import DragDropFileUpload from "../../../components/DragDropFile/DragDropFile";
import {
  DragDropContainer,
  UploadFileTitle,
} from "../../HomePage/AddQuerryPopup/AddQueryPopup.styles";
import { memo, useState } from "react";
import {
  DeleteButton,
  ImagePreview,
  ImagePreviewContainer,
  Images,
} from "./ImageUploadComponent.styles";
import TrashIcon from "../../../assets/icons/TrashIcon.svg";
import { Lightbox } from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import "yet-another-react-lightbox/styles.css";
import { useDisclosure } from "../../../hooks/useDisclosure";
import { DeleteImageWarning } from "./DeleteImageWarning";
export const ImageUploadComponent = memo(
  ({ currentFiles, setCurrentFiles, disabled }) => {
    const deleteWarning = useDisclosure();
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [openIndex, setOpenIndex] = useState(-1);
    const deletePhotoHandle = (index) => {
      setCurrentFiles((prev) => prev.filter((_, i) => i !== index));
    };
    return (
      <DragDropContainer disabled={disabled}>
        <UploadFileTitle>
          <Typography variant="body7">Uploaded images</Typography>
        </UploadFileTitle>

        <DragDropFileUpload
          onFileUpload={setCurrentFiles}
          filesLenght={currentFiles.length}
          disabled={currentFiles.length >= 6}
          requirementsText="Max file size - *MB"
        />
        <Images>
          {currentFiles.length > 0
            ? currentFiles.map((file, index) => (
                <ImagePreviewContainer key={file?.name}>
                  {file ? (
                    <>
                      <ImagePreviewHOC
                        file={file}
                        setOpenIndex={setOpenIndex}
                        index={index}
                      />
                      <DeleteButton
                        onClick={() => {
                          setDeleteIndex(index);
                          deleteWarning.open();
                        }}
                      >
                        <img src={TrashIcon} alt="trash-icon" />
                      </DeleteButton>
                    </>
                  ) : (
                    <CircularProgress color="primary" />
                  )}
                </ImagePreviewContainer>
              ))
            : null}
        </Images>
        <Lightbox
          index={openIndex}
          open={openIndex >= 0}
          plugins={[Fullscreen]}
          close={() => {
            setOpenIndex(-1);
          }}
          slides={currentFiles
            .filter((item) => item !== null)
            .map((el) => {
              return {
                src: el?.url || URL.createObjectURL(el),
              };
            })}
        />
        {deleteWarning.isOpen ? (
          <DeleteImageWarning
            onClose={deleteWarning.close}
            onDelete={() => {
              deletePhotoHandle(deleteIndex);
              deleteWarning.close();
            }}
          />
        ) : null}
      </DragDropContainer>
    );
  }
);

const ImagePreviewHOC = memo(({ file, setOpenIndex, index }) => {
  return (
    <ImagePreview
      src={file?.url || URL.createObjectURL(file)}
      onClick={() => {
        setOpenIndex(index);
      }}
    />
  );
});
