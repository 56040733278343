export const QUERY_PER_PAGE = 25;
export const QUERY_DATE_FORMAT = "MMM D, YY";

export const defaultFilters = {
  single: false,
  batch: false,
  in_progress: false,
  completed: false,
  date: ["", ""],
};
export const queryInitialData = {
  queries: [],
  selectedQuerries: [],
  completedQuerries: [],
  filters: defaultFilters,
  totalQueries: 0,
  isQuerryExistedLength: 0,
  sortingField: "created_at",
  sortingOrder: true,
  searchField: "",
  page: 1,
  pageCount: 1,
  isLoading: false,
};

export const emptyData = {};
