import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const formatLabelByType = (item, type) => {
  if (type === "State") {
    return {
      id: item?.id,
      label: item.name,
      value: item?.id,
    };
  }
  return {
    id: item?.id,
    label: `${item.name}, ${item.state}`,
    value: item?.id,
  };
};
const getComplianceListForReport =
  (set, get) =>
  async ({ search, type }) => {
    const {
      search: searchField,
      nextPage,
      currentPage,
      options,
    } = get({})[`optionsToShow${type}`];

    if (!nextPage && searchField === search) {
      return;
    }
    try {
      set({ isLoading: true });
      const response = await api.get(`/compliances/`, {
        params: {
          page: searchField === search ? nextPage : currentPage,
          name__istartswith: search,
          type,
          is_active: true,
        },
      });

      const { results, next } = response.data;

      set({
        [`optionsToShow${type}`]: {
          nextPage: next ? currentPage + 1 : null,
          currentPage: currentPage + 1,
          search,
          options:
            searchField !== search
              ? results.map((item) => formatLabelByType(item, type))
              : options.concat(
                  results.map((item) => formatLabelByType(item, type))
                ),
        },
        isLoading: false,
      });
    } catch (error) {
      set({ isLoading: false });
      throw errorFormater(error.response);
    }
  };

export default getComplianceListForReport;
