import { Box, TableBody, Typography } from "@mui/material";
import { CompanyContainer } from "./ResponsesPageTable.styles";
import { LAButton } from "../../../components/LAButton/LAButton";

import singleIcon from "../../../assets/icons/SingleIcon.svg";
import { shallow } from "zustand/shallow";
import { useMemo } from "react";
import dayjs from "dayjs";
import { QUERY_DATE_FORMAT } from "../../../state/query/state";
import { LATable } from "../../../components/Table/Table";
import {
  LATableRow,
  TableBodyCell,
} from "../../../components/Table/TableRow/TableRow";
import { HeaderCellContainer } from "../../../components/Table/TableHead/TableHead.styles";
import { CustomTooltipForTable } from "../../HomePage/HomePageTable/HomePageTable.styles";
import useResponseStore from "../../../state/responses/responses-store";

export const ResponsesPageTable = ({ handleFinalise, handleViewDetails }) => {
  const [responses] = useResponseStore((state) => [state.responses], shallow);

  const tableHeaders = useMemo(() => {
    return [
      {
        title: "Case ID",
        name: "case_id",
        isSortable: false,
      },
      {
        title: "Create date",
        name: "created_at",
        isSortable: true,
      },
      {
        title: "Query type",
        name: "is_single",
        isSortable: true,
      },
      {
        title: "Company name",
        name: "organization__name",
        isSortable: true,
      },
      {
        title: "Submitted date",
        name: "submitted_at",
        isSortable: true,
      },
      {
        title: "Submitted by",
        name: "submitted_by",
        isSortable: false,
      },
      {
        title: "",
        name: "",
        isSortable: false,
      },
    ];
  }, []);

  return (
    <LATable
      itemsCount={responses.length}
      store={useResponseStore}
      tableHeaders={tableHeaders}
    >
      {!!responses.length ? (
        <TableBody>
          {responses.map((row) => {
            return (
              <LATableRow key={row.id}>
                <TableBodyCell align="left">
                  <HeaderCellContainer>{row.case_number}</HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    {dayjs(row.created_at).format(QUERY_DATE_FORMAT)}
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <CustomTooltipForTable
                      placement="bottom"
                      arrow
                      title={<Typography paragraph>Single</Typography>}
                    >
                      <img src={singleIcon} alt="file-icon" />
                    </CustomTooltipForTable>
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <CustomTooltipForTable
                      placement="bottom"
                      arrow
                      title={
                        <Typography paragraph>{row.organization}</Typography>
                      }
                    >
                      <CompanyContainer>{row.organization}</CompanyContainer>
                    </CustomTooltipForTable>
                  </HeaderCellContainer>
                </TableBodyCell>

                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    {dayjs(row?.submitted_at).format(QUERY_DATE_FORMAT)}
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <CustomTooltipForTable
                      placement="bottom"
                      arrow
                      title={
                        <Typography paragraph>
                          {row.submitted_by || "-"}
                        </Typography>
                      }
                    >
                      <CompanyContainer>
                        {row.submitted_by || "-"}
                      </CompanyContainer>
                    </CustomTooltipForTable>
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <Box display={"flex"} gap={"20px"} alignItems={"center"}>
                      <LAButton
                        text="View Report"
                        fullWidth
                        size="sm"
                        onClick={() => handleViewDetails(row.id)}
                      />
                      <Typography
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        variant="body2"
                        onClick={() => handleFinalise(row.id)}
                      >
                        Edit Report
                      </Typography>
                    </Box>
                  </HeaderCellContainer>
                </TableBodyCell>
              </LATableRow>
            );
          })}
        </TableBody>
      ) : null}
    </LATable>
  );
};
