import { TableHead } from "@mui/material";
import {
  HeaderCellContainer,
  TableHeaderCell,
  TableHeaderRow,
} from "./TableHead.styles";
import SortArrow from "../../../pages/HomePage/HomePageTable/SortArror/SortArrow";
import { shallow } from "zustand/shallow";
import { errorApiHandler } from "../../../services/errorHandler";
import { useNavigate } from "react-router-dom";

export const LATableHead = ({ headerCells, store }) => {
  const navigation = useNavigate();

  const [sortingField, sortingOrder, updateSorting, getList] = store(
    (state) => [
      state.sortingField,
      state.sortingOrder,
      state.updateSorting,
      state.getList,
    ],
    shallow
  );

  const updateSort = async (name) => {
    try {
      await updateSorting(name);
      await getList();
    } catch (error) {
      errorApiHandler(error, navigation);
    }
  };

  return (
    <TableHead>
      <TableHeaderRow>
        {headerCells.map((head) => {
          return (
            <TableHeaderCell
              align="left"
              key={head.name}
              sortable={head.isSortable}
              onClick={() => head.isSortable && updateSort(head.name)}
            >
              <HeaderCellContainer>
                {!!head.component ? (
                  head.component
                ) : (
                  <>
                    {head.title}
                    {head.isSortable ? (
                      <SortArrow
                        name={head.name}
                        sortingField={sortingField}
                        sortingOrder={sortingOrder}
                      />
                    ) : null}
                  </>
                )}
              </HeaderCellContainer>
            </TableHeaderCell>
          );
        })}
      </TableHeaderRow>
    </TableHead>
  );
};
