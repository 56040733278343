import { Checkbox, styled } from "@mui/material";
import Check from "../assets/icons/CheckIcon.svg";
import unCheck from "../assets/icons/UnCheckedIcon.svg";

export const LACheckbox = ({ ...checkboxProps }) => {
  return (
    <StyledCheckbox
      checkedIcon={<img src={Check} alt="CheckIcon"></img>}
      icon={<img src={unCheck} alt="CheckIcon"></img>}
      {...checkboxProps}
    />
  );
};

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  height: 20,
  width: 20,
  stroke: theme.palette.COLORS.NEUTRAL0,
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  strokeWidth: 1,
  borderRadius: theme.spacing(1),
  color: theme.palette.COLORS.BLUE2,
  strokeLinecap: "round",
  "&.Mui-disabled": {
    color: theme.palette.COLORS.NEUTRAL0,
    filter: "grayscale(45%) opacity(50%)",
  },
  "& .MuiSvgIcon-root": {
    height: 24,
    width: 24,
  },
  "&:hover": {
    backgroundColor: theme.palette.COLORS.NEUTRAL0,
  },
}));
