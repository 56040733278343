import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Paper, Typography, styled } from "@mui/material";
import { smallCustomScroll } from "../../styles";

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  height: 44,
  padding: 0,
}));
export const StyledPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.COLORS.NEUTRAL15}`,
  boxShadow: "none",
  marginTop: theme.spacing(1),
  padding: theme.spacing(2, 0.5, 2, 0),
  "& .MuiAutocomplete-noOptions": {
    padding: theme.spacing(0, 1.5),
  },
  "& .MuiAutocomplete-listbox": {
    ...smallCustomScroll,
    maxHeight: 190,
    padding: 0,
    "& .MuiAutocomplete-option": {
      ...theme.typography.body7,
      padding: theme.spacing(0.5, 1, 0.5, 2),
    },
  },
  "& .MuiAutocomplete-loading": {
    padding: theme.spacing(0, 1.5),
  },
}));
export const StyledInput = styled(({ error: _error, ...props }) => (
  <TextField {...props} />
))(({ theme, error }) => ({
  height: 44,
  width: "100%",
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.COLORS.NEUTRAL05,
  ...theme.typography.body3,
  textAlign: "left",
  outline: "none",
  color: theme.palette.COLORS.NEUTRAL9,

  "& .MuiAutocomplete-inputRoot": {
    height: 44,
    padding: 0,
    borderColor: theme.palette.COLORS.NEUTRAL2,
    "&.Mui-focused": {
      border: `none`,
      outline: "none",
    },
    "&:not(.Mui-focused):hover fieldset, fieldset": {
      borderColor: theme.palette.COLORS.NEUTRAL2,
      ...(error && { borderColor: theme.palette.COLORS.RED5 }),
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      ...(error && { borderColor: theme.palette.COLORS.RED5 }),
      outline: "none",
    },
    "& .MuiAutocomplete-input": {
      boxSizing: "border-box",
      borderRadius: theme.spacing(1),
      ...theme.typography.body3,
      height: 44,
      backgroundColor: theme.palette.COLORS.NEUTRAL05,
      padding: theme.spacing(1.25, 2),
      "&::placeholder": {
        color: theme.palette.COLORS.NEUTRAL5,
      },
      "&:-webkit-autofill, input:-webkit-autofill:focus": {
        "-webkit-box-shadow": `0 0 0 1000px ${theme.palette.COLORS.NEUTRAL05} inset`,
      },
    },
  },
}));

export const CustomCircularProgress = styled(CircularProgress)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const LabelContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  lineHeight: "21px",
  marginBottom: theme.spacing(1),
  alignItems: "center",
  gap: theme.spacing(1),
}));
export const InputLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL7,
}));

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
export const ImgContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 32,
  height: 32,
  backgroundColor: theme.palette.COLORS.NEUTRAL1,
  borderRadius: "50%",
  "& img": {
    height: 21,
    width: 21,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body7,
  color: theme.palette.COLORS.NEUTRAL6,
}));

export const StyledError = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.RED5,
  position: "absolute",
  top: 52,
  left: 0,
  textAlign: "left",
}));

export const Entries = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE6,
  ...theme.typography.small2,
  fontWeight: 600,
  textAlign: "right",
}));
export const Address = styled(Typography)(({ theme }) => ({
  ...theme.typography.body7,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
}));
export const EntriesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));
