import chevron from "../../assets/icons/ChevronDown.svg";
import { StyledButton } from "./BackButton.styles";
export const BackButton = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick} disableRipple>
      <img src={chevron} alt="chevron-icon" />
      Back
    </StyledButton>
  );
};
