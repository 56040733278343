import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const getCompanies = (set) => async () => {
  try {
    set({ isLoading: true });
    const response = await api.get("/organizations/");
    set({
      isLoading: false,
      companies: response.data.map((company) => ({
        id: company.id,
        value: company.name,
        label: company.name,
      })),
    });
    return response.data;
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default getCompanies;
