import { LAButton } from "../../../components/LAButton/LAButton";

import {
  Block,
  StyledForm,
  ButtonContainer,
  OverrideContainer,
  OverrideButton,
  OverridenText,
  CancelButton,
  FormRow,
} from "./FinaliseReportPopup.styles";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputController } from "../../../components/BaseInput/InputController";
import { shallow } from "zustand/shallow";
import { ModalDialog } from "../../../components/ModalDialog/ModalDialog";
import useQueueStore from "../../../state/queue/queue-store";
import { reportScheme } from "./reportScheme";
import { yes_no_options } from "../../CompliancePage/CreateEntityPopup/CreateEntityPopup";
import { MultiSelect } from "../../../components/MultiSelect/MultiSelect";
import { InputContainer } from "../../CompliancePage/CreateEntityPopup/CreateEntityPopup.styles";
import Calendar from "../../../components/CalendarInput/CalenderInput";
import Toaster from "../../../components/Toaster/Toaster";
import { toast } from "sonner";
import useEntityStore from "../../../state/compliance/compliance-store";
import { errorApiHandler } from "../../../services/errorHandler";
import { useNavigate } from "react-router-dom";
import { OverrideEntityPopup } from "./OverrideEntityPopup/OverrideEntityPopup";
import { useDisclosure } from "../../../hooks/useDisclosure";
import { ConfirmSubmitModal } from "./ConfirmSubmitModal/ConfirmSubmitModal";
import useResponseStore from "../../../state/responses/responses-store";
import { useMemo } from "react";
import {
  compliances,
  formatReportFromQueue,
  formatReportFromResponse,
  jurisdictions,
} from "../../../mock/ReportData";
import { Box } from "@mui/material";

const updateComplianceData = (setValue, data) => {
  compliances.forEach((key) => {
    const shortKey = key.replace("_compliance", "");
    setValue(key, data?.response?.[shortKey]?.id);
  });
};

export const FinaliseReportPopup = ({ onClose, inResponse }) => {
  const navigate = useNavigate();

  const entityOverrideModal = useDisclosure();
  const warningDialog = useDisclosure();

  const [
    isLoading,
    queueToFinalise,
    submitQueue,
    getQueueList,
    queueDetails,
    cancelOverride,
    getQueueDetails,
    setPage,
    setIdToOverride,
  ] = useQueueStore(
    (state) => [
      state.isLoading,
      state.queueToFinalise,
      state.submitQueue,
      state.getList,
      state.queueDetails,
      state.cancelOverride,
      state.getDetails,
      state.changePage,
      state.setIdToOverride,
    ],
    shallow
  );

  const [
    isLoadingCompliance,
    getComplianceListForReport,
    optionsToShowState,
    optionsToShowCity,
    optionsToShowHOA,
    optionsToShowCounty,
    updateOptions,
    getEntity,
    setEntityToView,
  ] = useEntityStore(
    (state) => [
      state.isLoading,
      state.getComplianceListForReport,
      state.optionsToShowState,
      state.optionsToShowCity,
      state.optionsToShowHOA,
      state.optionsToShowCounty,
      state.updateOptions,
      state.getEntity,
      state.setEntityToView,
    ],
    shallow
  );

  const [responseDetails, getResponseDetails, submitResponse, getResponseList] =
    useResponseStore(
      (state) => [
        state.responseDetails,
        state.getDetails,
        state.submitResponse,
        state.getList,
      ],
      shallow
    );

  const {
    control,
    setError,
    watch,
    setValue,
    getValues,

    formState: { errors, isValid },
    clearErrors,
  } = useForm({
    defaultValues: inResponse
      ? formatReportFromResponse(responseDetails.response)
      : formatReportFromQueue(queueToFinalise),
    mode: "onChange",
    resolver: yupResolver(reportScheme),
  });

  const currentReportDetail = useMemo(() => {
    inResponse && updateComplianceData(setValue, responseDetails);
    return inResponse ? responseDetails : queueDetails;
  }, [inResponse, responseDetails, queueDetails, setValue]);

  const handleSaveChanges = async (values) => {
    const data = {
      ...values,
    };
    if (inResponse) {
      compliances.forEach((key) => {
        const shortKey = key.replace("_compliance", "");
        if (
          data[key] === currentReportDetail?.response?.[shortKey]?.id ||
          currentReportDetail?.response?.[shortKey]?.is_overridden
        ) {
          delete data[key];
        }
      });
    }
    try {
      inResponse
        ? await submitResponse(data, currentReportDetail.id)
        : await submitQueue(data, queueDetails.id, true);
      toast.custom((t) => (
        <Toaster
          t={t}
          message={
            inResponse
              ? "Changes have been successfully saved"
              : "Report has been successfully submitted"
          }
          type="info"
        />
      ));
      inResponse ? await getResponseList() : await getQueueList();
      onClose();
    } catch (error) {
      errorApiHandler(error, navigate, setError, null, null, () => {
        setPage(1);
        inResponse ? getResponseList() : getQueueList();
        onClose();
      });
    }
  };

  const handleDraftChanges = async () => {
    try {
      await submitQueue(getValues(), queueDetails.id, false);
      toast.custom((t) => (
        <Toaster
          t={t}
          message={"Draft has been successfully saved"}
          type="info"
        />
      ));
      await getQueueList();
      onClose();
    } catch (error) {
      errorApiHandler(error, navigate, setError, null, null, () => {
        setPage(1);
        getQueueList();
        onClose();
      });
    }
  };

  const handleOverrideEntity = async (id, type) => {
    setIdToOverride(currentReportDetail.id);
    try {
      inResponse
        ? setEntityToView({
            ...currentReportDetail?.response?.[type],
            type: jurisdictions[type],
          })
        : await getEntity(id);
      entityOverrideModal.open();
    } catch (error) {
      errorApiHandler(error, navigate);
    }
  };
  const handleCancelOverride = async (type) => {
    try {
      await cancelOverride({ id: currentReportDetail.id, type });
      (await inResponse)
        ? getResponseDetails(currentReportDetail.id)
        : getQueueDetails(currentReportDetail.id);
    } catch (error) {
      errorApiHandler(error, navigate);
    }
  };

  return (
    <ModalDialog title="Finalise Report" onClose={onClose}>
      <StyledForm>
        <Block>
          <FormRow twoColumn>
            <InputContainer>
              <Controller
                control={control}
                name="state"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={(() => {
                        if (
                          inResponse &&
                          currentReportDetail?.response?.state?.id
                        ) {
                          return [
                            {
                              id: currentReportDetail?.response?.state?.id,
                              label: `${currentReportDetail?.response?.state?.name}`,
                              value: currentReportDetail?.response?.state?.id,
                              subTitle: " (Currently in use)",
                            },
                          ].concat(optionsToShowState.options);
                        }
                        return optionsToShowState.options;
                      })()}
                      onSearchOptions={(search) => {
                        getComplianceListForReport({
                          search,
                          type: "State",
                        });
                      }}
                      updateOptions={() => updateOptions("State")}
                      label="State"
                      noItemsSelectedLabel="None"
                      {...field}
                      valueToCompare={watch("state_compliance")}
                      onChange={(e, v, label) => {
                        setValue("state", label);
                        setValue("state_compliance", e.target.value);
                        if (!e.target.value) {
                          setValue("state_has_permit", "");
                          setValue("state_permit_expiry_at", "");
                          setValue("state_notes", "");
                        }
                        clearErrors("state_compliance");
                      }}
                      showErrorMessage
                      error={errors?.state_compliance}
                      withSearch
                      isLoading={isLoadingCompliance}
                      withEmptyValue
                      disabled={
                        currentReportDetail.response.state?.is_overridden
                      }
                    />
                  );
                }}
              />
              <OverrideContainer>
                {currentReportDetail.response.state?.is_overridden ? (
                  <OverridenText variant="body4">Overridden</OverridenText>
                ) : (
                  <OverrideButton
                    variant="body4"
                    onClick={
                      watch("state_compliance") ===
                      currentReportDetail.response.state?.id
                        ? () =>
                            handleOverrideEntity(
                              getValues("state_compliance"),
                              "state"
                            )
                        : null
                    }
                    disabled={
                      watch("state_compliance") !==
                        currentReportDetail.response.state?.id ||
                      currentReportDetail.response.state === null
                    }
                  >
                    Override entity
                  </OverrideButton>
                )}
                {currentReportDetail.response.state?.is_overridden && (
                  <CancelButton
                    variant="body4"
                    onClick={() => handleCancelOverride("state")}
                  >
                    Cancel
                  </CancelButton>
                )}
              </OverrideContainer>
            </InputContainer>
            <InputContainer>
              <Controller
                control={control}
                name="state_has_permit"
                render={({ field }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={yes_no_options}
                      noItemsSelectedLabel="None"
                      label="Has permit?"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!e.target.value) {
                          setValue("state_permit_expiry_at", "");
                        }
                      }}
                      disabled={!watch("state_compliance")}
                      withEmptyValue
                    />
                  );
                }}
              />
            </InputContainer>
          </FormRow>
          <FormRow twoColumn>
            <InputContainer>
              <Controller
                control={control}
                name="state_permit_expiry_at"
                render={({ field, fieldState: { error } }) => (
                  <Calendar
                    label="Permit expiry date"
                    placeholder="MM/DD/YYYY"
                    startDate={field.value}
                    selectsRange={false}
                    disabled={!watch("state_has_permit")}
                    showErrorMessage
                    error={error}
                    {...field}
                  />
                )}
              />
            </InputContainer>
            <InputContainer />
          </FormRow>
          <FormRow>
            <InputContainer disabled={!watch("state_compliance")}>
              <InputController
                control={control}
                label="Notes"
                name="state_notes"
                placeholder="Enter note"
                multiline
                showErrorMessage
                disabled={!watch("state_compliance")}
              />
            </InputContainer>
          </FormRow>
        </Block>
        <Block>
          <FormRow twoColumn>
            <InputContainer>
              <Controller
                control={control}
                name="county"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={(() => {
                        if (
                          inResponse &&
                          currentReportDetail?.response?.county?.id
                        ) {
                          return [
                            {
                              id: currentReportDetail?.response?.county?.id,
                              label: `${currentReportDetail?.response?.county?.name}`,
                              value: currentReportDetail?.response?.county?.id,
                              subTitle: " (Currently in use)",
                            },
                          ].concat(optionsToShowCounty.options);
                        }
                        return optionsToShowCounty.options;
                      })()}
                      onSearchOptions={(search) =>
                        getComplianceListForReport({
                          search,
                          type: "County",
                        })
                      }
                      updateOptions={() => updateOptions("County")}
                      label="County"
                      noItemsSelectedLabel="None"
                      {...field}
                      valueToCompare={watch("county_compliance")}
                      onChange={(e, v, label) => {
                        setValue("county", label);
                        setValue("county_compliance", e.target.value);
                        if (!e.target.value) {
                          setValue("county_has_permit", "");
                          setValue("county_permit_expiry_at", "");
                          setValue("county_notes", "");
                        }
                        clearErrors("county_compliance");
                      }}
                      showErrorMessage
                      error={errors?.county_compliance}
                      withSearch
                      isLoading={isLoadingCompliance}
                      withEmptyValue
                      disabled={
                        currentReportDetail.response.county?.is_overridden
                      }
                    />
                  );
                }}
              />
              <OverrideContainer>
                {currentReportDetail.response.county?.is_overridden ? (
                  <OverridenText variant="body4">Overridden</OverridenText>
                ) : (
                  <OverrideButton
                    variant="body4"
                    onClick={
                      watch("county_compliance") ===
                      currentReportDetail.response.county?.id
                        ? () =>
                            handleOverrideEntity(
                              getValues("county_compliance"),
                              "county"
                            )
                        : null
                    }
                    disabled={
                      watch("county_compliance") !==
                        currentReportDetail.response.county?.id ||
                      currentReportDetail.response.county === null
                    }
                  >
                    Override entity
                  </OverrideButton>
                )}
                {currentReportDetail.response.county?.is_overridden && (
                  <CancelButton
                    variant="body4"
                    onClick={() => handleCancelOverride("county")}
                  >
                    Cancel
                  </CancelButton>
                )}
              </OverrideContainer>
            </InputContainer>
            <InputContainer>
              <Controller
                control={control}
                name="county_has_permit"
                render={({ field }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={yes_no_options}
                      noItemsSelectedLabel="None"
                      label="Has permit?"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!e.target.value) {
                          setValue("county_permit_expiry_at", "");
                        }
                      }}
                      disabled={!watch("county_compliance")}
                      withEmptyValue
                    />
                  );
                }}
              />
            </InputContainer>
          </FormRow>
          <FormRow twoColumn>
            <InputContainer>
              <Controller
                control={control}
                name="county_permit_expiry_at"
                render={({ field, fieldState: { error } }) => (
                  <Calendar
                    label="Permit expiry date"
                    placeholder="MM/DD/YYYY"
                    startDate={field.value}
                    selectsRange={false}
                    disabled={!watch("county_has_permit")}
                    {...field}
                    showErrorMessage
                    error={error}
                  />
                )}
              />
            </InputContainer>
            <InputContainer />
          </FormRow>
          <FormRow>
            <InputContainer disabled={!watch("county_compliance")}>
              <InputController
                control={control}
                label="Notes"
                name="county_notes"
                placeholder="Enter note"
                multiline
                showErrorMessage
                disabled={!watch("county_compliance")}
              />
            </InputContainer>
          </FormRow>
        </Block>
        <Block>
          <FormRow twoColumn>
            <InputContainer>
              <Controller
                control={control}
                name="city"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={(() => {
                        if (
                          inResponse &&
                          currentReportDetail?.response?.city?.id
                        ) {
                          return [
                            {
                              id: currentReportDetail?.response?.city?.id,
                              label: `${currentReportDetail?.response?.city?.name}`,
                              value: currentReportDetail?.response?.city?.id,
                              subTitle: " (Currently in use)",
                            },
                          ].concat(optionsToShowCity.options);
                        }
                        return optionsToShowCity.options;
                      })()}
                      onSearchOptions={(search) =>
                        getComplianceListForReport({
                          search,
                          type: "City",
                        })
                      }
                      updateOptions={() => updateOptions("City")}
                      label="City"
                      noItemsSelectedLabel="None"
                      {...field}
                      valueToCompare={watch("city_compliance")}
                      onChange={(e, v, label) => {
                        setValue("city", label);
                        setValue("city_compliance", e.target.value);
                        if (!e.target.value) {
                          setValue("city_has_permit", "");
                          setValue("city_permit_expiry_at", "");
                          setValue("city_notes", "");
                        }
                        clearErrors("city_compliance");
                      }}
                      showErrorMessage
                      error={errors.city_compliance}
                      withSearch
                      isLoading={isLoadingCompliance}
                      withEmptyValue
                      disabled={
                        currentReportDetail.response.city?.is_overridden
                      }
                    />
                  );
                }}
              />
              <OverrideContainer>
                {currentReportDetail.response.city?.is_overridden ? (
                  <OverridenText variant="body4">Overridden</OverridenText>
                ) : (
                  <OverrideButton
                    variant="body4"
                    onClick={
                      watch("city_compliance") ===
                      currentReportDetail.response.city?.id
                        ? () =>
                            handleOverrideEntity(
                              getValues("city_compliance"),
                              "city"
                            )
                        : null
                    }
                    disabled={
                      watch("city_compliance") !==
                        currentReportDetail.response.city?.id ||
                      currentReportDetail.response.city === null
                    }
                  >
                    Override entity
                  </OverrideButton>
                )}
                {currentReportDetail.response.city?.is_overridden && (
                  <CancelButton
                    variant="body4"
                    onClick={() => handleCancelOverride("city")}
                  >
                    Cancel
                  </CancelButton>
                )}
              </OverrideContainer>
            </InputContainer>
            <InputContainer>
              <Controller
                control={control}
                name="city_has_permit"
                render={({ field }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={yes_no_options}
                      noItemsSelectedLabel="None"
                      label="Has permit?"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!e.target.value) {
                          setValue("city_permit_expiry_at", "");
                        }
                      }}
                      disabled={!watch("city_compliance")}
                      withEmptyValue
                    />
                  );
                }}
              />
            </InputContainer>
          </FormRow>
          <FormRow twoColumn>
            <InputContainer>
              <Controller
                control={control}
                name="city_permit_expiry_at"
                render={({ field, fieldState: { error } }) => (
                  <Calendar
                    label="Permit expiry date"
                    placeholder="MM/DD/YYYY"
                    startDate={field.value}
                    selectsRange={false}
                    disabled={!watch("city_has_permit")}
                    {...field}
                    showErrorMessage
                    error={error}
                  />
                )}
              />
            </InputContainer>
            <InputContainer />
          </FormRow>
          <FormRow>
            <InputContainer disabled={!watch("city_compliance")}>
              <InputController
                control={control}
                label="Notes"
                name="city_notes"
                placeholder="Enter note"
                multiline
                showErrorMessage
                disabled={!watch("city_compliance")}
              />
            </InputContainer>
          </FormRow>
        </Block>
        <Block>
          <FormRow twoColumn>
            <InputContainer>
              <Controller
                control={control}
                name="hoa"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={(() => {
                        if (
                          inResponse &&
                          currentReportDetail?.response?.hoa?.id
                        ) {
                          return [
                            {
                              id: currentReportDetail?.response?.hoa?.id,
                              label: `${currentReportDetail?.response?.hoa?.name}`,
                              value: currentReportDetail?.response?.hoa?.id,
                              subTitle: " (Currently in use)",
                            },
                          ].concat(optionsToShowHOA.options);
                        }
                        return optionsToShowHOA.options;
                      })()}
                      onSearchOptions={(search) =>
                        getComplianceListForReport({
                          search,
                          type: "HOA",
                        })
                      }
                      updateOptions={() => updateOptions("HOA")}
                      label="HOA"
                      noItemsSelectedLabel="None"
                      {...field}
                      valueToCompare={watch("hoa_compliance")}
                      onChange={(e, v, label) => {
                        setValue("hoa", label);
                        setValue("hoa_compliance", e.target.value);
                        if (!e.target.value) {
                          setValue("hoa_has_permit", "");
                          setValue("hoa_permit_expiry_at", "");
                          setValue("hoa_notes", "");
                        }
                        clearErrors("hoa_compliance");
                      }}
                      showErrorMessage
                      error={errors.hoa_compliance}
                      withSearch
                      isLoading={isLoadingCompliance}
                      withEmptyValue
                      disabled={currentReportDetail.response.hoa?.is_overridden}
                    />
                  );
                }}
              />
              <OverrideContainer>
                {currentReportDetail.response.hoa?.is_overridden ? (
                  <OverridenText variant="body4">Overridden</OverridenText>
                ) : (
                  <OverrideButton
                    variant="body4"
                    onClick={
                      watch("hoa_compliance") ===
                      currentReportDetail.response.hoa?.id
                        ? () =>
                            handleOverrideEntity(
                              getValues("hoa_compliance"),
                              "hoa"
                            )
                        : null
                    }
                    disabled={
                      watch("hoa_compliance") !==
                        currentReportDetail.response.hoa?.id ||
                      currentReportDetail.response.hoa === null
                    }
                  >
                    Override entity
                  </OverrideButton>
                )}
                {currentReportDetail.response.hoa?.is_overridden && (
                  <CancelButton
                    variant="body4"
                    onClick={() => handleCancelOverride("hoa")}
                  >
                    Cancel
                  </CancelButton>
                )}
              </OverrideContainer>
            </InputContainer>
            <InputContainer>
              <Controller
                control={control}
                name="hoa_has_permit"
                render={({ field }) => {
                  return (
                    <MultiSelect
                      multiple={false}
                      options={yes_no_options}
                      noItemsSelectedLabel="None"
                      label="Has permit?"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!e.target.value) {
                          setValue("hoa_permit_expiry_at", "");
                        }
                      }}
                      disabled={!watch("hoa_compliance")}
                      withEmptyValue
                    />
                  );
                }}
              />
            </InputContainer>
          </FormRow>
          <FormRow twoColumn>
            <InputContainer>
              <Controller
                control={control}
                name="hoa_permit_expiry_at"
                render={({ field, fieldState: { error } }) => (
                  <Calendar
                    label="Permit expiry date"
                    placeholder="MM/DD/YYYY"
                    startDate={field.value}
                    selectsRange={false}
                    disabled={!watch("hoa_has_permit")}
                    {...field}
                    showErrorMessage
                    error={error}
                  />
                )}
              />
            </InputContainer>
            <InputContainer />
          </FormRow>
          <FormRow>
            <InputContainer disabled={!watch("hoa_compliance")}>
              <InputController
                control={control}
                label="Notes"
                name="hoa_notes"
                placeholder="Enter note"
                multiline
                showErrorMessage
                disabled={!watch("hoa_compliance")}
              />
            </InputContainer>
          </FormRow>
        </Block>
        <Box justifyContent={"center"} display="flex" gap={2.5}>
          <ButtonContainer>
            {inResponse ? (
              <LAButton
                text="Cancel"
                variant="secondary"
                onClick={onClose}
                fullWidth
                loading={isLoading}
                disabled={isLoading}
              />
            ) : (
              <LAButton
                text="Save as a draft"
                variant="secondary"
                onClick={handleDraftChanges}
                fullWidth
                loading={isLoading}
                disabled={isLoading}
              />
            )}
          </ButtonContainer>
          <ButtonContainer>
            <LAButton
              text={inResponse ? "Save Changes" : "Submit"}
              fullWidth
              loading={isLoading}
              onClick={() => {
                isValid && warningDialog.open();
              }}
              disabled={isLoading}
            />
          </ButtonContainer>
        </Box>
      </StyledForm>
      {entityOverrideModal.isOpen ? (
        <OverrideEntityPopup
          onClose={entityOverrideModal.close}
          onSuccess={async () => {
            (await inResponse)
              ? getResponseDetails(currentReportDetail.id)
              : getQueueDetails(currentReportDetail.id);
          }}
        />
      ) : null}
      {warningDialog.isOpen ? (
        <ConfirmSubmitModal
          onClose={warningDialog.close}
          isLoading={isLoading}
          submitResponse={inResponse}
          onConfirm={() => handleSaveChanges(getValues())}
        />
      ) : null}
    </ModalDialog>
  );
};
