import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { COLORS } from "../../../theme/colors";

const styles = StyleSheet.create({
  InfoCard: {
    fontFamily: "Inter",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "100%",
    borderRadius: "5px",
    color: "grey",
    padding: "2mm",
    border: `1px solid ${COLORS.NEUTRAL1}`,
  },
  TypographyBody7: {
    fontWeight: "500",
    fontSize: 7,
    color: COLORS.NEUTRAL7,
  },
  TypographyBody7text: {
    fontWeight: "500",
    fontSize: 7,
    color: COLORS.NEUTRAL9,
  },
});

export const InfoCard = ({ label, text, colored = false, color }) => {
  const bgColor = () => {
    if (colored) {
      if (text === "Yes") {
        return COLORS.GREEN05;
      }
      if (text === "No") {
        return COLORS.RED05;
      }
      if (text === "-") {
        return COLORS.NEUTRAL05;
      }
    }
    return COLORS.BLUE0;
  };
  return (
    <View style={{ ...styles.InfoCard, backgroundColor: color || bgColor() }}>
      <Text style={styles.TypographyBody7}>{label}</Text>
      <Text style={styles.TypographyBody7text}>{text}</Text>
    </View>
  );
};
