import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { CssBaseline } from "@mui/material";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  isRouteErrorResponse,
  useRouteError,
} from "react-router-dom";
import { Toaster } from "sonner";
import SignIn from "./templates/SignIn/SignIn";
import ResetPasswordEmail from "./templates/ResetPasswordEmail/ResetPasswordEmail";
import NewPassword from "./templates/NewPassword/NewPassword";
import useUserStore from "./state/user/user-store";
import { shallow } from "zustand/shallow";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Settings from "./pages/Settings/Settings";
import HomePage from "./pages/HomePage/HomePage";
import CompliancePage from "./pages/CompliancePage/CompliancePage";
import AuthRouter from "./components/PrivateRoute/AuthRoute";
import QueuePage from "./pages/QueuePage/QueuePage";
import { QueuePageReport } from "./pages/QueuePageReport/QueuePageReport";
import { HomePageReport } from "./pages/HomePage/HomePageReport/HomePageReport";
import useQueueStore from "./state/queue/queue-store";
import ResponsesPage from "./pages/ResponsesPage/ResponsesPage";
import useAppStore from "./state/app-store/query-store";
import { ResponsePageReport } from "./pages/ResponsesPage/ResponsePageReport/ResponsePageReport";
import useResponseStore from "./state/responses/responses-store";

export const AppProvider = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}

        <Toaster position="top-right" expand={true} />
      </ThemeProvider>
    </>
  );
};

const CustomRouter = () => {
  const [getDetails, getHomeDetails] = useQueueStore(
    (state) => [state.getDetails, state.getHomeDetails],
    shallow
  );
  const [getDetailsResponse] = useResponseStore(
    (state) => [state.getDetails],
    shallow
  );

  const [loadStoreData] = useUserStore(
    (state) => [state.loadStoreData],
    shallow
  );

  const [getCompanies] = useAppStore((state) => [state.getCompanies], shallow);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        loader={async () => {
          await loadStoreData();
          return null;
        }}
        errorElement={<RootBoundary />}
      >
        <Route path="signin">
          <Route
            element={
              <PrivateRoute forAuthed={false}>
                <SignIn />
              </PrivateRoute>
            }
            index
          />
        </Route>
        <Route path="reset-password-email">
          <Route
            element={
              <PrivateRoute forAuthed={false}>
                <ResetPasswordEmail />
              </PrivateRoute>
            }
            index
          />
        </Route>
        <Route
          path="reset/:token"
          element={
            <PrivateRoute forAuthed={false} navigateTo={"/home"}>
              <NewPassword />
            </PrivateRoute>
          }
        ></Route>

        <Route
          index
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route path="home">
          <Route
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
            index
          />
          <Route
            path=":reportId"
            element={<HomePageReport />}
            loader={async ({ params }) => {
              try {
                await getHomeDetails(params.reportId);
              } catch (err) {
                throw err;
              }
              return null;
            }}
          />
        </Route>
        <Route path="compliance">
          <Route
            element={
              <PrivateRoute onlyStaff>
                <CompliancePage />
              </PrivateRoute>
            }
            index
          />
        </Route>
        <Route path="queue">
          <Route
            element={
              <PrivateRoute onlyStaff>
                <QueuePage />
              </PrivateRoute>
            }
            loader={async () => {
              try {
                await getCompanies(true);
              } catch (err) {
                throw err;
              }
              return null;
            }}
            index
          />
          <Route
            path=":reportId"
            element={<QueuePageReport />}
            loader={async ({ params }) => {
              try {
                await getDetails(params.reportId);
              } catch (err) {
                throw err;
              }
              return null;
            }}
          />
        </Route>
        <Route path="responses">
          <Route
            element={
              <PrivateRoute onlyStaff>
                <ResponsesPage />
              </PrivateRoute>
            }
            index
          />
          <Route
            path=":reportId"
            element={<ResponsePageReport />}
            loader={async ({ params }) => {
              try {
                await getDetailsResponse(params.reportId);
              } catch (err) {
                throw err;
              }
              return null;
            }}
          />
        </Route>
        <Route path="settings">
          <Route
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
            index
          />
        </Route>
        <Route
          path="/*"
          element={
            <AuthRouter>
              <PageNotFound error={404} />
            </AuthRouter>
          }
        ></Route>
      </Route>
    )
  );
  return <RouterProvider router={router} />;
};

function RootBoundary() {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <AuthRouter>
          <PageNotFound error={404} />
        </AuthRouter>
      );
    }
  }
  if (error.status === 403) {
    return <PageNotFound error={403} />;
  }
  if (error.status === 401) {
    return <Navigate to={"/signin"} />;
  }
  if (error.status === 404) {
    return <PageNotFound error={404} />;
  }
  if (error.status > 500) {
    return <PageNotFound error={500} />;
  }
  return (
    <AuthRouter>
      <PageNotFound />
    </AuthRouter>
  );
}

const AppWrapper = () => {
  return (
    <AppProvider>
      <CustomRouter />
    </AppProvider>
  );
};

export default AppWrapper;
