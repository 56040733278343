import React from "react";
import { COLORS } from "../../../../theme/colors";
import UpArrow from "../../../../assets/CustomIcons/UpArrow";
import DownArrow from "../../../../assets/CustomIcons/DownArrow";
import { Box, styled } from "@mui/material";

const SortArrow = ({ name, sortingOrder, sortingField }) => {
  return (
    <Container>
      <UpArrow
        stroke={
          sortingField === name && !sortingOrder
            ? COLORS.NEUTRAL9
            : COLORS.NEUTRAL5
        }
      />
      <DownArrow
        stroke={
          sortingField === name && sortingOrder
            ? COLORS.NEUTRAL9
            : COLORS.NEUTRAL5
        }
      />
    </Container>
  );
};

export default SortArrow;

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.25),
}));
