import { Box, IconButton, styled } from "@mui/material";
import { NavLink } from "react-router-dom";

export const Container = styled(Box)(({ theme }) => ({
  width: 400,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(3),
  padding: theme.spacing(2, 3),
}));
export const MenuHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(1, 0),
}));
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.3),
  borderRadius: 0,
}));
export const ItemList = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

export const StyledIconLogo = styled(Box)(() => ({}));
export const Logo = styled("img")({
  height: 38,
});
export const MenuButton = styled("img")({
  height: 32,
});

export const MenuItem = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2, 3),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  textDecoration: "none",
  "&:hover": {
    backgroundColor: theme.palette.COLORS.NEUTRAL05,
    cursor: "pointer",
  },
}));

export const StyledIcon = styled("img")({
  height: 24,
});
export const StyledLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.COLORS.NEUTRAL9,
  "&.active": {
    backgroundColor: theme.palette.COLORS.BLUE05,
    borderRadius: theme.spacing(1.5),
  },
  "&.active div:hover": {
    backgroundColor: theme.palette.COLORS.BLUE05,
  },
}));
