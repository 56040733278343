import * as yup from "yup";

export const entitySchema = yup.object().shape({
  type: yup.string().required("This field is required"),
  state: yup.string().required("This field is required"),
  name: yup.string().required("This field is required"),
  address: yup.string().when("type", {
    is: (props) => {
      return props === "HOA" || props === "State";
    },
    then: () => yup.string().nullable(),
    otherwise: () => yup.string().required("This field is required").nullable(),
  }),
  is_str_allowed: yup.string().required("Select an option"),
  is_permit_required: yup.string().when("is_str_allowed", {
    is: "true",
    then: () => yup.string().required("Select an option"),
    otherwise: () => yup.string(),
  }),
  legislation_name: yup.string(),
  legislation_web_url: yup.string(),
  legislation_upload_url: yup.string(),
  contact_name: yup.string(),
  contact_email: yup.string(),
  contact_phone: yup.string(),
  law_added_at: null,

  is_owner_occupancy_required: yup.string(),
  owner_occupancy_required_notes: yup.string().max(500, "Max 500 characters"),
  is_entity_limits_number_permit: yup.string(),
  entity_limits_number_permit_notes: yup
    .string()
    .max(500, "Max 500 characters"),
  is_limit_length_stay: yup.string(),
  limit_length_stay_notes: yup.string().max(500, "Max 500 characters"),
  is_license_transferability: yup.string(),
  license_transferability_notes: yup.string().max(500, "Max 500 characters"),
  is_entity_limits_permit_owner: yup.string(),
  entity_limits_permit_owner_notes: yup.string().max(500, "Max 500 characters"),
  is_zone_restricted: yup.string(),
  zone_restriction_notes: yup.string().max(2000, "Max 2000 characters"),
  notes: yup.string().max(2000, "Max 2000 characters"),
});

export const defaultEntity = {
  type: "",
  state: "",
  name: "",
  address: "",
  is_str_allowed: "",
  is_permit_required: "",
  legislation_name: "",
  legislation_web_url: "",
  legislation_upload_url: "",
  contact_name: "",
  contact_email: "",
  contact_phone: "",
  law_added_at: null,

  is_owner_occupancy_required: "",
  owner_occupancy_required_notes: "",
  is_entity_limits_number_permit: "",
  entity_limits_number_permit_notes: "",
  is_limit_length_stay: "",
  limit_length_stay_notes: "",
  is_license_transferability: "",
  license_transferability_notes: "",
  is_entity_limits_permit_owner: "",
  entity_limits_permit_owner_notes: "",
  is_zone_restricted: "",
  zone_restriction_notes: "",
  notes: "",
  images: [],
};
