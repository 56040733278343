import {
  Action,
  ButtonContainer,
  ButtonEditContainer,
  Container,
  Date,
  EditField,
  InputContainer,
  Name,
  NoNotes,
  Note,
  NoteInfo,
  StyledIconButton,
  Text,
  Title,
} from "./NotesSection.styles";

import DeleteIcon from "../../../assets/icons/DeleteIcon.svg";
import EditIcon from "../../../assets/icons/EditIcon.svg";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { InputController } from "../../../components/BaseInput/InputController";
import { LAButton } from "../../../components/LAButton/LAButton";
import { toast } from "sonner";
import Toaster from "../../../components/Toaster/Toaster";
import { errorApiHandler } from "../../../services/errorHandler";
import { DeleteNotePopup } from "./DeleteNotePopup";
import { useDisclosure } from "../../../hooks/useDisclosure";
import { Fragment, useState } from "react";
import { shallow } from "zustand/shallow";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
export const NotesSection = ({ notes, id, store }) => {
  const navigate = useNavigate();
  const [createNote, deleteNoteApi, updateNote, getDetails] = store(
    (state) => [
      state.createNote,
      state.deleteNote,
      state.updateNote,
      state.getDetails,
    ],
    shallow
  );
  const { control, watch, setError, setValue, getValues } = useForm({
    defaultValues: {
      text: "",
      editNote: "",
    },
    mode: "onChange",
  });

  const [editedNote, setEditedNote] = useState(null);
  const [deletedNote, setDeletedNote] = useState(null);

  const deleteNoteModal = useDisclosure();

  const sendNote = async () => {
    try {
      await createNote({ text: getValues("text"), query: id });
      toast.custom((t) => (
        <Toaster
          t={t}
          message={"Note has been successfully added"}
          type="info"
        />
      ));
      setValue("text", "");
      await getDetails(id);
    } catch (err) {
      errorApiHandler(err, navigate, setError);
    }
  };

  const editNote = async () => {
    try {
      await updateNote({ text: getValues("editNote"), id: editedNote });
      toast.custom((t) => (
        <Toaster
          t={t}
          message={"Note has been successfully changed"}
          type="info"
        />
      ));
      setValue("editNote", "");
      setEditedNote(null);
      await getDetails(id);
    } catch (err) {
      errorApiHandler(err, navigate, setError, "editNote", "text");
    }
  };
  const deleteNote = async () => {
    try {
      await deleteNoteApi(deletedNote);
      toast.custom((t) => (
        <Toaster
          t={t}
          message={"Note has been successfully deleted"}
          type="info"
        />
      ));
      setDeletedNote(null);
      deleteNoteModal.close();
      await getDetails(id);
    } catch (err) {
      errorApiHandler(err, navigate, setError);
    }
  };

  return (
    <Container>
      <Title variant="subtitle3">Notes</Title>
      {!notes.length ? <NoNotes>No Notes to Display...</NoNotes> : null}
      {notes.map((note) => {
        if (editedNote === note.id) {
          return (
            <EditField key={note.id}>
              <InputContainer>
                <InputController
                  control={control}
                  label="Edit note"
                  name="editNote"
                  placeholder="Enter note"
                  multiline
                  showErrorMessage
                />
              </InputContainer>
              <Box display={"flex"} gap={2.5}>
                <ButtonEditContainer>
                  <LAButton
                    text="Cancel"
                    variant="secondary"
                    onClick={() => {
                      setValue("editNote", "");
                      setEditedNote(null);
                    }}
                    fullWidth
                  />
                </ButtonEditContainer>
                <ButtonEditContainer>
                  <LAButton
                    text="Save Changes"
                    onClick={editNote}
                    fullWidth
                    disabled={!watch("editNote").length}
                  />
                </ButtonEditContainer>
              </Box>
            </EditField>
          );
        }
        return (
          <Note key={note.id + 1}>
            <NoteInfo>
              <Name>{note.created_by}</Name>
              <Date>
                {dayjs(note.created_at).format("MMM D, YYYY [at] h:mm A")}
              </Date>
            </NoteInfo>
            <Text>
              {note?.text?.split("\n").map((part, i) => {
                return (
                  <Fragment key={part + i}>
                    {part}
                    <br />
                  </Fragment>
                );
              })}
            </Text>
            {note.is_owner ? (
              <Action>
                <StyledIconButton
                  disableRipple
                  onClick={() => {
                    deleteNoteModal.open();
                    setDeletedNote(note.id);
                  }}
                >
                  <img src={DeleteIcon} alt="delete-icon" />
                </StyledIconButton>
                <StyledIconButton
                  disableRipple
                  onClick={() => {
                    setValue("editNote", note.text);
                    setEditedNote(note.id);
                  }}
                >
                  <img src={EditIcon} alt="edit-icon" />
                </StyledIconButton>
              </Action>
            ) : null}
          </Note>
        );
      })}
      <InputContainer>
        <InputController
          control={control}
          label="Leave note"
          name="text"
          placeholder="Enter note"
          multiline
          showErrorMessage
        />
      </InputContainer>

      <ButtonContainer>
        <LAButton
          text="Leave Note"
          onClick={sendNote}
          fullWidth
          disabled={!watch("text").length}
        />
      </ButtonContainer>
      {deleteNoteModal.isOpen ? (
        <DeleteNotePopup
          onClose={() => {
            setDeletedNote(null);
            deleteNoteModal.close();
          }}
          onDelete={deleteNote}
        />
      ) : null}
    </Container>
  );
};
