import getList from "./actions/getQueryList";
import changePage from "./actions/changePage";
import createQuery from "./actions/createQuery";
import selectAll from "./actions/selectAll";
import selectOne from "./actions/selectOne";
import { updateSorting } from "./actions/updateSorting";
import onSearchChange from "./actions/onSearchChange";
import { updateFilters } from "./actions/updateFilters";
import getReports from "./actions/getReports";
import {
  getStateFromLocalStore,
  updateCurrentFiltersInLocalStore,
  updateSelectedQueriesFromLocalStore,
  updateSelectedQueriesInLocalStore,
} from "./actions/updateSelectedQueriesInLocalStore";
import { resetPageProps } from "./actions/resetPageProps";

export const createQueryStoreActions = (set, get) => ({
  updateSorting: (field) => {
    set({
      selectedQuerries: [],
    });
    updateSorting(field, set, get);
  },
  changePage: (page) => {
    set({
      selectedQuerries: [],
    });
    changePage(set)(page);
  },
  selectAll: selectAll(set, get),
  selectOne: selectOne(set, get),
  createQuery: createQuery(set),
  getList: getList(set, get),
  onSearchChange: (value) => {
    set({
      selectedQuerries: [],
    });
    onSearchChange(set)(value);
    getList(set, get)(false);
  },
  updateFilters: (filters, options) => {
    set({
      selectedQuerries: [],
    });
    updateFilters(set)(filters, options);
  },
  getReports: getReports(set, get),
  updateSelectedQueriesInLocalStore: updateSelectedQueriesInLocalStore(get),
  updateSelectedQueriesFromLocalStore: updateSelectedQueriesFromLocalStore(set),
  updateCurrentFiltersInLocalStore: updateCurrentFiltersInLocalStore(get),
  getStateFromLocalStore: getStateFromLocalStore(set),

  resetPageProps: resetPageProps(set, get),
});
