import { Link } from "@react-pdf/renderer";
import { COLORS } from "../theme/colors";

const regExPLink =
  /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim;

export const parseText = (text) => {
  const res = [];

  text &&
    text.replace(regExPLink, (m, link, text) => {
      res.push(
        link ? (
          <a
            href={(link[0] === "w" ? "//" : "") + link}
            key={res.length}
            target="_blank"
            rel="noreferrer"
            style={{
              color: COLORS.BLUE6,
            }}
          >
            {link}
          </a>
        ) : (
          text
        )
      );
    });
  return res;
};

export const parseTextInPDF = (text) => {
  const res = [];

  text &&
    text.replace(regExPLink, (m, link, text) => {
      res.push(
        link ? (
          <Link
            src={(link[0] === "w" ? "//" : "") + link}
            style={{ wordBreak: "break-all", color: COLORS.BLUE6 }}
          >
            {link}
          </Link>
        ) : (
          text
        )
      );
    });
  return res;
};
