import { Box, Pagination, PaginationItem, styled } from "@mui/material";
import { shallow } from "zustand/shallow";

import {
  PaginationArrowLeft,
  PaginationArrowRight,
} from "../../assets/CustomIcons/PaginationArrow";
import { errorApiHandler } from "../../services/errorHandler";
import { useNavigate } from "react-router-dom";
export const TablePagination = ({ store }) => {
  const navigation = useNavigate();
  const [pageCount, page, changePage, getList] = store(
    (state) => [state.pageCount, state.page, state.changePage, state.getList],
    shallow
  );

  const updatePage = async (page) => {
    try {
      await changePage(page);
      await getList();
    } catch (error) {
      errorApiHandler(error, navigation);
    }
  };

  return (
    <FooterContainer>
      <StyledPagination
        count={pageCount || 1}
        shape="rounded"
        variant="outlined"
        siblingCount={1}
        boundaryCount={1}
        page={page}
        onChange={(_, page) => updatePage(page)}
        renderItem={(item) => (
          <PaginationItem
            slots={{
              previous: PaginationArrowLeft,
              next: PaginationArrowRight,
            }}
            {...item}
          />
        )}
      />
    </FooterContainer>
  );
};

const FooterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2, 0, 3),
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPagination-ul": {
    justifyContent: "center",
    "& li": {
      width: 29,
      height: 29,
      margin: theme.spacing(0, 0.5),
    },
    "& li:first-of-type > button, & li:last-child > button": {
      border: "none",
      backgroundColor: theme.palette.COLORS.NEUTRAL0,
      width: 48,
      padding: theme.spacing(0.5, 1.75),
    },
    "& li:first-of-type, & li:last-child": {
      width: 48,
      margin: theme.spacing(0, 2),
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.COLORS.BLUE5,
      color: theme.palette.COLORS.NEUTRAL0,
      border: "none",
    },
    "& button": {
      border: "none",
      borderRadius: theme.spacing(0.5),
      width: "100%",
      minWidth: 29,
      height: "100%",
      padding: theme.spacing(0.5, 1.25),
      color: theme.palette.COLORS.NEUTRAL6,
      margin: 0,
      ...theme.typography.body7,
    },
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));
