import { Box, IconButton, styled } from "@mui/material";

export const Images = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  paddingTop: theme.spacing(1),
}));
export const ImagePreviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxHeight: 130,
  minHeight: 130,
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.COLORS.NEUTRAL1,
  position: "relative",
  cursor: "pointer",
  width: 180,
}));
export const ImagePreview = styled("img")(({ theme }) => ({
  maxHeight: "100%",
  maxWidth: "100%",
}));

export const DeleteButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  backgroundColor: "rgba(44, 48, 56, 0.8)",
  borderRadius: 8,
  width: 32,
  height: 32,
  right: 8,
  top: 8,
  "&:hover": {
    backgroundColor: theme.palette.COLORS.NEUTRAL9,
  },
}));
