import dayjs from "dayjs";
import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const optionsToRemove = ["state", "county", "hoa", "city"];
const submitResponse = (set) => async (data, id) => {
  const entity = {
    ...data,
  };
  Object.entries(entity).forEach(([key, value]) => {
    if (entity[key] === "") {
      entity[key] = null;
    }
    if (key.endsWith("_expiry_at") && value !== "") {
      entity[key] = dayjs(entity[key]).format("YYYY-MM-DD");
    }
  });

  optionsToRemove.forEach((option) => delete entity[option]);
  try {
    set({ isLoading: true });
    await api.put(`/responses/${id}/`, entity);
    set({ isLoading: false });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default submitResponse;
