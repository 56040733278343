import addressAutocomplete from "./commonActions/AddressAutocomplete";
import getCompanies from "./commonActions/getCompanies";
import getCounty from "./commonActions/getCounty";

export const createStoreActions = (set, get) => ({
  findAddress: addressAutocomplete(set),
  getCounty: getCounty(set),
  clearAddresses: () => {
    set({ addresses: [] });
  },
  getCompanies: getCompanies(set),
});
