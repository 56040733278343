import { Typography, Box } from "@mui/material";
import {
  Column,
  ColumnCell,
  ColumnHeader,
  HeaderRow,
  InfoCard,
  InfoColumns,
  InfoRow,
  InformationBlock,
  LabelColumn,
  Main,
  NotesColumnHeader,
  NotesHeader,
  NotesRow,
  NotesRowCell,
  NotesTable,
  PermitTable,
  ReportInfo,
  STRTable,
  TableBody,
} from "./ReportData.styles";
import { COLORS } from "../../theme/colors";
import { NotesSection } from "../../pages/QueuePageReport/NotesSection/NotesSection";
import dayjs from "dayjs";
import { Fragment, useMemo } from "react";
import {
  STRLabels,
  STRLabelsForNotes,
  jurisdictionLabels,
  jurisdictions,
  notes,
  overallResultsColors,
} from "../../mock/ReportData";
import { parseText } from "../../utils/linkSearch";

export const ReportData = ({ noNotes = false, report, store }) => {
  const reportResponses = useMemo(
    () => ({
      city: report?.response?.city,
      county: report?.response?.county,
      hoa: report?.response?.hoa,
      state: report?.response?.state,
    }),
    [report?.response]
  );
  const permitNotes = useMemo(
    () => ({
      city: report?.response?.city_notes,
      county: report?.response?.county_notes,
      hoa: report?.response?.hoa_notes,
      state: report?.response?.state_notes,
    }),
    [report?.response]
  );
  const permitExpires = useMemo(
    () => ({
      city: report?.response?.city_permit_expiry_at,
      county: report?.response?.county_permit_expiry_at,
      hoa: report?.response?.hoa_permit_expiry_at,
      state: report?.response?.state_permit_expiry_at,
    }),
    [report?.response]
  );
  const hasPermits = useMemo(
    () => ({
      city: report?.response?.city_has_permit,
      county: report?.response?.county_has_permit,
      hoa: report?.response?.hoa_has_permit,
      state: report?.response?.state_has_permit,
    }),
    [report?.response]
  );
  const isJurisdictionsExist =
    report?.response &&
    Object.values(reportResponses).filter((item) => item !== null).length;
  const isNotesExist = useMemo(() => {
    if (!report?.response) return 0;
    const reportsWithNotes = Object.entries(reportResponses).filter(
      ([key, value]) => {
        if (!value) {
          return false;
        }
        const allNotes = notes.reduce((acc, n) => {
          if (!value[n]) return acc;
          else {
            return acc + 1;
          }
        }, 0);
        return allNotes > 0;
      }
    );
    const permitNotesLength = Object.entries(permitNotes).filter(
      ([key, value]) => value
    ).length;
    return reportsWithNotes.length || permitNotesLength;
  }, [report, reportResponses, permitNotes]);

  return (
    <Main>
      <InformationBlock>
        <Typography variant="subtitle3" align="left" component={"p"} mb={-1.5}>
          Information
        </Typography>
        <InfoRow>
          <InFoCardContainer
            label="Address"
            text={`${report?.street}, ${report?.city}, ${report?.state} ${report?.postal_code}`}
            color={COLORS.BLUE0}
          />
          <InFoCardContainer
            label="Owner name(s)"
            text={report?.owner_names}
            color={COLORS.BLUE0}
          />
          <InFoCardContainer
            label="Owner Occupied?"
            text={
              report?.is_occupied === null
                ? "-"
                : report?.is_occupied
                ? "Yes"
                : "No"
            }
            color={COLORS.BLUE0}
          />
          <InFoCardContainer
            label="House Type"
            text={report?.housing_type}
            color={COLORS.BLUE0}
          />
        </InfoRow>
        <InfoRow>
          <InFoCardContainer
            label="Prepared for"
            text={report?.organization}
            color={COLORS.BLUE0}
          />
          <InFoCardContainer
            label="Requested by"
            text={report?.created_by}
            color={COLORS.BLUE0}
          />
          <InFoCardContainer
            label="Date completed"
            text={
              report?.submitted_at
                ? dayjs(report?.submitted_at).format("MM/DD/YYYY")
                : "-"
            }
            color={COLORS.BLUE0}
          />
          <InFoCardContainer
            label="Case ID"
            text={report?.case_number}
            color={COLORS.BLUE0}
          />
        </InfoRow>
      </InformationBlock>
      <InformationBlock>
        <Typography variant="subtitle3" align="left" component={"p"}>
          Overall Results
        </Typography>
        <InfoRow mt={-1.5}>
          <InFoCardContainer
            label="STR allowed?"
            text={
              report?.overall_result?.is_str_allowed === null
                ? "-"
                : report?.overall_result?.is_str_allowed
                ? "Yes"
                : "No"
            }
            color={overallResultsColors[report?.overall_result?.is_str_allowed]}
          />
          <InFoCardContainer
            label="Permit required?"
            text={
              report?.overall_result?.is_permit_required === null
                ? "-"
                : report?.overall_result?.is_permit_required
                ? "Yes"
                : "No"
            }
            color={
              report?.overall_result?.is_permit_required
                ? COLORS.GREEN05
                : undefined
            }
          />
          <InFoCardContainer
            label="Permit compliant?"
            text={
              report?.overall_result?.is_permit_compliant === null
                ? "-"
                : report?.overall_result?.is_permit_compliant
                ? "Yes"
                : "No"
            }
            color={
              overallResultsColors[report?.overall_result?.is_permit_compliant]
            }
          />
        </InfoRow>
        {isJurisdictionsExist ? (
          <Box>
            <PermitTable>
              <LabelColumn>
                <ColumnHeader>
                  <Typography variant="body5" component={"p"}>
                    Applicable jurisdiction
                  </Typography>
                </ColumnHeader>
                {jurisdictionLabels.map((label) => (
                  <ColumnCell key={label}>
                    <Typography variant="body5" component={"p"}>
                      {label}
                    </Typography>
                  </ColumnCell>
                ))}
              </LabelColumn>
              <InfoColumns>
                {Object.entries(reportResponses).map(([key, item]) => {
                  if (item === null) return null;
                  return (
                    <Column
                      key={item?.id}
                      is_failed={!report.is_compliant_pass[key]}
                    >
                      <ColumnHeader>
                        <Typography variant="body5" component={"p"}>
                          {jurisdictions[key]}{" "}
                          <Typography
                            variant="body8"
                            ml={0.5}
                            color={COLORS.NEUTRAL6}
                          >
                            {item.name}
                          </Typography>
                        </Typography>
                      </ColumnHeader>
                      <ColumnCell>
                        <Typography
                          variant="body5"
                          component={"p"}
                          sx={{
                            visibility:
                              item?.is_str_allowed === null
                                ? "hidden"
                                : "visible",
                          }}
                        >
                          {item?.is_str_allowed === null
                            ? "-"
                            : item?.is_str_allowed
                            ? "Yes"
                            : "No"}
                        </Typography>
                      </ColumnCell>
                      <ColumnCell>
                        <Typography
                          variant="body5"
                          component={"p"}
                          sx={{
                            visibility:
                              item?.is_permit_required === null
                                ? "hidden"
                                : "visible",
                          }}
                        >
                          {item?.is_permit_required === null
                            ? "-"
                            : item?.is_permit_required
                            ? "Yes"
                            : "No"}
                        </Typography>
                      </ColumnCell>
                      <ColumnCell>
                        <Typography
                          variant="body5"
                          component={"p"}
                          sx={{
                            visibility:
                              hasPermits[key] === null ? "hidden" : "visible",
                          }}
                        >
                          {hasPermits[key] === null
                            ? "-"
                            : hasPermits[key]
                            ? "Yes"
                            : "No"}
                        </Typography>
                      </ColumnCell>
                      <ColumnCell>
                        <Typography
                          variant="body8"
                          component={"p"}
                          sx={{
                            visibility:
                              permitExpires[key] === null || !permitExpires[key]
                                ? "hidden"
                                : "visible",
                          }}
                        >
                          {permitExpires[key]
                            ? dayjs(permitExpires[key]).format("MMM DD, YYYY")
                            : "-"}
                        </Typography>
                      </ColumnCell>
                      <ColumnCell>
                        <Typography
                          variant="body8"
                          component={"p"}
                          sx={{
                            visibility:
                              item?.law_added_at === null
                                ? "hidden"
                                : "visible",
                          }}
                        >
                          {item?.law_added_at
                            ? dayjs(item?.law_added_at).format("MMM DD, YYYY")
                            : "-"}
                        </Typography>
                      </ColumnCell>
                    </Column>
                  );
                })}
                <Column>
                  <ColumnHeader>
                    <Typography variant="body5" component={"p"}>
                      none
                    </Typography>
                  </ColumnHeader>
                  {Array(5)
                    .fill("none")
                    .map((i, index) => (
                      <ColumnCell key={index}>
                        <Typography variant="body5" component={"p"}>
                          {i}
                        </Typography>
                      </ColumnCell>
                    ))}
                </Column>
              </InfoColumns>
            </PermitTable>

            <STRTable>
              <HeaderRow>
                <Typography variant="body5" component={"p"}>
                  Additional STR Laws
                </Typography>
              </HeaderRow>
              <TableBody>
                <LabelColumn>
                  {STRLabels.map((label) => (
                    <ColumnCell key={label}>
                      <Typography variant="body5" component={"p"}>
                        {label}
                      </Typography>
                    </ColumnCell>
                  ))}
                </LabelColumn>
                <InfoColumns>
                  {Object.entries(reportResponses).map(([key, item]) => {
                    if (!item) return null;
                    return (
                      <Column key={item?.id}>
                        <ColumnCell>
                          <Typography
                            variant="body5"
                            component={"p"}
                            sx={{
                              visibility:
                                item?.is_owner_occupancy_required === null
                                  ? "hidden"
                                  : "visible",
                            }}
                          >
                            {item?.is_owner_occupancy_required === null
                              ? "-"
                              : item?.is_owner_occupancy_required
                              ? "Yes"
                              : "No"}
                            {item?.owner_occupancy_required_notes ? (
                              <Typography variant="small2" ml={0.5}>
                                (see entity notes)
                              </Typography>
                            ) : null}
                          </Typography>
                        </ColumnCell>
                        <ColumnCell>
                          <Typography
                            variant="body5"
                            component={"p"}
                            sx={{
                              visibility:
                                item?.is_limit_length_stay === null
                                  ? "hidden"
                                  : "visible",
                            }}
                          >
                            {item?.is_limit_length_stay === null
                              ? "-"
                              : item?.is_limit_length_stay
                              ? "Yes"
                              : "No"}
                            {item?.limit_length_stay_notes ? (
                              <Typography variant="small2" ml={0.5}>
                                (see entity notes)
                              </Typography>
                            ) : null}
                          </Typography>
                        </ColumnCell>
                        <ColumnCell>
                          <Typography
                            variant="body5"
                            component={"p"}
                            sx={{
                              visibility:
                                item?.is_entity_limits_permit_owner === null
                                  ? "hidden"
                                  : "visible",
                            }}
                          >
                            {item?.is_entity_limits_permit_owner === null
                              ? "-"
                              : item?.is_entity_limits_permit_owner
                              ? "Yes"
                              : "No"}
                            {item?.entity_limits_permit_owner_notes ? (
                              <Typography variant="small2" ml={0.5}>
                                (see entity notes)
                              </Typography>
                            ) : null}
                          </Typography>
                        </ColumnCell>
                        <ColumnCell>
                          <Typography
                            variant="body5"
                            component={"p"}
                            sx={{
                              visibility:
                                item?.is_entity_limits_number_permit === null
                                  ? "hidden"
                                  : "visible",
                            }}
                          >
                            {item?.is_entity_limits_number_permit === null
                              ? "-"
                              : item?.is_entity_limits_number_permit
                              ? "Yes"
                              : "No"}
                            {item?.entity_limits_number_permit_notes ? (
                              <Typography variant="small2" ml={0.5}>
                                (see entity notes)
                              </Typography>
                            ) : null}
                          </Typography>
                        </ColumnCell>
                        <ColumnCell>
                          <Typography
                            variant="body5"
                            component={"p"}
                            sx={{
                              visibility:
                                item?.is_license_transferability === null
                                  ? "hidden"
                                  : "visible",
                            }}
                          >
                            {item?.is_license_transferability === null
                              ? "-"
                              : item?.is_license_transferability
                              ? "Yes"
                              : "No"}
                            {item?.license_transferability_notes ? (
                              <Typography variant="small2" ml={0.5}>
                                (see entity notes)
                              </Typography>
                            ) : null}
                          </Typography>
                        </ColumnCell>
                        <ColumnCell>
                          <Typography
                            variant="body5"
                            component={"p"}
                            sx={{
                              visibility:
                                item?.is_zone_restricted === null
                                  ? "hidden"
                                  : "visible",
                            }}
                          >
                            {item?.is_zone_restricted === null
                              ? "-"
                              : item?.is_zone_restricted
                              ? "Yes"
                              : "No"}
                            {item?.zone_restriction_notes ? (
                              <Typography variant="small2" ml={0.5}>
                                (see entity notes)
                              </Typography>
                            ) : null}
                          </Typography>
                        </ColumnCell>
                      </Column>
                    );
                  })}
                  <Column>
                    {Array(6)
                      .fill("none")
                      .map((i, index) => (
                        <ColumnCell key={index}>
                          <Typography variant="body5" component={"p"}>
                            {i}
                          </Typography>
                        </ColumnCell>
                      ))}
                  </Column>
                </InfoColumns>
              </TableBody>
            </STRTable>
          </Box>
        ) : null}
        {isNotesExist ? (
          <Box>
            <NotesTable>
              <NotesHeader>
                <NotesColumnHeader>
                  <Typography
                    variant="body5"
                    component={"p"}
                    visibility={"hidden"}
                  >
                    none
                  </Typography>
                </NotesColumnHeader>
                <NotesColumnHeader>
                  <Typography variant="body5" component={"p"}>
                    Entity Notes
                  </Typography>
                </NotesColumnHeader>
                <NotesColumnHeader>
                  <Typography variant="body5" component={"p"}>
                    Permit Notes
                  </Typography>
                </NotesColumnHeader>
              </NotesHeader>

              {Object.entries(reportResponses).map(([key, item]) => {
                if (
                  (!item ||
                    notes.reduce((acc, n) => {
                      if (!item[n]) return acc;
                      else {
                        return acc + 1;
                      }
                    }, 0) === 0) &&
                  !permitNotes[key]
                ) {
                  return null;
                }
                return (
                  <NotesRow key={item?.id || key}>
                    <NotesRowCell>
                      <Typography variant="body5" component={"p"}>
                        {jurisdictions[key]}
                      </Typography>
                    </NotesRowCell>
                    <NotesRowCell>
                      {item &&
                        notes.map((key) => {
                          if (!item?.[key]) return null;
                          return (
                            <Typography
                              variant="body8"
                              component={"p"}
                              key={key}
                              style={{ wordBreak: "break-word" }}
                            >
                              <Typography variant="body6" ml={0.5}>
                                {STRLabelsForNotes[key]}
                              </Typography>
                              {item[key]?.split("\n").map((part, i) => {
                                return (
                                  <Fragment key={part + i}>
                                    {parseText(part)}
                                    <br />
                                  </Fragment>
                                );
                              })}
                            </Typography>
                          );
                        })}
                    </NotesRowCell>
                    <NotesRowCell>
                      <Typography
                        variant="body8"
                        component={"p"}
                        style={{ wordBreak: "break-word" }}
                      >
                        {permitNotes[key]?.split("\n").map((part, i) => {
                          return (
                            <Fragment key={part + i}>
                              {parseText(part)}
                              <br />
                            </Fragment>
                          );
                        })}
                      </Typography>
                    </NotesRowCell>
                  </NotesRow>
                );
              })}
            </NotesTable>
          </Box>
        ) : null}
      </InformationBlock>
      {!noNotes ? (
        <NotesSection notes={report?.notes} id={report?.id} store={store} />
      ) : null}
      <ReportInfo>
        The use of this report is only permitted by active customers of ADPG LLC
        (dba Property Guard), and is subject to the Terms of Service agreed to
        by such customer. ADPG LLC disclaims any responsibility for use of this
        report by individuals who have not agreed to its Terms of Service
      </ReportInfo>
    </Main>
  );
};

const InFoCardContainer = ({ label, text, color }) => {
  return (
    <InfoCard color={color}>
      <Typography variant="body7" component={"p"}>
        {label}
      </Typography>
      <Typography
        variant="body7"
        component={"p"}
        sx={{ color: COLORS.NEUTRAL9 }}
      >
        {text}
      </Typography>
    </InfoCard>
  );
};
