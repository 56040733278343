import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { InfoCard } from "./components/ReportCard";
import Logo from "../../assets/icons/Logo.png";
import { StrTable } from "./components/StrTable";
import font from "../../assets/fonts/Inter-font.ttf";
import fontBold from "../../assets/fonts/Inter-Bold.ttf";
import fontSemiBold from "../../assets/fonts/Inter-SemiBold.ttf";
import fontRegular from "../../assets/fonts/Inter-Regular.ttf";
import { COLORS } from "../../theme/colors";
import { NotesHeader, NotesTable } from "./components/NotesTable";
import dayjs from "dayjs";
import { useMemo } from "react";
import { notes } from "../../mock/ReportData";

Font.register({
  family: "Inter",
  fonts: [
    { src: font },
    { src: fontBold, fontWeight: 700 },
    { src: fontSemiBold, fontWeight: 600 },
    { src: fontRegular, fontWeight: 400 },
  ],
});

export const styles = StyleSheet.create({
  page: { fontFamily: "Inter", padding: "16px 0" },
  Main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 16px",
    gap: 10,
    background: "white",
    borderRadius: "7px",
  },
  InformationBlock: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  Typography: {
    fontSize: 10,
    fontWeight: 600,
  },
  Title: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 16,
    textAlign: "center",
  },
  logoBox: {
    paddingTop: "-16px",
    width: "23mm",
  },
  logo: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  InfoRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: 10,
    flexWrap: "nowrap",
  },
  TypographyBody7: {
    fontSize: 7,
    color: COLORS.NEUTRAL7,
  },
  TypographyBody7text: {
    fontSize: 7,
    color: COLORS.NEUTRAL9,
  },
  Footer: {
    padding: "3mm 35mm",
    width: "100%",
  },
  FooterText: {
    fontSize: 5,
    color: COLORS.NEUTRAL5,
    textAlign: "center",
    width: "100%",
  },
  Header: {
    width: "100%",
    height: "20px",
    backgroundColor: "red",
  },
  pageNumbers: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    fontSize: 30,
    textAlign: "center",
  },
  HeaderContainer: {
    padding: "0 16px",
  },
});

export const ReportTemplate = ({ reportTMP }) => {
  const reportResponses = {
    city: reportTMP?.response?.city,
    county: reportTMP?.response?.county,
    hoa: reportTMP?.response?.hoa,
    state: reportTMP?.response?.state,
  };
  const permitNotes = useMemo(
    () => ({
      city: reportTMP?.response?.city_notes,
      county: reportTMP?.response?.county_notes,
      hoa: reportTMP?.response?.hoa_notes,
      state: reportTMP?.response?.state_notes,
    }),
    [reportTMP]
  );
  const permitExpires = useMemo(
    () => ({
      city: reportTMP?.response?.city_permit_expiry_at,
      county: reportTMP?.response?.county_permit_expiry_at,
      hoa: reportTMP?.response?.hoa_permit_expiry_at,
      state: reportTMP?.response?.state_permit_expiry_at,
    }),
    [reportTMP?.response]
  );
  const hasPermits = useMemo(
    () => ({
      city: reportTMP?.response?.city_has_permit,
      county: reportTMP?.response?.county_has_permit,
      hoa: reportTMP?.response?.hoa_has_permit,
      state: reportTMP?.response?.state_has_permit,
    }),
    [reportTMP?.response]
  );
  const jurisdictionsTable = reportTMP?.response
    ? Object.entries(reportResponses).filter((item) => item[1] !== null)
    : null;
  const allNotes =
    jurisdictionsTable?.length > 0
      ? notes.reduce((acc, item) => {
          return acc + jurisdictionsTable[0]?.[1]?.[item];
        }, "")
      : "";
  const isNotesExist = useMemo(() => {
    const notesCounter = jurisdictionsTable?.reduce((acc, [key, value], i) => {
      let count = 0;
      if (value) {
        notes.forEach((innerKey) => {
          if (value[innerKey]?.length > 0) {
            count++;
          }
        });
      }
      return acc + count;
    }, 0);
    const permitNotesLength = Object.entries(permitNotes).filter(
      ([key, value]) => value
    ).length;
    return notesCounter || permitNotesLength;
  }, [jurisdictionsTable, permitNotes]);

  return (
    <Document>
      <Page size="A4" style={styles.page} pageCount>
        <View
          style={styles.HeaderContainer}
          render={({ pageNumber }) => {
            if (pageNumber === 2 && allNotes?.length > 1000) {
              return null;
            }
            return pageNumber > 1 && <NotesHeader />;
          }}
          fixed
        />
        <View style={styles.Main}>
          <View style={styles.logoBox}>
            <Image styles={styles.logo} src={Logo} />
          </View>

          <Text style={styles.Title}>Short term rental compliance report</Text>
          <View style={styles.InformationBlock}>
            <Text style={styles.Typography}>Information</Text>
            <View style={styles.InfoRow}>
              <InfoCard
                label="Address"
                text={`${reportTMP?.street}, ${reportTMP?.city}, ${reportTMP?.state} ${reportTMP?.postal_code}`}
              />
              <InfoCard label="Owner name(s)" text={reportTMP?.owner_names} />
              <InfoCard
                label="Owner Occupied?"
                text={
                  reportTMP?.is_occupied === null
                    ? "-"
                    : reportTMP?.is_occupied
                    ? "Yes"
                    : "No"
                }
              />
              <InfoCard label="House Type" text={reportTMP?.housing_type} />
            </View>
            <View style={styles.InfoRow}>
              <InfoCard label="Prepared for" text={reportTMP?.organization} />
              <InfoCard label="Requested by" text={reportTMP?.created_by} />
              <InfoCard
                label="Date completed"
                text={
                  reportTMP?.submitted_at
                    ? dayjs(reportTMP?.submitted_at).format("MM/DD/YYYY")
                    : "-"
                }
              />
              <InfoCard label="Case ID" text={reportTMP?.case_number} />
            </View>

            <Text style={styles.Typography}>Overal Results</Text>
            <View style={styles.InfoRow}>
              <InfoCard
                label="STR allowed?"
                text={
                  reportTMP?.overall_result?.is_str_allowed === null
                    ? "-"
                    : reportTMP?.overall_result?.is_str_allowed
                    ? "Yes"
                    : "No"
                }
                colored
              />
              <InfoCard
                label="Permit required?"
                text={
                  reportTMP?.overall_result?.is_permit_required === null
                    ? "-"
                    : reportTMP?.overall_result?.is_permit_required
                    ? "Yes"
                    : "No"
                }
                colored
                color={
                  reportTMP?.overall_result?.is_permit_required
                    ? COLORS.GREEN05
                    : COLORS.NEUTRAL05
                }
              />
              <InfoCard
                label="Permit compliant?"
                text={
                  reportTMP?.overall_result?.is_permit_compliant === null
                    ? "-"
                    : reportTMP?.overall_result?.is_permit_compliant
                    ? "Yes"
                    : "No"
                }
                colored
              />
            </View>
          </View>
          {jurisdictionsTable?.length ? (
            <>
              <StrTable
                reportTMP={jurisdictionsTable}
                hasPermits={hasPermits}
                permitsExpiredDates={permitExpires}
                isFailed={reportTMP.is_compliant_pass}
              />
              <NotesTable
                reportTMP={jurisdictionsTable}
                permitNotes={permitNotes}
                isNotesExist={isNotesExist}
              />
            </>
          ) : null}

          {!isNotesExist ? (
            <View style={styles.Footer}>
              <Text style={styles.FooterText}>
                The use of this report is only permitted by active customers of
                ADPG LLC (dba Property Guard), and is subject to the Terms of
                Service agreed to by such customer. ADPG LLC disclaims any
                responsibility for use of this report by individuals who have
                not agreed to its Terms of Service
              </Text>
            </View>
          ) : null}
        </View>
      </Page>
    </Document>
  );
};
