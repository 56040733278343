import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

export const changePassword = async (passwords, set) => {
  try {
    set({ isLoading: true });
    await api.put("/users/password/", passwords);
    set({ isLoading: false });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};
