import { TableBody, Typography } from "@mui/material";
import {
  AddressContainer,
  CityContainer,
  CustomErrorTooltipForTable,
  CustomTooltipForTable,
  HeaderCellContainer,
  Owners,
} from "./HomePageTable.styles";
import { LACheckbox } from "../../../components/LACheckbox";
import { LAButton } from "../../../components/LAButton/LAButton";

import singleIcon from "../../../assets/icons/SingleIcon.svg";
import batchIcon from "../../../assets/icons/BatchIcon.svg";
import infoIcon from "../../../assets/icons/WarningV2Icon.svg";
import { Status } from "../../../components/Status/Status";
import useQueryStore from "../../../state/query/query-store";
import { shallow } from "zustand/shallow";
import { Fragment, useMemo } from "react";
import dayjs from "dayjs";
import { QUERY_DATE_FORMAT } from "../../../state/query/state";
import { LATable } from "../../../components/Table/Table";
import {
  LATableRow,
  TableBodyCell,
} from "../../../components/Table/TableRow/TableRow";

export const HomePageTable = ({ handleViewDetails }) => {
  const [queries, selectAll, selectOne, selected, completed] = useQueryStore(
    (state) => [
      state.queries,
      state.selectAll,
      state.selectOne,
      state.selectedQuerries,
      state.completedQuerries,
    ],
    shallow
  );

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const tableHeaders = useMemo(() => {
    return [
      {
        title: "",
        name: "checkbox",
        component: (
          <LACheckbox
            disabled={!completed.length}
            onChange={(e) => selectAll(e.target.checked)}
            checked={
              !!(selected.length === completed.length && selected.length)
            }
          />
        ),
      },
      {
        title: "Case ID",
        name: "case_id",
        isSortable: false,
      },
      {
        title: "Created",
        name: "created_at",
        isSortable: true,
      },
      {
        title: "Type",
        name: "is_single",
        isSortable: true,
      },
      {
        title: "City",
        name: "city",
        isSortable: true,
      },
      {
        title: "State",
        name: "state",
        isSortable: true,
      },
      {
        title: "Address",
        name: "street",
        isSortable: true,
      },
      {
        title: "Name(s)",
        name: "names",
        isSortable: false,
      },
      {
        title: "Status",
        name: "is_completed",
        isSortable: true,
      },
      {
        title: "",
        name: "",
        isSortable: false,
      },
    ];
  }, [selectAll, selected.length, completed.length]);

  return (
    <LATable
      itemsCount={queries.length}
      store={useQueryStore}
      tableHeaders={tableHeaders}
    >
      {!!queries.length ? (
        <TableBody>
          {queries.map((row) => {
            const isItemSelected = isSelected(row.id);
            const splitedNames = row.owner_names.split(",");

            const tooltipNames = splitedNames.slice(1);
            const firstName = splitedNames[0];
            return (
              <LATableRow key={row.id}>
                <TableBodyCell width={40}>
                  <HeaderCellContainer>
                    <LACheckbox
                      disabled={!row.is_completed}
                      checked={isItemSelected}
                      onChange={(e) => selectOne(e.target.checked, row.id)}
                    />
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>{row.case_number}</HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    {dayjs(row.created_at).format(QUERY_DATE_FORMAT)}
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <CustomTooltipForTable
                      placement="bottom"
                      arrow
                      title={
                        <Typography paragraph>
                          {row?.is_single ? "Single" : "Batch"}
                        </Typography>
                      }
                    >
                      <img
                        src={row?.is_single ? singleIcon : batchIcon}
                        alt="file-icon"
                      />
                    </CustomTooltipForTable>
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <CustomTooltipForTable
                      placement="bottom"
                      arrow
                      title={<Typography paragraph>{row.city}</Typography>}
                    >
                      <CityContainer>{row.city}</CityContainer>
                    </CustomTooltipForTable>
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>{row.state}</HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <CustomTooltipForTable
                      placement="bottom"
                      arrow
                      title={<Typography paragraph>{row.street}</Typography>}
                    >
                      <AddressContainer>{row.street}</AddressContainer>
                    </CustomTooltipForTable>
                    {row?.is_zone_restricted && (
                      <CustomErrorTooltipForTable
                        placement="bottom"
                        arrow
                        title={
                          <Typography paragraph>
                            This entity is subject to zoning restrictions!
                          </Typography>
                        }
                      >
                        <img src={infoIcon} alt="info-icon" />
                      </CustomErrorTooltipForTable>
                    )}
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    {firstName ?? "N/A"}
                    {
                      <CustomTooltipForTable
                        placement="bottom"
                        arrow
                        title={
                          <Fragment>
                            {tooltipNames.map((name) => (
                              <Typography paragraph key={name}>
                                {name}
                              </Typography>
                            ))}
                          </Fragment>
                        }
                      >
                        <Owners>
                          {tooltipNames.length ? `+${tooltipNames.length}` : ""}
                        </Owners>
                      </CustomTooltipForTable>
                    }
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <Status
                      status={row.is_completed ? "complete" : "progress"}
                    />
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <LAButton
                      text="View Report"
                      fullWidth
                      size="sm"
                      onClick={() => handleViewDetails(row.id)}
                    />
                  </HeaderCellContainer>
                </TableBodyCell>
              </LATableRow>
            );
          })}
        </TableBody>
      ) : null}
    </LATable>
  );
};
