import { COLORS } from "./colors";

const palette = /** @type {const} */ ({
  primary: {
    main: COLORS.BLUE6,
  },
  background: {
    default: COLORS.NEUTRAL05,
  },
  text: {
    primary: COLORS.NEUTRAL9,
  },
  error: {
    main: COLORS.RED9,
  },
  COLORS,
});

export default palette;
