import styled from "@emotion/styled";
import { Box, Chip, InputBase, MenuItem, Typography } from "@mui/material";
import { elipsedText } from "../../styles";

export const Container = styled(Box)(({ theme, ...props }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  width: "100%",
  filter: props.disabled ? "opacity(40%)" : "none",
  "& .Mui-disabled": {
    backgroundColor: theme.palette.COLORS.NEUTRAL05,
  },
}));

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL7,
  ...theme.typography.body7,
  position: "relative",
}));
export const StyledMenuContainer = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  color: theme.palette.COLORS.NEUTRAL7,
}));

export const PlaceholderLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.body3,
  textAlign: "left",
  position: "absolute",
  top: "50%",
  left: 16,
  transform: "translate(0,-50%)",
  color: theme.palette.COLORS.NEUTRAL5,
}));

export const ErrorLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.RED5,
  position: "absolute",
  top: 48,
  left: 0,
  textAlign: "left",
}));

export const ChipsContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
});
export const SelectedItem = styled(Typography)(({ theme }) => ({
  ...elipsedText,
}));

export const ItemContainer = styled(Box)(({ theme }) => ({
  alignItems: "center",
  gap: theme.spacing(1),
  width: "100%",
  ...elipsedText,
  wordWrap: "break-word",
  ...theme.typography.body7,
  padding: theme.spacing(0.5, 0),
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  height: "29px",
  borderRadius: "8px",
  padding: theme.spacing(0.5, 1),
  border: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  ...theme.typography.body7,
  "& > span": {
    paddingLeft: 0,
    paddingRight: theme.spacing(1.25),
  },
}));

export const StyledSelectInput = styled(({ ...props }) => (
  <SelectInput {...props} />
))(({ theme, size }) => ({
  width: "100%",
  minHeight: size === "small" ? 33 : 44,
  height: size === "small" ? 33 : 44,
  cursor: "pointer",

  "& .MuiInputBase-input": {
    minHeight: size === "small" ? 33 : 44,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    padding: size === "small" ? theme.spacing(0.75, 1) : "",
  },
  "& .MuiSelect-select": {
    minHeight: `${size === "small" ? 33 : 44}px !important`,
    paddingLeft: theme.spacing(size === "small" ? 1 : 2),
  },
}));

export const StyledMenuItem = styled(MenuItem)(
  ({ theme, isSelected, size }) => {
    return {
      ...theme.typography.body2,
      padding: theme.spacing(0, size === "small" ? 1 : 2),
      margin: 0,
      "&.Mui-selected": {
        backgroundColor: theme.palette.COLORS.BLUE05,
      },
      backgroundColor: isSelected
        ? theme.palette.COLORS.BLUE05
        : theme.palette.COLORSNEUTRAL0,
    };
  }
);
export const SearchMenuItem = styled(MenuItem)(({ theme }) => ({
  width: "100%",
  padding: 0,
  margin: 0,
  position: "sticky",
  top: 0,
  backgroundColor: theme.palette.COLORS.NEUTRAL0,
  zIndex: 10,
}));
export const StyledIcon = styled("img")(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: "50%",
  transform: "translate(0, -50%)",
}));

export const SelectInput = styled(InputBase)(({ theme }) => ({
  border: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  borderRadius: "8px",
  overflow: "hidden",
  backgroundColor: theme.palette.COLORS.NEUTRAL05,

  "&.Mui-focused": {
    borderColor: theme.palette.COLORS.BLUE6,
  },
  "&.Mui-error": {
    borderColor: theme.palette.COLORS.RED5,
    "&:focus-within": {
      borderColor: theme.palette.COLORS.RED5,
    },
  },
}));
