import { Navigate, useLocation } from "react-router-dom";
import { shallow } from "zustand/shallow";
import useUserStore from "../../state/user/user-store";

const AuthRouter = ({ children, forAuthed = true }) => {
  const [refresh] = useUserStore((state) => [state.refresh], shallow);
  const location = useLocation();
  if (refresh && forAuthed) {
    return children;
  }
  return <Navigate to="/signin" state={{ referer: location }} replace />;
};

export default AuthRouter;
