import { Box, Typography, styled } from "@mui/material";
import { customScroll } from "../../../styles";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "0 53px 0",
  textAlign: "center",
  maxHeight: 562,
  overflow: "scroll",
  color: theme.palette.COLORS.NEUTRAL9,
  ...customScroll,
  [theme.breakpoints.down("md")]: {
    maxHeight: 462,
  },
}));
export const MainInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(1.5),
  paddingBottom: theme.spacing(2.5),
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
}));
export const AdditionalLaws = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  width: "100%",
  gap: theme.spacing(1.5),
  padding: theme.spacing(2.5, 0),
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
}));
export const UploadedImagesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  width: "100%",
  gap: theme.spacing(1.5),
  padding: theme.spacing(2.5, 0),
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
}));
export const UploadedImages = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing(1.5),
  width: "100%",
}));
export const Footer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: theme.spacing(2.5, 0),
}));
export const ItemRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "start",
  width: "100%",
  gap: theme.spacing(2.5),
}));
export const ItemColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
  width: "50%",
  gap: theme.spacing(1.5),
}));

export const DataContainer = styled(Box)(({ theme }) => ({
  width: "100%",
}));
export const OptionContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  textAlign: "left",
  display: "flex",
}));
export const Versions = styled(Box)(({ theme }) => ({
  width: 150,
  display: "flex",
  position: "absolute",
  top: 68,
  left: 60,
}));
export const ButtonContainer = styled(Box)(({ theme }) => ({
  width: 143,
}));
export const Label = styled(Typography)(({ theme }) => ({
  ...theme.typography.body8,
  color: theme.palette.COLORS.NEUTRAL6,
  lineHeight: "21px",
  wordBreak: "break-word",
}));
export const Info = styled(Typography)(({ theme }) => ({
  ...theme.typography.body7,
  color: theme.palette.COLORS.NEUTRAL9,
  textAlign: "left",
  marginLeft: theme.spacing(1),
  lineHeight: "21px",
}));

export const ImagePreviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxHeight: 144,
  minHeight: 144,
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.COLORS.NEUTRAL1,
  position: "relative",
  cursor: "pointer",
  width: 200,
}));

export const ImagePreview = styled("img")(({ theme }) => ({
  maxHeight: "100%",
  maxWidth: "100%",
}));
