import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const getQueueFinalise = (set) => async (id) => {
  try {
    set({ isLoading: true });

    const response = await api.get(`/queues/${id}/report/`);
    const result = response.data;
    set({
      queueToFinalise: result,
      isLoading: false,
    });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default getQueueFinalise;
