import { Box, Typography, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2.5),
  padding: theme.spacing(0, 28),
  [theme.breakpoints.down("xl-lg")]: {
    padding: theme.spacing(0, 1),
  },
}));
export const PageTitle = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(2.5),
}));

export const PageHeader = styled(Typography)(({ theme }) => ({
  width: "80%",
  ...theme.typography.h2,
  color: theme.palette.COLORS.BLUE9,
}));
