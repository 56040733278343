import { Box, IconButton, Typography, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

export const Title = styled(Typography)(({ theme }) => ({
  width: "100%",
  paddingBottom: theme.spacing(2.5),
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
}));

export const Note = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
}));
export const EditField = styled(Note)(({ theme }) => ({
  gap: theme.spacing(4),
}));

export const NoteInfo = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
}));
export const InputContainer = styled(Box)(() => ({
  width: 756,
}));
export const ButtonContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  width: 149,
}));
export const ButtonModalContainer = styled(Box)(() => ({
  width: 118,
}));
export const ButtonEditContainer = styled(Box)(() => ({
  width: 172,
}));
export const Action = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(1.5),
}));

export const Name = styled(Typography)(({ theme }) => ({
  ...theme.typography.body8,
}));
export const NoNotes = styled(Name)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL5,
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
}));
export const Date = styled(Typography)(({ theme }) => ({
  ...theme.typography.small1,
  color: theme.palette.COLORS.NEUTRAL6,
}));
export const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body7,
}));

export const StyledIconButton = styled(IconButton)(() => ({
  width: 20,
  height: 20,
  borderRadius: 0,
}));
