import { Box, Typography, styled } from "@mui/material";
import { customScroll } from "../../../styles";

export const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  padding: theme.spacing(0, 6.5, 8, 7),
  textAlign: "center",
  maxHeight: 562,
  overflowY: "scroll",
  color: theme.palette.COLORS.NEUTRAL9,
  [theme.breakpoints.down("md")]: {
    maxHeight: 462,
  },
  ...customScroll,
}));

export const Block = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing(4),
  width: "100%",
  paddingBottom: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  "&:nth-child(4)": {
    paddingBottom: theme.spacing(2),
    borderBottom: `none`,
  },
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: 179,
}));

export const OverrideContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
}));

export const FormRow = styled(Box)(({ theme, twoColumn }) => ({
  display: "grid",
  gridTemplateColumns: twoColumn ? "minmax(auto, 50%) 1fr" : "1fr",
  width: "100%",
  gap: theme.spacing(2.5),
}));

export const OverrideButton = styled(Typography)(({ theme, ...props }) => ({
  color: theme.palette.COLORS.BLUE6,
  ...theme.typography.body7,
  textDecoration: "underline",
  cursor: props.disabled ? "default" : "pointer",
  filter: props.disabled && "opacity(40%)",
}));

export const OverridenText = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL9,
  ...theme.typography.body6,
}));

export const CancelButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL5,
  ...theme.typography.body7,
  textDecoration: "underline",
  cursor: "pointer",
}));
