import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const getCounty = (set) => async (data) => {
  try {
    set({ isLoading: true });
    const response = await api.post("/addresses/county/", data);
    set({ isLoading: false, county: response.data });
    return response.data;
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default getCounty;
