import palette from "./palette";
import typography from "./typography";

const baseTheme = {
  palette,
  typography,
  zIndex: {
    snackbar: 1600,
  },
  shape: {
    borderRadius: 8,
  },
  breakpoints: {
    values: {
      xs: 400,
      sm: 768,
      md: 1281,
      lg: 1441,
      "xl-lg": 1700,
      xl: 1921,
    },
  },
};

export default baseTheme;
