import CheckMark from "../../../assets/icons/CheckMark.svg";
import SignInIcon from "../../../components/SignInIcon/SignInIcon";
import {
  ButtonContainer,
  Container,
  Header,
  StyledLink,
  SubText,
} from "./ConfirmPassword.styles";
import { LAButton } from "../../../components/LAButton/LAButton";

const ConfirmPassword = () => {
  return (
    <Container>
      <SignInIcon src={CheckMark} alt="check-icon" />
      <Header variant="h2" align="center">
        Success!
      </Header>
      <SubText variant="subtitle3" component="p" align="center">
        Your password has been reset successfully.
      </SubText>
      <ButtonContainer justifyContent="center">
        <StyledLink to="/signin">
          <LAButton
            text="Back to log in"
            type="button"
            variant="primary"
            data-testid="cancel-reset"
            fullWidth
          />
        </StyledLink>
      </ButtonContainer>
    </Container>
  );
};
export default ConfirmPassword;
