export const updateCurrentFiltersInLocalStore = (get) => () => {
  const { page, sortingField, sortingOrder, searchField, filters } = get();

  localStorage.setItem(
    "queue-filters",
    JSON.stringify({
      page,
      filters,
      sortingField,
      sortingOrder,
      searchField,
    })
  );
};

export const getStateFromLocalStore = (set) => () => {
  const { page, sortingField, sortingOrder, searchField, filters } = JSON.parse(
    localStorage.getItem("queue-filters")
  );
  set({
    page,
    sortingField,
    sortingOrder,
    searchField,
    filters,
  });
};
