import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const addressAutocomplete = (set) => async (address, selected) => {
  const request = {
    query: address,
  };
  if (selected) {
    request.selected = selected;
  }
  try {
    set({ isLoading: true });
    const response = await api.post("/addresses/search/", request);
    set({ isLoading: false, addresses: response.data });
  } catch (error) {
    set({ isLoading: false, addresses: [] });
    throw errorFormater(error.response);
  }
};

export default addressAutocomplete;
