import { Box } from "@mui/material";
import { ModalDialog } from "../../../../components/ModalDialog/ModalDialog";
import { Subtitle } from "../DiscardDraftPopupWarning";
import { ButtonContainer } from "../CreateEntityPopup.styles";
import { LAButton } from "../../../../components/LAButton/LAButton";

export const CreateVersionWarning = ({
  onClose,
  onProceed,
  loading,
  isEditMode,
  loadingPercent,
}) => {
  return (
    <ModalDialog
      title="Confirm Publishing Entity Version"
      onClose={onClose}
      width={712}
    >
      <Box mt={-0.5}>
        <Subtitle>
          {isEditMode
            ? `Are you sure you want to publish this version of the entity? This
            action will update the entity information and apply it to new and
            in-progress queries. Any overrides in in-progress queries will be
            cleared.`
            : `Are you sure you want to publish this version of the entity? This information will be applied to any new queries. However, please note that existing queries in progress will not be automatically updated. Manual intervention will be required to apply the changes to existing queries. Proceed with publishing?`}
        </Subtitle>
        <Box
          display={"flex"}
          gap={2.5}
          width={"100%"}
          justifyContent={"center"}
          mt={5}
          mb={8}
        >
          <ButtonContainer>
            <LAButton
              text="Cancel"
              variant="secondary"
              fullWidth
              onClick={onClose}
              loading={loading}
            />
          </ButtonContainer>
          <ButtonContainer>
            <LAButton
              text="Proceed"
              fullWidth
              onClick={onProceed}
              loading={loading}
              loadingPercent={loadingPercent}
            />
          </ButtonContainer>
        </Box>
      </Box>
    </ModalDialog>
  );
};
