import { shallow } from "zustand/shallow";
import PagesLayout from "../../Layouts/PagesLayout";
import { LAButton } from "../../components/LAButton/LAButton";

import { useEffect, useState } from "react";
import { useDisclosure } from "../../hooks/useDisclosure";
import entityIcon from "../../assets/icons/EntityIcon.svg";
import { Box } from "@mui/material";

import { NoDataFound } from "../HomePage/NoDataFound/NoDataFound";
import LoadingPage from "../LoadingPage/LoadingPage";
import {
  Actions,
  Container,
  HeaderRow,
  PageHeader,
  QueriesCount,
  StyledHeader,
} from "../HomePage/HomePage.styles";

import { SearchContainer } from "./CompliancePage.styles";
import { useDebounceValue } from "../../hooks/useDebouncedValue";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import useEntityStore from "../../state/compliance/compliance-store";
import { ComplianceTable } from "./ComplianceTable/ComplianceTable";
import { CreateEntityPopup } from "./CreateEntityPopup/CreateEntityPopup";
import { EntityViewPopup } from "./EntityViewPopup/EntityViewPopup";
import { errorApiHandler } from "../../services/errorHandler";
import { useNavigate } from "react-router-dom";

const CompliancePage = () => {
  const navigate = useNavigate();
  const [
    totalEntities,
    getList,
    isLoading,
    onSearchChange,
    searchField,
    getEntity,
    resetSorting,
    getComplianceGroup,
    clearState,
  ] = useEntityStore(
    (state) => [
      state.totalEntities,
      state.getList,
      state.isLoading,
      state.onSearchChange,
      state.searchField,
      state.getEntity,
      state.resetSorting,
      state.getComplianceGroup,
      state.clearState,
    ],
    shallow
  );
  const addEntityDialog = useDisclosure();
  const editEntityDialog = useDisclosure();
  const entityView = useDisclosure();

  const [localSearch, setLocalSearch] = useState("");

  const debouncedSearch = useDebounceValue(localSearch);

  useEffect(() => {
    onSearchChange(debouncedSearch);
  }, [debouncedSearch, onSearchChange]);

  useEffect(() => {
    const getData = async () => {
      try {
        await getList();
      } catch (error) {
        errorApiHandler(error, navigate);
      }
    };
    getData();
  }, [getList, searchField, navigate]);

  const handleViewDetails = async (id) => {
    try {
      await getEntity(id);
      await getComplianceGroup(id);
      entityView.open();
    } catch (error) {
      errorApiHandler(error, navigate);
    }
  };
  const handleEdit = async (id) => {
    try {
      await getEntity(id);
      await getComplianceGroup(id);
      editEntityDialog.open();
    } catch (error) {
      errorApiHandler(error, navigate);
    }
  };

  useEffect(() => {
    return () => {
      resetSorting();
    };
  }, [resetSorting]);

  return (
    <PagesLayout>
      <Container>
        <PageHeader>
          <HeaderRow>
            <StyledHeader variant="h1">Compliance Entities</StyledHeader>
          </HeaderRow>
          <HeaderRow>
            <Actions>
              <QueriesCount variant="h3">{totalEntities} entities</QueriesCount>
              <Box>
                <LAButton
                  size="sm"
                  text="Add Entity"
                  icon={<img src={entityIcon} alt="entityIcon" />}
                  onClick={() => {
                    clearState();
                    addEntityDialog.open();
                  }}
                />
              </Box>
            </Actions>
            <SearchContainer>
              <SearchInput value={localSearch} onChange={setLocalSearch} />
            </SearchContainer>
          </HeaderRow>
        </PageHeader>
        <Box>
          {totalEntities ? (
            <ComplianceTable
              handleViewDetails={handleViewDetails}
              handleEdit={handleEdit}
            />
          ) : (
            !isLoading && <NoDataFound />
          )}
        </Box>
      </Container>
      {<LoadingPage isLoading={isLoading} />}
      {addEntityDialog.isOpen ? (
        <CreateEntityPopup
          onClose={() => {
            addEntityDialog.close();
            clearState();
          }}
          editMode={false}
        />
      ) : null}
      {editEntityDialog.isOpen ? (
        <CreateEntityPopup onClose={editEntityDialog.close} editMode={true} />
      ) : null}
      {entityView.isOpen ? (
        <EntityViewPopup
          onClose={entityView.close}
          onCancel={entityView.close}
          onEdit={() => {
            entityView.close();
            editEntityDialog.open();
          }}
        />
      ) : null}
    </PagesLayout>
  );
};

export default CompliancePage;
