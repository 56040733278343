import { LAButton } from "../../../components/LAButton/LAButton";
import {
  StyledForm,
  FormRow,
  InputContainer,
  LabelContainer,
  InputLabel,
  RadioGroupContainer,
  CustomRadioGroup,
  RadioLabel,
  ButtonContainer,
  StyledDivider,
  DragDropContainer,
  ComingSoon,
  UploadFileTitle,
  StyledError,
} from "./AddQueryPopup.styles";
import { Box, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputController } from "../../../components/BaseInput/InputController";
import { defaultQuery, querrySchema } from "./queryScheme";
import { LARadio } from "../../../components/LARadio";
import { LACheckbox } from "../../../components/LACheckbox";
import DragDropFileUpload from "../../../components/DragDropFile/DragDropFile";
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { toast } from "sonner";
import Toaster from "../../../components/Toaster/Toaster";
import useQueryStore from "../../../state/query/query-store";
import { ModalDialog } from "../../../components/ModalDialog/ModalDialog";
import CustomAutocomplete from "../../../components/Autocomplete/Autocomplete";
import useAppStore from "../../../state/app-store/query-store";
import { CustomCircularProgress } from "../../../components/Autocomplete/Autocomplete.styles";
import {
  useDebouncedEffect,
  usePrevious,
} from "../../../hooks/useDebouncedValue";
import { errorApiHandler } from "../../../services/errorHandler";
import { useNavigate } from "react-router-dom";

export const AddQueryPopup = ({ onClose }) => {
  const navigation = useNavigate();
  const [isFileUploadDisabled] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState("");

  const [isLoading, createQuery, getList] = useQueryStore(
    (state) => [state.isLoading, state.createQuery, state.getList],
    shallow
  );
  const [isAppLoading, findAddress, addresses, clearAddresses, getCounty] =
    useAppStore(
      (state) => [
        state.isLoading,
        state.findAddress,
        state.addresses,
        state.clearAddresses,
        state.getCounty,
      ],
      shallow
    );
  const { handleSubmit, control, setError, watch, setValue, trigger } = useForm(
    {
      defaultValues: defaultQuery,
      mode: "onChange",
      resolver: yupResolver(querrySchema),
    }
  );
  const addressQuery = watch("street");

  const previousValue = usePrevious(addressQuery);
  useDebouncedEffect(
    async () => {
      if (addressQuery.length > 1 && addressQuery !== previousValue) {
        try {
          await findAddress(addressQuery, selectedAddress);
          setSelectedAddress("");
        } catch (err) {
          if (err?.query) {
            setError("street", { type: "custom", message: err?.query });
          }
        }
      }
    },
    [addressQuery, selectedAddress],
    700
  );

  const handleRunQuery = async (values) => {
    try {
      await createQuery(values);
      getList();
      toast.custom((t) => (
        <Toaster
          t={t}
          message={"Query has been successfully created."}
          type="info"
        />
      ));
      onClose();
    } catch (err) {
      errorApiHandler(err, navigation, setError);
    }
  };
  const onAddressSelected = async (_, option) => {
    if (option?.title) {
      setValue("street", option.value);
      return;
    }
    if (option) {
      setValue("street", `${option.street} ${option?.secondary}`?.trim());
      if (option?.entries > 1) {
        setSelectedAddress(
          `${option?.street} ${option?.secondary} (${option?.entries}) ${option?.city} ${option?.state} ${option?.zipcode}`
        );
      } else {
        setSelectedAddress("");
        const res = await getCounty(option);
        if (res.county) {
          setValue("county", res.county);
        }
      }
      setValue("state", option.state);
      setValue("postal_code", option.zipcode);
      setValue("city", option.city);

      trigger("state");
      trigger("postal_code");
      trigger("city");
      return;
    }
    setValue("street", "");
  };
  useEffect(() => {
    return () => {
      clearAddresses();
    };
  }, [clearAddresses]);
  return (
    <ModalDialog title="Create Query" onClose={onClose}>
      <StyledForm onSubmit={handleSubmit((values) => handleRunQuery(values))}>
        <FormRow>
          <InputContainer>
            <Controller
              control={control}
              name="street"
              render={({ field: { ref, ...field }, fieldState: { error } }) => {
                return (
                  <CustomAutocomplete
                    refer={ref}
                    label="Address*"
                    name={field.name}
                    options={addresses}
                    onChangeOption={onAddressSelected}
                    showErrorMessage
                    isLoading={isAppLoading}
                    selectedAddress={selectedAddress}
                    clearFunction={() => {
                      clearAddresses();
                      setValue(field.name, "");
                    }}
                    error={error}
                    {...field}
                  />
                );
              }}
            />
          </InputContainer>
          <InputContainer>
            <InputController
              control={control}
              label="City*"
              name="city"
              placeholder="Enter city"
              showErrorMessage
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer>
            <InputController
              control={control}
              label="State*"
              name="state"
              placeholder="Enter state"
              showErrorMessage
            />
          </InputContainer>
          <InputContainer>
            <InputController
              control={control}
              label="Postal code*"
              name="postal_code"
              placeholder="Enter postal code"
              showErrorMessage
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer>
            <InputController
              control={control}
              label="Owner name(s)*"
              name="owner_names"
              placeholder="Enter owner name(s)"
              showErrorMessage
              tooltip="Use a comma between names if more than one"
            />
          </InputContainer>
          <InputContainer>
            <InputController
              control={control}
              label="County"
              name="county"
              placeholder="Enter county"
              disabled={isAppLoading}
              endAdornment={
                <>
                  {isAppLoading ? <CustomCircularProgress size={22} /> : null}
                </>
              }
              showErrorMessage
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <RadioGroupContainer>
            <LabelContainer>
              <InputLabel variant="body7" htmlFor={"is_occupied"}>
                Owner occupied?
              </InputLabel>
            </LabelContainer>
            <Controller
              control={control}
              name="is_occupied"
              render={({ field }) => {
                return (
                  <CustomRadioGroup {...field}>
                    <RadioLabel
                      value="true"
                      control={<LARadio />}
                      label="Yes"
                    />
                    <RadioLabel
                      value="false"
                      control={<LARadio />}
                      label="No"
                    />
                  </CustomRadioGroup>
                );
              }}
            />
          </RadioGroupContainer>
          <RadioGroupContainer>
            <LabelContainer>
              <InputLabel variant="body7" htmlFor={"housing_type"}>
                House type*
              </InputLabel>
            </LabelContainer>
            <Controller
              control={control}
              name="housing_type"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Box justifyContent={"flex-start"} position={"relative"}>
                    <CustomRadioGroup {...field}>
                      <RadioLabel
                        value="House"
                        control={<LARadio />}
                        label="House"
                      />
                      <RadioLabel
                        value="Condo"
                        control={<LARadio />}
                        label="Condo"
                      />
                      <RadioLabel
                        value="Apartment"
                        control={<LARadio />}
                        label="Apartment"
                      />
                    </CustomRadioGroup>
                    <StyledError variant="small1" paragraph>
                      {error?.message}
                    </StyledError>
                  </Box>
                );
              }}
            />
          </RadioGroupContainer>
        </FormRow>
        <FormRow>
          <InputContainer>
            <InputController
              control={control}
              label="Case ID*"
              name="case_number"
              placeholder="Enter Case ID"
              showErrorMessage
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <Controller
            control={control}
            name={`notify_me`}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <RadioLabel
                control={<LACheckbox checked={value} onChange={onChange} />}
                label="Notify me when Query finishes"
              />
            )}
          />
        </FormRow>
        <FormRow justifyContent={"center"}>
          <ButtonContainer>
            <LAButton
              text="Run Query"
              fullWidth
              loading={isLoading}
              type="submit"
              disabled={isLoading}
            />
          </ButtonContainer>
        </FormRow>
        <StyledDivider>
          <Typography variant="body3">OR</Typography>
        </StyledDivider>
        <FormRow>
          <DragDropContainer>
            <UploadFileTitle>
              <Typography variant="body7">CSV file</Typography>
              <ComingSoon variant="body7">COMING SOON!</ComingSoon>
            </UploadFileTitle>

            <DragDropFileUpload
              onFileUpload={() => {}}
              requirementsText={"Max file size - *MB"}
              disabled={isFileUploadDisabled}
            />
          </DragDropContainer>
        </FormRow>
      </StyledForm>
    </ModalDialog>
  );
};
