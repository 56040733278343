import { LAButton } from "../../components/LAButton/LAButton";
import {
  Container,
  PageHeader,
  StyledHeader,
  HeaderRow,
  QueriesCount,
  Actions,
  SearchContainer,
  Filters,
  FiltersCount,
  Xmark,
  FilterInfo,
} from "../HomePage/HomePage.styles";
import { Box } from "@mui/material";
import { useDisclosure } from "../../hooks/useDisclosure";
import { shallow } from "zustand/shallow";
import filterIcon from "../../assets/icons/SortIcon.svg";
import xMark from "../../assets/icons/XmarkIcon.svg";
import { COLORS } from "../../theme/colors";
import { useCallback, useEffect, useMemo } from "react";
import LoadingPage from "../LoadingPage/LoadingPage";
import useQueueStore from "../../state/queue/queue-store";
import { QueuePageTable } from "./QueuePageTable/QueuePageTable";
import { NoDataFound } from "../HomePage/NoDataFound/NoDataFound";
import { StyledDrawer } from "../../components/Header/Header.styles";
import { FiltersMenu } from "./FiltersMenu/FiltersMenu";
import { defaultFilters } from "../../state/queue/state";
import { FinaliseReportPopup } from "./FinaliseReportPopup/FinaliseReportPopup";
import { useNavigate } from "react-router-dom";
import PagesLayout from "../../Layouts/PagesLayout";
import { errorApiHandler } from "../../services/errorHandler";
import { SearchComponent } from "../../components/SeachComponent/SearchComponent";
const QueuePage = () => {
  const [
    totalQueue,
    getList,
    isLoading,
    filters,
    updateFilters,
    getQueueFinalise,
    getQueueDetails,
    resetPageProps,
    updateCurrentFiltersInLocalStore,
  ] = useQueueStore(
    (state) => [
      state.totalQueue,
      state.getList,
      state.isLoading,
      state.filters,
      state.updateFilters,
      state.getQueueFinalise,
      state.getDetails,
      state.resetPageProps,
      state.updateCurrentFiltersInLocalStore,
    ],
    shallow
  );
  const navigation = useNavigate();
  const finaliseReportModal = useDisclosure();

  const filterCounter = useMemo(() => {
    const isTypeUsed = filters.single || filters.batch;
    const isStatusUsed = filters.in_progress || filters.received;
    const isDateUsed = !!filters.date[0] || !!filters.date[1];
    const companies = filters.companies.length > 0;
    return +isTypeUsed + +isStatusUsed + +isDateUsed + +companies;
  }, [filters]);

  const filterMenu = useDisclosure();

  const clearFilters = useCallback(
    async (e, options) => {
      e && e?.stopPropagation();
      updateFilters(defaultFilters, options);
      try {
        await getList();
      } catch (error) {
        errorApiHandler(error, navigation);
      }
    },
    [updateFilters, getList, navigation]
  );

  const handleGetFinaliseReport = async (id) => {
    try {
      await getQueueDetails(id);
      await getQueueFinalise(id);
      finaliseReportModal.open();
    } catch (error) {
      errorApiHandler(error, navigation);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const isFromReport = JSON.parse(localStorage.getItem("prevIsReport"));
        isFromReport ? await getList(true) : await getList();
      } catch (error) {
        errorApiHandler(error, navigation, null, null, null, () => {
          getList();
        });
      }
    };
    getData();
  }, [getList, navigation]);

  useEffect(() => {
    return () => {
      resetPageProps();
    };
  }, [resetPageProps]);
  return (
    <PagesLayout>
      <Container>
        <PageHeader>
          <HeaderRow>
            <StyledHeader variant="h1">Queue</StyledHeader>
          </HeaderRow>
          <HeaderRow>
            <Actions>
              <QueriesCount variant="h3">{totalQueue} queries</QueriesCount>
            </Actions>
            <Filters>
              <SearchContainer>
                <SearchComponent store={useQueueStore} />
              </SearchContainer>
              <LAButton
                variant={"secondary"}
                size="sm"
                text={
                  <FilterInfo>
                    Filter
                    {filterCounter ? (
                      <>
                        <FiltersCount>{filterCounter}</FiltersCount>
                        <Xmark
                          src={xMark}
                          alt="xMarkIcon"
                          onClick={clearFilters}
                        />
                      </>
                    ) : null}
                  </FilterInfo>
                }
                onClick={filterMenu.open}
                icon={<img src={filterIcon} alt="filterIcon" />}
              />
            </Filters>
          </HeaderRow>
        </PageHeader>
        <Box>
          {totalQueue ? (
            <QueuePageTable
              handleFinalise={(id) => {
                handleGetFinaliseReport(id);
              }}
              handleViewDetails={(id) => {
                updateCurrentFiltersInLocalStore();
                navigation(`${id}`);
              }}
            />
          ) : (
            !isLoading && <NoDataFound />
          )}
        </Box>
      </Container>
      <StyledDrawer
        open={filterMenu.isOpen}
        onClose={filterMenu.close}
        anchor="right"
        BackdropProps={{
          style: { backgroundColor: COLORS.NEUTRAL6, opacity: 0.6 },
        }}
      >
        <FiltersMenu onClose={filterMenu.close} />
      </StyledDrawer>
      {<LoadingPage isLoading={isLoading} />}
      {finaliseReportModal.isOpen ? (
        <FinaliseReportPopup onClose={finaliseReportModal.close} />
      ) : null}
    </PagesLayout>
  );
};

export default QueuePage;
