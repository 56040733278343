import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const cancelOverride =
  (set) =>
  async ({ id, type }) => {
    try {
      set({ isLoading: true });
      await api.delete(`/responses/${id}/override/${type}/`);
      set({ isLoading: false });
    } catch (error) {
      set({ isLoading: false });
      throw errorFormater(error.response);
    }
  };

export default cancelOverride;
