import { Backdrop, CircularProgress, Typography, styled } from "@mui/material";
import { transparencyToHex } from "../../styles";

const LoadingPage = ({ isLoading }) => {
  return (
    <StyledBackdrop open={isLoading}>
      <CircularProgress color="primary" />
      <StyledTypography variant="h3">Loading...</StyledTypography>
    </StyledBackdrop>
  );
};

export default LoadingPage;

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  backgroundColor: `${theme.palette.COLORS.NEUTRAL05}${transparencyToHex(0.6)}`,
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.BLUE9,
}));
