import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const deleteImagesFromDraft = (set) => async (existedFiles) => {
  try {
    set({ isLoading: true });

    existedFiles.forEach(async (file) => {
      await api.delete(`/compliances/images/${file.id}/`);
    });

    set({
      isLoading: false,
      imageLoadingPercent: null,
    });
  } catch (error) {
    set({ isLoading: false, imageLoadingPercent: null });
    throw errorFormater(error.response);
  }
};

export default deleteImagesFromDraft;
