import { COLORS } from "../../theme/colors";

export const BUTTON_COLOR = {
  PRIMARY: COLORS.BLUE6,
  SECONDARY: COLORS.NEUTRAL15,
  TRIETARY: "transparent",
};

export const BUTTON_HOVER_COLOR = {
  PRIMARY: COLORS.BLUE5,
  SECONDARY: COLORS.NEUTRAL1,
  TRIETARY: "transparent",
};
export const BUTTON_CLICKED_COLOR = {
  PRIMARY: COLORS.BLUE7,
  SECONDARY: COLORS.NEUTRAL2,
  TRIETARY: "transparent",
};

export const BUTTON_DISABLED_COLOR = {
  PRIMARY: COLORS.NEUTRAL1,
  SECONDARY: COLORS.NEUTRAL05,
  TRIETARY: "transparent",
};

export const TEXT_COLOR = {
  PRIMARY: COLORS.NEUTRAL0,
  SECONDARY: COLORS.NEUTRAL9,
  TRIETARY: COLORS.BLUE7,
};

export const TEXT_HOVER_COLOR = {
  PRIMARY: COLORS.NEUTRAL0,
  SECONDARY: COLORS.NEUTRAL9,
  TRIETARY: COLORS.BLUE6,
};

export const TEXT_CLICKED_COLOR = {
  PRIMARY: COLORS.NEUTRAL0,
  SECONDARY: COLORS.NEUTRAL9,
  TRIETARY: COLORS.BLUE8,
};

export const TEXT_COLOR_DISABLED = {
  PRIMARY: COLORS.NEUTRAL4,
  SECONDARY: COLORS.NEUTRAL5,
  TRIETARY: COLORS.NEUTRAL4,
};

export const BORDER_COLOR = {
  TRIETARY: COLORS.BLUE7,
};

export const BORDER_HOVER_COLOR = {
  TRIETARY: COLORS.BLUE6,
};
export const BORDER_CLICKED_COLOR = {
  TRIETARY: COLORS.BLUE8,
};
export const BORDER_DISABLED_COLOR = {
  TRIETARY: `1px solid ${COLORS.NEUTRAL4}`,
};

export const BORDER_SIZE = {
  PRIMARY: 0,
  SECONDARY: 0,
  TRIETARY: 1,
};

export const BUTTON_HEIGHT = {
  LG: 48,
  SM: 40,
};

export const BUTTON_WIDTH = {
  LG: 224,
  SM: 192,
};

export const BUTTON_ONLY_ICON_WIDTH = {
  LG: 68,
  SM: 68,
};
