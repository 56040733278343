import { Box, TableBody, Typography } from "@mui/material";
import {
  AddressContainer,
  CityContainer,
  CompanyContainer,
} from "./QueuePageTable.styles";
import { LAButton } from "../../../components/LAButton/LAButton";

import singleIcon from "../../../assets/icons/SingleIcon.svg";
import batchIcon from "../../../assets/icons/BatchIcon.svg";
import infoIcon from "../../../assets/icons/WarningV2Icon.svg";

import { Status } from "../../../components/Status/Status";
import { shallow } from "zustand/shallow";
import { useMemo } from "react";
import dayjs from "dayjs";
import { QUERY_DATE_FORMAT } from "../../../state/query/state";
import { LATable } from "../../../components/Table/Table";
import {
  LATableRow,
  TableBodyCell,
} from "../../../components/Table/TableRow/TableRow";
import useQueueStore from "../../../state/queue/queue-store";
import { HeaderCellContainer } from "../../../components/Table/TableHead/TableHead.styles";
import {
  CustomErrorTooltipForTable,
  CustomTooltipForTable,
} from "../../HomePage/HomePageTable/HomePageTable.styles";
import { COLORS } from "../../../theme/colors";

export const QueuePageTable = ({ handleFinalise, handleViewDetails }) => {
  const [queue] = useQueueStore((state) => [state.queue], shallow);

  const tableHeaders = useMemo(() => {
    return [
      {
        title: "Case ID",
        name: "case_id",
        isSortable: false,
      },
      {
        title: "Create date",
        name: "created_at",
        isSortable: true,
      },
      {
        title: "Type",
        name: "is_single",
        isSortable: true,
      },
      {
        title: "Company name",
        name: "organization__name",
        isSortable: true,
      },
      {
        title: "City",
        name: "city",
        isSortable: false,
      },
      {
        title: "State",
        name: "state",
        isSortable: false,
      },
      {
        title: "Address",
        name: "street",
        isSortable: false,
      },
      {
        title: "Status",
        name: "is_queue_received",
        isSortable: true,
      },
      {
        title: "",
        name: "",
        isSortable: false,
      },
    ];
  }, []);

  return (
    <LATable
      itemsCount={queue.length}
      store={useQueueStore}
      tableHeaders={tableHeaders}
    >
      {!!queue.length ? (
        <TableBody>
          {queue.map((row) => {
            return (
              <LATableRow key={row.id}>
                <TableBodyCell align="left">
                  <HeaderCellContainer>{row.case_number}</HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    {dayjs(row.created_at).format(QUERY_DATE_FORMAT)}
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <CustomTooltipForTable
                      placement="bottom"
                      arrow
                      title={
                        <Typography paragraph>
                          {row?.is_single ? "Single" : "Batch"}
                        </Typography>
                      }
                    >
                      <img
                        src={row?.is_single ? singleIcon : batchIcon}
                        alt="file-icon"
                      />
                    </CustomTooltipForTable>
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <CustomTooltipForTable
                      placement="bottom"
                      arrow
                      title={
                        <Typography paragraph>{row.organization}</Typography>
                      }
                    >
                      <CompanyContainer>{row.organization}</CompanyContainer>
                    </CustomTooltipForTable>
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <CustomTooltipForTable
                      placement="bottom"
                      arrow
                      title={<Typography paragraph>{row.city}</Typography>}
                    >
                      <CityContainer>{row.city}</CityContainer>
                    </CustomTooltipForTable>
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>{row.state}</HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <CustomTooltipForTable
                      placement="bottom"
                      arrow
                      title={<Typography paragraph>{row.street}</Typography>}
                    >
                      <AddressContainer>{row.street}</AddressContainer>
                    </CustomTooltipForTable>
                    {row?.is_zone_restricted && (
                      <CustomErrorTooltipForTable
                        placement="bottom"
                        arrow
                        title={
                          <Typography paragraph>
                            This entity is subject to zoning restrictions!
                          </Typography>
                        }
                      >
                        <img src={infoIcon} alt="info-icon" />
                      </CustomErrorTooltipForTable>
                    )}
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <Status
                      status={row?.is_queue_received ? "received" : "progress"}
                    />
                  </HeaderCellContainer>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <HeaderCellContainer>
                    <Box display={"flex"} gap={"20px"} alignItems={"center"}>
                      <LAButton
                        text="View Details"
                        fullWidth
                        size="sm"
                        onClick={() => handleViewDetails(row.id)}
                      />
                      <Typography
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                          color: !row?.is_queue_received
                            ? COLORS.RED6
                            : "inherit",
                        }}
                        variant="body2"
                        onClick={() => handleFinalise(row.id)}
                      >
                        {row?.is_queue_received
                          ? "Finalise Report"
                          : "View Draft"}
                      </Typography>
                    </Box>
                  </HeaderCellContainer>
                </TableBodyCell>
              </LATableRow>
            );
          })}
        </TableBody>
      ) : null}
    </LATable>
  );
};
