import SignInLayout from "../../Layouts/SignInLayout";
import SignInIcon from "../../components/SignInIcon/SignInIcon";
import {
  ButtonContainer,
  CustomForm,
  FormFooter,
  FormGroup,
  Header,
  InputsContainer,
  NewPasswordContent,
} from "./NewPassword.styles";
import * as yup from "yup";

import KeyIcon from "../../assets/icons/KeyIcon.svg";
import { InputController } from "../../components/BaseInput/InputController";
import { useForm } from "react-hook-form";
import { LAButton } from "../../components/LAButton/LAButton";
import { useEffect, useState } from "react";
import ConfirmPassword from "./ConfirmPassword/ConfirmPassword";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import useUserStore from "../../state/user/user-store";
import { shallow } from "zustand/shallow";
import { errorApiHandler } from "../../services/errorHandler";

const resetPasswordFormSchema = yup.object().shape({
  password: yup.string().required("New password is required"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords Do Not match"),
});

const NewPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [confirm, isLoading] = useUserStore(
    (state) => [state.resetPasswordWithToken, state.isLoading],
    shallow
  );
  const [confirmed, setConfirmed] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { isValid },
    trigger,
    setError,
    watch,
  } = useForm({
    defaultValues: {
      password: "",
      passwordConfirm: "",
    },
    mode: "onChange",
    resolver: yupResolver(resetPasswordFormSchema),
  });

  const password = watch("password");

  useEffect(() => {
    trigger("passwordConfirm");
  }, [password, trigger]);

  const handleConfirm = async (values) => {
    try {
      await confirm({
        token,
        password: values.password,
      });
      setConfirmed(true);
    } catch (error) {
      errorApiHandler(error, navigate, setError);
    }
  };

  return (
    <SignInLayout>
      <NewPasswordContent confirmed={confirmed}>
        {confirmed ? (
          <ConfirmPassword />
        ) : (
          <CustomForm
            onSubmit={handleSubmit((values) => handleConfirm(values))}
          >
            <SignInIcon src={KeyIcon} alt="key-icon" />
            <Header variant="h2">Reset password</Header>
            <InputsContainer>
              <FormGroup>
                <InputController
                  control={control}
                  label="New password"
                  name="password"
                  placeholder="*******"
                  type="password"
                  inputProps={{
                    "data-testid": "signin-password-field",
                  }}
                  showErrorMessage
                />
              </FormGroup>
              <FormGroup mt={6}>
                <InputController
                  control={control}
                  label="Confirm password"
                  name="passwordConfirm"
                  placeholder="*******"
                  type="password"
                  inputProps={{
                    "data-testid": "signin-password-field",
                  }}
                  showErrorMessage
                />
              </FormGroup>

              <FormFooter>
                <ButtonContainer justifyContent="center">
                  <LAButton
                    text="Confirm"
                    type="submit"
                    variant="primary"
                    disabled={!isValid}
                    data-testid="signin-submit"
                    fullWidth
                    loading={isLoading}
                  />
                </ButtonContainer>
              </FormFooter>
            </InputsContainer>
          </CustomForm>
        )}
      </NewPasswordContent>
    </SignInLayout>
  );
};

export default NewPassword;
