import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Paper, Typography, styled } from "@mui/material";
import { smallCustomScroll, smallHorizontalCustomScroll } from "../../styles";

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  padding: 0,
}));
export const StyledPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.COLORS.NEUTRAL15}`,
  boxShadow: "none",
  marginTop: theme.spacing(1),
  padding: theme.spacing(2, 0.5, 2, 0),
  "& .MuiAutocomplete-noOptions": {
    padding: theme.spacing(0, 1.5),
  },
  "& .MuiAutocomplete-listbox": {
    ...smallCustomScroll,
    maxHeight: 150,
    padding: 0,
    "& .MuiAutocomplete-option": {
      ...theme.typography.body7,
      padding: theme.spacing(0.5, 2),
    },
  },
}));
export const StyledInput = styled(({ error: _error, ...props }) => (
  <TextField {...props} />
))(({ theme, error }) => ({
  width: "100%",
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.COLORS.NEUTRAL05,
  ...theme.typography.body3,
  textAlign: "left",
  outline: "none",
  color: theme.palette.COLORS.NEUTRAL9,

  "& .MuiAutocomplete-inputRoot": {
    padding: theme.spacing(1, 1.5),
    gap: theme.spacing(1),
    borderColor: theme.palette.COLORS.NEUTRAL2,
    "&.Mui-focused": {
      border: `none`,
      outline: "none",
    },
    "&:not(.Mui-focused):hover fieldset, fieldset": {
      borderColor: theme.palette.COLORS.NEUTRAL2,
      ...(error && { borderColor: theme.palette.COLORS.RED5 }),
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      ...(error && { borderColor: theme.palette.COLORS.RED5 }),
      outline: "none",
    },
    "& .MuiAutocomplete-input": {
      boxSizing: "border-box",
      borderRadius: theme.spacing(1),
      ...theme.typography.body3,
      height: 28,
      backgroundColor: theme.palette.COLORS.NEUTRAL05,
      padding: 0,
      "&::placeholder": {
        color: theme.palette.COLORS.NEUTRAL5,
      },
      "&:-webkit-autofill, input:-webkit-autofill:focus": {
        "-webkit-box-shadow": `0 0 0 1000px ${theme.palette.COLORS.NEUTRAL05} inset`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      padding: theme.spacing(0.5),
    },
  },
}));

export const LabelContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  lineHeight: "21px",
  marginBottom: theme.spacing(1),
  alignItems: "center",
  gap: theme.spacing(1),
}));
export const InputLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL7,
}));

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
export const Chips = styled(Box)(({ theme }) => ({
  maxWidth: "50%",
  display: "flex",
  alignItems: "flex-start",
  gap: theme.spacing(1),
  overflowX: "scroll",
  ...smallHorizontalCustomScroll,
}));
export const ImgContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 32,
  height: 32,
  backgroundColor: theme.palette.COLORS.NEUTRAL1,
  borderRadius: "50%",
  "& img": {
    height: 21,
    width: 21,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body7,
  color: theme.palette.COLORS.NEUTRAL6,
}));

export const StyledIcon = styled("img")(({ theme }) => ({
  cursor: "pointer",
  top: "calc(50% - 6px)",
}));
