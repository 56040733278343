import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const getComplianceGroup = (set) => async (id) => {
  try {
    const response = await api.get(`/compliances/${id}/group/`);

    const result = response.data;

    const draft = result.find((version) => version.version === 0);

    let filteredVersions = result;
    let maxVersion = 1;
    if (draft) {
      filteredVersions = result
        .filter((ver) => ver.version !== 0)
        .toSorted((a, b) => b.version - a.version);
      filteredVersions.unshift(draft);
      set({
        draftId: draft.id,
        currentVersionId: filteredVersions[1].id,
      });
      maxVersion = filteredVersions[1];
    } else {
      filteredVersions = result.toSorted((a, b) => b.version - a.version);
      set({
        draftId: null,
        currentVersionId: filteredVersions[0].id,
      });
      maxVersion = filteredVersions[0];
    }
    set({
      complianceVersions: filteredVersions.map((item) => ({
        label: item.version > 0 ? `Version ${item.version}` : "Draft",
        subTitle: item.version === maxVersion.version ? "(Current)" : "",
        value: item.id,
      })),

      isLoading: false,
    });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default getComplianceGroup;
