import dayjs from "dayjs";
import api from "../../../services/api";
import { QUEUE_PER_PAGE, defaultFilters } from "../state";
import { errorFormater } from "../../../services/errorHandler";

const getList = (set, get) => async (isFiltersFromLocalStore) => {
  const prevIsReport = JSON.parse(localStorage.getItem("prevIsReport"));

  const { page, sortingField, sortingOrder, searchField, filters } =
    isFiltersFromLocalStore && prevIsReport
      ? JSON.parse(localStorage.getItem("queue-filters"))
      : get();
  const sorting =
    sortingField === "default"
      ? ""
      : `${sortingOrder ? "-" : ""}${sortingField}`;

  const is_single = filters.single && !filters.batch;
  const is_batch = !filters.single && filters.batch;

  const is_queue_received = filters.received && !filters.in_progress;
  const is_queue_in_progress = !filters.received && filters.in_progress;

  try {
    set({ isLoading: true });
    const response = await api.get(`/queues/`, {
      params: {
        page,
        order_by: sorting,
        organization: filters.companies.map((i) => i.id).join(", "),
        is_single: is_single || is_batch ? is_single : null,
        is_queue_received:
          is_queue_received || is_queue_in_progress ? is_queue_received : null,
        q: searchField,
        created_at_after: filters.date[0],
        created_at_before: filters.date[1]
          ? new Date(dayjs(filters.date[1]).add(1, "day"))
          : filters.date[1],
      },
    });

    localStorage.setItem("prevIsReport", JSON.stringify(false));

    prevIsReport &&
      set({
        page,
        sortingField,
        sortingOrder,
        searchField,
        filters,
      });

    localStorage.setItem(
      "queue-filters",
      JSON.stringify({
        page: 1,
        sortingField: "created_at",
        sortingOrder: true,
        searchField: "",
        filters: defaultFilters,
      })
    );

    const { count: totalQueue, results } = response.data;
    set({
      totalQueue,
      pageCount: Math.ceil(totalQueue / QUEUE_PER_PAGE),
      queue: results,
      isLoading: false,
    });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default getList;
