import * as yup from "yup";

export const querrySchema = yup.object().shape({
  street: yup.string().required("Please enter address"),
  city: yup.string().required("Please enter city"),
  state: yup.string().required("Please enter state"),
  postal_code: yup.string().required("Please enter postal code"),
  owner_names: yup.string().required("Please enter owner name(s)"),
  county: yup.string(),
  is_occupied: yup.boolean().nullable(),
  housing_type: yup.string().required("Please select an option"),
  case_number: yup.string().required("Please enter case ID"),
  notify_me: yup.boolean(),
});

export const defaultQuery = {
  street: "",
  city: "",
  state: "",
  postal_code: "",
  owner_names: "",
  county: "",
  is_occupied: null,
  housing_type: "",
  case_number: "",
  notify_me: false,
};
