import changePage from "../query/actions/changePage";
import onSearchChange from "../query/actions/onSearchChange";
import { updateFilters } from "../query/actions/updateFilters";
import { updateSorting } from "../query/actions/updateSorting";
import getList from "./actions/GetList";
import cancelOverride from "./actions/cancelOverride";
import createNote from "./actions/createNote";
import deleteNote from "./actions/deleteNote";
import getHomeDetails from "./actions/getHomeDetails";
import getQueueDetails from "./actions/getQueueDetails";
import getQueueFinalise from "./actions/getQueueFinalise";
import overrideEntity from "./actions/overrideEntity";
import { resetPageProps } from "./actions/resetPageProps";
import submitQueue from "./actions/submitQueue";
import {
  getStateFromLocalStore,
  updateCurrentFiltersInLocalStore,
} from "./actions/updateFiltersFromLocalStore";
import updateNote from "./actions/updateNote";

export const createQueueStoreActions = (set, get) => ({
  updateSorting: (field) => updateSorting(field, set, get),
  changePage: changePage(set),
  getList: getList(set, get),
  onSearchChange: (value) => {
    onSearchChange(set)(value);
    getList(set, get)(false);
  },
  updateFilters: updateFilters(set),
  getQueueFinalise: getQueueFinalise(set),
  getDetails: getQueueDetails(set),
  createNote: createNote(set),
  deleteNote: deleteNote(set),
  updateNote: updateNote(set),
  getHomeDetails: getHomeDetails(set),
  submitQueue: submitQueue(set),
  overrideEntity: overrideEntity(set),
  cancelOverride: cancelOverride(set),
  setIdToOverride: (id) => {
    set({
      idToOverride: id,
    });
  },
  resetPageProps: resetPageProps(set),
  updateCurrentFiltersInLocalStore: updateCurrentFiltersInLocalStore(get),
  getStateFromLocalStore: getStateFromLocalStore(set),
});
