import { TableCell, TableRow } from "@mui/material";
import { Box, styled } from "@mui/system";

export const TableHeaderRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.COLORS.NEUTRAL1,
  borderRadius: theme.spacing(0.5),
  "& th:first-of-type": {
    borderRadius: theme.spacing(0.5, 0, 0, 0.5),
    padding: theme.spacing(2, 0, 2, 2.5),
  },
  "& th:last-child": {
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    padding: theme.spacing(2, 2.5),
  },
}));

const ToBeStyledTableCell = ({ sortable: _sortable, ...props }) => (
  <TableCell {...props} />
);
export const TableHeaderCell = styled(ToBeStyledTableCell)(
  ({ sortable, theme }) => ({
    borderBottom: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
    ...theme.typography.body2,
    color: theme.palette.COLORS.NEUTRAL9,
    padding: theme.spacing(2, 0, 2, 2),

    [theme.breakpoints.down("md")]: {
      ...theme.typography.body5,
    },
    cursor: sortable ? "pointer" : "default",
  })
);
export const HeaderCellContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.75),
  width: "100%",
  whiteSpace: "nowrap",
}));
