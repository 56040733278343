import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { COLORS } from "../../../theme/colors";
import { STRLabelsForNotes, notes } from "../../../mock/ReportData";
import { parseTextInPDF } from "../../../utils/linkSearch";

const styles = StyleSheet.create({
  ColumnCell: {
    display: "flex",
    width: "100%",
    color: COLORS.NEUTRAL9,
    padding: "3mm 4mm",
  },
  TypographyBody5: {
    fontSize: 7,
    color: COLORS.NEUTRAL9,
  },
  SubText: {
    fontSize: 5,
    color: COLORS.NEUTRAL9,
    fontWeight: 400,
    marginLeft: 3,
  },
});

export const ColumnCell = ({
  bold = false,
  text,
  empty = false,
  error = false,
  isLast = false,
  subText,
  sx,
  textSx,
}) => {
  return (
    <View
      style={{
        ...styles.ColumnCell,
        backgroundColor: error ? COLORS.RED05 : COLORS.NEUTRAL0,
        borderBottom: isLast ? "none" : `1px solid ${COLORS.NEUTRAL2}`,
        ...sx,
      }}
    >
      <Text
        style={{
          ...styles.TypographyBody5,
          fontWeight: bold ? 600 : 500,
          ...textSx,
        }}
      >
        {empty ? " " : parseTextInPDF(text)}{" "}
        {subText ? <Text style={styles.SubText}>{subText}</Text> : null}
      </Text>
    </View>
  );
};

export const ColumnNoteCell = ({
  item,
  empty = false,
  error = false,
  isLast = false,
  sx,
  textSx,
}) => {
  return (
    <View
      style={{
        ...styles.ColumnCell,
        backgroundColor: error ? COLORS.RED05 : COLORS.NEUTRAL0,
        borderBottom: isLast ? "none" : `1px solid ${COLORS.NEUTRAL2}`,
        ...sx,
      }}
    >
      {notes.map((note) => {
        if (item[1]?.[note]) {
          return (
            <Text
              style={{
                ...styles.TypographyBody5,
                fontWeight: 600,
                ...textSx,
              }}
            >
              {empty ? " " : STRLabelsForNotes?.[note]}{" "}
              <Text style={styles.SubText}>
                {parseTextInPDF(item[1]?.[note])}
              </Text>
            </Text>
          );
        }
        return null;
      })}
    </View>
  );
};
