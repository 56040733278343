import { Typography } from "@mui/material";
import zoomIcon from "../../../assets/icons/ZoomIcon.svg";
import {
  Container,
  ImgContainer,
  RowContainer,
  RowImgContainer,
  RowSubtext,
  Subtext,
  TextContainer,
  Title,
} from "./NoDataFound.styles";

export const NoDataFound = () => {
  return (
    <Container>
      <ImgContainer>
        <img src={zoomIcon} alt={"zoomIcon"} />
      </ImgContainer>
      <TextContainer>
        <Title>No Data Found</Title>
        <Subtext>There is no data available to display at the moment.</Subtext>
      </TextContainer>
    </Container>
  );
};

export const NoDataFoundRow = () => {
  return (
    <RowContainer>
      <RowImgContainer>
        <img src={zoomIcon} alt={"zoomIcon"} />
      </RowImgContainer>
      <TextContainer>
        <Typography variant="h3">No Data Found</Typography>
        <RowSubtext>
          There is no data available to display at the moment.
        </RowSubtext>
      </TextContainer>
    </RowContainer>
  );
};
