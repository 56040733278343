import dayjs from "dayjs";
import { COLORS } from "../theme/colors";

export const jurisdictionLabels = [
  "STR allowed",
  "Permit required",
  "Has permit",
  "Permit expiration date",
  "Date law was added",
];
export const STRLabels = [
  "Is Owner Occupancy Required?",
  "Limit on number or length of stays?",
  "Entity Limits on Permits per Owner?",
  "Entity Limits on Number of Permit?",
  "License Transferability to other Parties?",
  "Are there Zoning Restrictions?",
];

export const STRLabelsForNotes = {
  owner_occupancy_required_notes: "Is Owner Occupancy Required? : ",
  limit_length_stay_notes: "Limit on number or length of stays? : ",
  entity_limits_permit_owner_notes: "Entity Limits on Permits per Owner? : ",
  entity_limits_number_permit_notes: "Entity Limits on Number of Permit? : ",
  license_transferability_notes: "License Transferability to other Parties? : ",
  zone_restriction_notes: "Are there Zoning Restrictions? : ",
  notes: "",
};

export const jurisdictions = {
  state: "State",
  city: "City",
  county: "County",
  hoa: "HOA",
};

export const notes = [
  "owner_occupancy_required_notes",
  "limit_length_stay_notes",
  "entity_limits_permit_owner_notes",
  "entity_limits_number_permit_notes",
  "license_transferability_notes",
  "zone_restriction_notes",
  "notes",
];

export const overallResultsColors = {
  true: COLORS.GREEN05,
  false: COLORS.RED05,
};

export const formatReportFromQueue = (queueToFinalise) => {
  return {
    city: queueToFinalise?.city_compliance
      ? `${queueToFinalise.city_compliance?.name}, ${queueToFinalise.city_compliance?.state}`
      : "",
    county: queueToFinalise.county_compliance
      ? `${queueToFinalise.county_compliance?.name}, ${queueToFinalise.county_compliance?.state}`
      : "",
    state: queueToFinalise.state_compliance?.name || "",
    hoa: queueToFinalise.hoa_compliance
      ? `${queueToFinalise.hoa_compliance?.name}, ${queueToFinalise.hoa_compliance?.state}`
      : "",
    state_compliance: queueToFinalise.state_compliance?.id || "",
    county_compliance: queueToFinalise.county_compliance?.id || "",
    city_compliance: queueToFinalise.city_compliance?.id || "",
    hoa_compliance: queueToFinalise.hoa_compliance?.id || "",
    city_has_permit:
      queueToFinalise.city_has_permit === null
        ? ""
        : queueToFinalise.city_has_permit,
    county_has_permit:
      queueToFinalise.county_has_permit === null
        ? ""
        : queueToFinalise.county_has_permit,
    state_has_permit:
      queueToFinalise.state_has_permit === null
        ? ""
        : queueToFinalise.state_has_permit,
    hoa_has_permit:
      queueToFinalise.hoa_has_permit === null
        ? ""
        : queueToFinalise.hoa_has_permit,
    city_permit_expiry_at: queueToFinalise.city_permit_expiry_at
      ? new Date(dayjs(queueToFinalise.city_permit_expiry_at))
      : "",
    county_permit_expiry_at: queueToFinalise.county_permit_expiry_at
      ? new Date(dayjs(queueToFinalise.county_permit_expiry_at))
      : "",
    state_permit_expiry_at: queueToFinalise.state_permit_expiry_at
      ? new Date(dayjs(queueToFinalise.state_permit_expiry_at))
      : "",
    hoa_permit_expiry_at: queueToFinalise.hoa_permit_expiry_at
      ? new Date(dayjs(queueToFinalise.hoa_permit_expiry_at))
      : "",
    city_notes: queueToFinalise.city_notes || "",
    county_notes: queueToFinalise.county_notes || "",
    state_notes: queueToFinalise.state_notes || "",
    hoa_notes: queueToFinalise.hoa_notes || "",
  };
};

export const formatReportFromResponse = (response) => {
  return {
    city: response?.city
      ? `${response.city?.name}, ${response.city?.state}`
      : "",
    county: response.county
      ? `${response.county?.name}, ${response.county?.state}`
      : "",
    state: response.state?.name || "",
    hoa: response.hoa ? `${response.hoa?.name}, ${response.hoa?.state}` : "",
    state_compliance: response.state?.id || "",
    county_compliance: response.county?.id || "",
    city_compliance: response.city?.id || "",
    hoa_compliance: response.hoa?.id || "",
    city_has_permit:
      response.city_has_permit === null ? "" : response.city_has_permit,
    county_has_permit:
      response.county_has_permit === null ? "" : response.county_has_permit,
    state_has_permit:
      response.state_has_permit === null ? "" : response.state_has_permit,
    hoa_has_permit:
      response.hoa_has_permit === null ? "" : response.hoa_has_permit,
    city_permit_expiry_at: response.city_permit_expiry_at
      ? new Date(dayjs(response.city_permit_expiry_at))
      : "",
    county_permit_expiry_at: response.county_permit_expiry_at
      ? new Date(dayjs(response.county_permit_expiry_at))
      : "",
    state_permit_expiry_at: response.state_permit_expiry_at
      ? new Date(dayjs(response.state_permit_expiry_at))
      : "",
    hoa_permit_expiry_at: response.hoa_permit_expiry_at
      ? new Date(dayjs(response.hoa_permit_expiry_at))
      : "",
    city_notes: response.city_notes || "",
    county_notes: response.county_notes || "",
    state_notes: response.state_notes || "",
    hoa_notes: response.hoa_notes || "",
  };
};

export const compliances = [
  "state_compliance",
  "hoa_compliance",
  "county_compliance",
  "city_compliance",
];
