export const RESPONSES_PER_PAGE = 25;

export const responseInitialData = {
  responses: [],
  totalResponses: 0,
  sortingField: "submitted_at",
  sortingOrder: true,
  searchField: "",
  page: 1,
  pageCount: 1,
  responseId: null,
  responseDetails: null,
  isLoading: false,
};

export const emptyData = {};
