import { Navigate, useLocation } from "react-router-dom";
import { shallow } from "zustand/shallow";
import useUserStore from "../../state/user/user-store";

const PrivateRoute = ({
  children,
  forAuthed = true,
  onlyStaff = false,
  navigateTo,
}) => {
  const [is_staff, id] = useUserStore(
    (state) => [state.is_staff, state.id],
    shallow
  );

  const location = useLocation();
  if (id && forAuthed) {
    if (onlyStaff) {
      return is_staff ? (
        children
      ) : (
        <Navigate to="/*" state={{ error: 404 }} replace />
      );
    }
    return children;
  }
  if (navigateTo && id) {
    return <Navigate to={navigateTo} replace />;
  }
  if (id && !forAuthed) {
    return <Navigate to="/*" state={{ error: 404 }} replace />;
  }
  if (!forAuthed) {
    return children;
  }

  if (typeof window === "undefined") {
    return null;
  }
  if (location.pathname === "/signin") {
    return children;
  }
  return <Navigate to="/signin" state={{ referer: location }} replace />;
};

export default PrivateRoute;
