import { Box, styled } from "@mui/material";
import { elipsedText } from "../../../styles";

export const NameContainer = styled(Box)(({ theme }) => ({
  maxWidth: 421,
  ...elipsedText,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 271,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 218,
  },
}));
export const StateContainer = styled(Box)(({ theme }) => ({
  maxWidth: 244,
  ...elipsedText,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 208,
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 178,
  },
}));
