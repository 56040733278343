import { LAButton } from "../../../components/LAButton/LAButton";
import {
  StyledForm,
  FormRow,
  InputContainer,
  LabelContainer,
  InputLabel,
  RadioGroupContainer,
  CustomRadioGroup,
  RadioLabel,
  ButtonContainer,
  StrLaws,
  ContainerWithNotes,
  DiscardDraftButton,
  DiscardDraft,
  WarningDraft,
  WarningDraftText,
} from "./CreateEntityPopup.styles";
import { Box } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputController } from "../../../components/BaseInput/InputController";
import { defaultEntity, entitySchema } from "./EntityScheme";
import { LARadio } from "../../../components/LARadio";
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { toast } from "sonner";
import Toaster from "../../../components/Toaster/Toaster";
import { ModalDialog } from "../../../components/ModalDialog/ModalDialog";
import { MultiSelect } from "../../../components/MultiSelect/MultiSelect";
import { USStates, USStatesByName, getCodeByLabel } from "../../../mock/States";
import Calendar from "../../../components/CalendarInput/CalenderInput";
import useEntityStore from "../../../state/compliance/compliance-store";
import { StyledError } from "../../HomePage/AddQuerryPopup/AddQueryPopup.styles";
import CustomAutocomplete from "../../../components/Autocomplete/Autocomplete";
import useAppStore from "../../../state/app-store/query-store";
import dayjs from "dayjs";
import trashIcon from "../../../assets/icons/RedTrashSmallIcon.svg";
import InfoIcon from "../../../assets/icons/WarningV2Icon.svg";
import ChangesIcon from "../../../assets/icons/ChangesIndicator.svg";
import {
  useDebouncedEffect,
  usePrevious,
} from "../../../hooks/useDebouncedValue";
import { errorApiHandler } from "../../../services/errorHandler";
import { useNavigate } from "react-router-dom";
import { ImageUploadComponent } from "../ImageUploadComponent/ImageUploadComponent";
import { Versions } from "../EntityViewPopup/EntityViewPopup.styles";
import { useDisclosure } from "../../../hooks/useDisclosure";
import { DiscardDraftPopupWarning } from "./DiscardDraftPopupWarning";
import { EntityViewForEditVersions } from "../EntityViewPopup/EntityVewForEditVersions";
import { CreateVersionWarning } from "./CreateWarnings/CreateVersionWarning";
import { SaveDraftWarning } from "./CreateWarnings/SaveDraftWarning";

const options = [
  {
    label: "City",
    value: "City",
  },
  {
    label: "County",
    value: "County",
  },
  {
    label: "State",
    value: "State",
  },
  {
    label: "HOA",
    value: "HOA",
  },
];
export const yes_no_options = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const isImagesChangedCheck = (existed, current) => {
  return JSON.stringify(existed) !== JSON.stringify(current);
};

const formatEntity = (entity) => {
  const newEntity = {};
  Object.keys(defaultEntity).forEach((key) => {
    newEntity[key] = entity[key];
  });

  newEntity.law_added_at = entity?.law_added_at
    ? new Date(dayjs(entity.law_added_at))
    : null;

  Object.entries(newEntity).forEach(([key, value]) => {
    if (key.startsWith("is_") && value === null) {
      newEntity[key] = "";
    }
  });
  return newEntity;
};
export const CreateEntityPopup = ({ onClose, editMode }) => {
  const navigate = useNavigate();
  const [typeCanBeEdited, setTypeCanBeEdited] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");

  const [
    imageLoadingPercent,
    isLoading,
    createEntity,
    editEntity,
    getList,
    entityToView,
    uploadImagesToCompliance,
    complianceVersions,
    deleteEntityDraft,
    getEntity,
    draftId,
    currentVersionId,
    getComplianceGroup,
  ] = useEntityStore(
    (state) => [
      state.imageLoadingPercent,
      state.isLoading,
      state.createEntity,
      state.editEntity,
      state.getList,
      state.entityToView,
      state.uploadImagesToCompliance,
      state.complianceVersions,
      state.deleteEntityDraft,
      state.getEntity,
      state.draftId,
      state.currentVersionId,
      state.getComplianceGroup,
    ],
    shallow
  );
  const [isAppLoading, findAddress, addresses, clearAddresses, getCounty] =
    useAppStore(
      (state) => [
        state.isLoading,
        state.findAddress,
        state.addresses,
        state.clearAddresses,
        state.getCounty,
      ],
      shallow
    );
  const {
    handleSubmit,
    control,
    setError,
    watch,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: defaultEntity,
    mode: "onChange",
    resolver: yupResolver(entitySchema),
  });
  const [currentFiles, setCurrentFiles] = useState(
    editMode ? entityToView?.images : []
  );
  const [isImagesChanged, setIsImagesChanged] = useState(false);
  const [isDiscardLoading, setIsDiscardLoading] = useState(false);
  useEffect(() => {
    setIsImagesChanged(isImagesChangedCheck(getValues("images"), currentFiles));
  }, [currentFiles, setIsImagesChanged, getValues]);

  const [loadingEntity, setLoadingEntity] = useState(false);
  const [isCurrentVersionWithDraft, setIsCurrentVersionWithDraft] =
    useState(false);

  const [selectedVersion, setSelectedVersion] = useState(
    editMode ? entityToView?.id : null
  );
  const { type, is_str_allowed, address } = watch();

  const handleRunQuery = async (values) => {
    try {
      if (editMode) {
        const res = await editEntity(values, entityToView?.id);
        if (res.id === entityToView?.id) {
          await uploadImagesToCompliance(
            res?.id,
            getValues("images") || [],
            currentFiles
          );
        } else {
          await uploadImagesToCompliance(
            res?.id || entityToView?.id,
            getValues("images") || [],
            currentFiles,
            res?.id
          );
        }
      } else {
        const res = await createEntity(values);
        await uploadImagesToCompliance(
          res?.id,
          getValues("images") || [],
          currentFiles
        );
      }
      await getList();
      saveVersionWarningDialog.close();
      toast.custom((t) => (
        <Toaster
          t={t}
          message={
            editMode
              ? "Entity has been successfully edited."
              : "Entity has been successfully added."
          }
          type="info"
        />
      ));
      onClose();
    } catch (error) {
      errorApiHandler(error, navigate, setError);
    }
  };

  const saveDraft = async () => {
    try {
      if (editMode) {
        const res = await editEntity(getValues(), entityToView?.id, true);
        await uploadImagesToCompliance(
          res?.id,
          getValues("images") || [],
          currentFiles,
          !draftId
        );
      } else {
        const res = await createEntity(getValues(), true);
        await uploadImagesToCompliance(
          res?.id,
          getValues("images") || [],
          currentFiles,
          !draftId
        );
      }

      await getList();
      saveDraftWarningDialog.close();
      toast.custom((t) => (
        <Toaster
          t={t}
          message={
            editMode
              ? "Entity draft has been successfully edited."
              : "Entity draft has been successfully added."
          }
          type="info"
        />
      ));
      onClose();
    } catch (error) {
      errorApiHandler(error, navigate, setError);
    }
  };

  const discardCompliance = async () => {
    try {
      setIsDiscardLoading(true);
      await deleteEntityDraft(draftId);
      setSelectedVersion(currentVersionId);
      await getComplianceGroup(currentVersionId);
      await getEntity(currentVersionId);
      await getList();

      setIsDiscardLoading(false);
      discardWarningDialog.close();
    } catch (error) {
      errorApiHandler(error, navigate, setError);
    }
  };

  useEffect(() => {
    if (editMode && type === entityToView.type && !typeCanBeEdited) {
      setTypeCanBeEdited(true);
      return;
    }
    reset({
      ...getValues(),
      name: "",
      state: "",
      address: "",
    });
    clearAddresses();
    // eslint-disable-next-line
  }, [type, reset, getValues, entityToView, editMode]);

  useEffect(() => {
    if (is_str_allowed === "false") {
      setValue("is_permit_required", "");
    }
  }, [is_str_allowed, reset, setValue]);
  useEffect(() => {
    if (entityToView) {
      reset(formatEntity(entityToView));
      setCurrentFiles(entityToView.images);
    }
  }, [entityToView, reset]);

  const previousValue = usePrevious(address);

  const discardWarningDialog = useDisclosure();
  const saveDraftWarningDialog = useDisclosure();
  const saveVersionWarningDialog = useDisclosure();
  useDebouncedEffect(
    () => {
      if (address?.length > 1 && address !== previousValue) {
        findAddress(address, selectedAddress);
        setSelectedAddress("");
      }
    },
    [address, selectedAddress],
    700
  );

  useEffect(() => {
    const firstErrorKey = Object.keys(errors).find((key) => errors[key]);
    if (firstErrorKey) {
      document.querySelector(`input[name="${firstErrorKey}"]`)?.focus();
    }
  }, [errors]);

  const onAddressSelected = async (_, option) => {
    if (option?.title) {
      setValue("address", option.value);
      return;
    }
    if (option) {
      setValue("address", `${option.street} ${option?.secondary}`?.trim());

      if (option?.entries > 1) {
        setSelectedAddress(
          `${option?.street} ${option?.secondary} (${option?.entries}) ${option?.city} ${option?.state} ${option?.zipcode}`
        );
      } else {
        setSelectedAddress("");
        if (type === "County") {
          const res = await getCounty(option);
          if (res.county) {
            setValue("name", res.county);
            setValue("state", option.state);
          }
        } else {
          setValue("name", option.city);
          setValue("state", option.state);
        }
        trigger("state");
        trigger("name");
      }

      return;
    }
    setValue("address", "");
  };

  const onSubmitDraft = () => {
    saveDraftWarningDialog.open();
  };
  const onSubmitVersion = () => {
    saveVersionWarningDialog.open();
  };

  useEffect(() => {
    return () => {
      clearAddresses();
    };
  }, [clearAddresses]);

  const renderFromTypeFirstRow = (type) => {
    switch (type) {
      case "HOA": {
        return (
          <InputContainer disabled={editMode}>
            <InputController
              control={control}
              label="Name*"
              name="name"
              placeholder="Enter name"
              showErrorMessage
              disabled={editMode}
            />
          </InputContainer>
        );
      }
      case "State": {
        return (
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error } }) => {
              return (
                <MultiSelect
                  multiple={false}
                  options={USStatesByName}
                  label="Name*"
                  noItemsSelectedLabel="Enter name"
                  {...field}
                  onChange={(e) => {
                    setValue(field.name, e.target.value);
                    setValue("state", getCodeByLabel(e.target.value));
                  }}
                  showErrorMessage
                  error={error}
                  disabled={editMode}
                />
              );
            }}
          />
        );
      }
      case "": {
        return null;
      }
      default: {
        return (
          <InputContainer disabled={editMode}>
            <Controller
              control={control}
              name="address"
              render={({ field: { ref, ...field }, fieldState: { error } }) => {
                return (
                  <CustomAutocomplete
                    refer={ref}
                    label="Address*"
                    name="address"
                    options={addresses}
                    onChangeOption={onAddressSelected}
                    showErrorMessage
                    isLoading={isAppLoading}
                    selectedAddress={selectedAddress}
                    clearFunction={() => {
                      clearAddresses();
                      setValue(field.name, "");
                    }}
                    error={error}
                    disabled={editMode}
                    {...field}
                  />
                );
              }}
            />
          </InputContainer>
        );
      }
    }
  };
  const renderFromTypeSecondRow = (type) => {
    switch (type) {
      case "HOA": {
        return (
          <FormRow>
            <Controller
              control={control}
              name="state"
              render={({ field, fieldState: { error } }) => {
                return (
                  <MultiSelect
                    multiple={false}
                    options={USStates}
                    label="State*"
                    noItemsSelectedLabel="Enter state"
                    showErrorMessage
                    error={error}
                    disabled={editMode}
                    {...field}
                  />
                );
              }}
            />
            <Box width={"100%"} />
          </FormRow>
        );
      }
      case "State": {
        return (
          <FormRow>
            <InputContainer disabled={editMode}>
              <InputController
                control={control}
                label="State*"
                name="state"
                placeholder="Enter state"
                disabled
                showErrorMessage
              />
            </InputContainer>
            <Box width={"100%"} />
          </FormRow>
        );
      }
      case "": {
        return null;
      }
      default: {
        return (
          <FormRow>
            <InputContainer disabled={editMode}>
              <InputController
                control={control}
                label="Name*"
                name="name"
                placeholder="Enter name"
                showErrorMessage
                disabled={editMode}
              />
            </InputContainer>
            <InputContainer disabled={editMode}>
              <InputController
                control={control}
                label="State*"
                name="state"
                placeholder="Enter state"
                showErrorMessage
                disabled={editMode}
              />
            </InputContainer>
          </FormRow>
        );
      }
    }
  };
  return (
    <ModalDialog
      title={
        selectedVersion !== draftId && selectedVersion !== currentVersionId
          ? "Entity Details"
          : editMode
          ? "Edit Entity"
          : "Add Entity"
      }
      onClose={onClose}
      additionalTopHeader={
        editMode
          ? `${
              entityToView?.created_at
                ? `Created: [${dayjs(entityToView?.created_at).format(
                    "MM/DD/YYYY"
                  )}]`
                : ""
            } ${
              entityToView?.updated_at
                ? `Updated: [${dayjs(entityToView?.updated_at).format(
                    "MM/DD/YYYY"
                  )}]`
                : ""
            }`
          : null
      }
      width={
        selectedVersion !== draftId && selectedVersion !== currentVersionId
          ? 752
          : ""
      }
      isLoading={loadingEntity}
      subheader={
        currentVersionId === selectedVersion && draftId ? (
          <WarningDraft>
            <img src={InfoIcon} alt="warning-icon" />
            <WarningDraftText variant="body4">
              You can't modify this version because a draft is in progress
            </WarningDraftText>
          </WarningDraft>
        ) : null
      }
    >
      {complianceVersions.length > 1 ? (
        <Versions>
          <MultiSelect
            multiple={false}
            options={complianceVersions}
            showErrorMessage
            size="small"
            value={selectedVersion}
            onChange={async (value) => {
              setLoadingEntity(true);
              await getEntity(value.target.value);
              setSelectedVersion(value.target.value);
              setIsCurrentVersionWithDraft(
                !!draftId && value.target.value === currentVersionId
              );
              setLoadingEntity(false);
            }}
          />
        </Versions>
      ) : null}
      {draftId && draftId === selectedVersion ? (
        <DiscardDraft
          onClick={() => {
            discardWarningDialog.open();
          }}
        >
          <img src={trashIcon} alt="trash-icon" />
          <DiscardDraftButton variant="body4">Discard draft</DiscardDraftButton>
        </DiscardDraft>
      ) : null}

      {selectedVersion !== draftId && selectedVersion !== currentVersionId ? (
        <EntityViewForEditVersions entityToView={entityToView} />
      ) : (
        <StyledForm onSubmit={handleSubmit((values) => onSubmitVersion())}>
          {/* <StyledForm onSubmit={handleSubmit((values) => handleRunQuery(values))}> */}
          <FormRow>
            <Controller
              control={control}
              name="type"
              render={({ field, fieldState: { error } }) => {
                return (
                  <MultiSelect
                    multiple={false}
                    options={options}
                    label="Entity type*"
                    noItemsSelectedLabel="Select entity type"
                    showErrorMessage
                    error={error}
                    disabled={editMode}
                    {...field}
                  />
                );
              }}
            />
            <Box width={"100%"}>{renderFromTypeFirstRow(type)}</Box>
          </FormRow>
          {renderFromTypeSecondRow(type)}
          <FormRow>
            <RadioGroupContainer disabled={isCurrentVersionWithDraft}>
              <LabelContainer>
                <InputLabel variant="body7" htmlFor={"is_str_allowed"}>
                  STR allowed?*
                </InputLabel>
                {!draftId && entityToView?.is_str_allowed_changed ? (
                  <img src={ChangesIcon} alt="change-icon" />
                ) : null}
              </LabelContainer>
              <Controller
                control={control}
                name="is_str_allowed"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Box justifyContent={"flex-start"} position={"relative"}>
                      <CustomRadioGroup {...field}>
                        <RadioLabel
                          value={true}
                          control={<LARadio />}
                          label="Yes"
                        />
                        <RadioLabel
                          value={false}
                          control={<LARadio />}
                          label="No"
                        />
                        <StyledError variant="small1" paragraph>
                          {error?.message}
                        </StyledError>
                      </CustomRadioGroup>
                    </Box>
                  );
                }}
              />
            </RadioGroupContainer>
            {is_str_allowed === "true" || is_str_allowed === true ? (
              <RadioGroupContainer disabled={isCurrentVersionWithDraft}>
                <LabelContainer>
                  <InputLabel variant="body7" htmlFor={"is_permit_required"}>
                    Permit required?*
                  </InputLabel>
                  {!draftId && entityToView?.is_permit_required_changed ? (
                    <img src={ChangesIcon} alt="change-icon" />
                  ) : null}
                </LabelContainer>
                <Controller
                  control={control}
                  name="is_permit_required"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <Box justifyContent={"flex-start"} position={"relative"}>
                        <CustomRadioGroup {...field}>
                          <RadioLabel
                            value="true"
                            control={<LARadio />}
                            label="Yes"
                          />
                          <RadioLabel
                            value="false"
                            control={<LARadio />}
                            label="No"
                          />
                          <StyledError variant="small1" paragraph>
                            {error?.message}
                          </StyledError>
                        </CustomRadioGroup>
                      </Box>
                    );
                  }}
                />
              </RadioGroupContainer>
            ) : null}
          </FormRow>
          <FormRow>
            <InputContainer disabled={isCurrentVersionWithDraft}>
              <InputController
                control={control}
                label="Legislation name"
                name="legislation_name"
                placeholder="Enter legislation name"
                showErrorMessage
                disabled={isCurrentVersionWithDraft}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer disabled={isCurrentVersionWithDraft}>
              <InputController
                control={control}
                label="Legislation website"
                name="legislation_web_url"
                placeholder="Enter legislation website link"
                showErrorMessage
                disabled={isCurrentVersionWithDraft}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer disabled={isCurrentVersionWithDraft}>
              <InputController
                control={control}
                label="Legislation file upload"
                name="legislation_upload_url"
                placeholder="Enter legislation link"
                showErrorMessage
                disabled={isCurrentVersionWithDraft}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <ImageUploadComponent
              currentFiles={currentFiles}
              setCurrentFiles={setCurrentFiles}
              disabled={isCurrentVersionWithDraft}
            />
          </FormRow>
          <FormRow>
            <InputContainer disabled={isCurrentVersionWithDraft}>
              <InputController
                control={control}
                label="Contact name"
                name="contact_name"
                placeholder="Enter contact name"
                showErrorMessage
                disabled={isCurrentVersionWithDraft}
              />
            </InputContainer>
            <InputContainer disabled={isCurrentVersionWithDraft}>
              <InputController
                control={control}
                label="Contact email"
                name="contact_email"
                placeholder="Enter contact email"
                showErrorMessage
                disabled={isCurrentVersionWithDraft}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer disabled={isCurrentVersionWithDraft}>
              <InputController
                control={control}
                label="Contact phone"
                name="contact_phone"
                placeholder="Enter contact phone"
                showErrorMessage
                disabled={isCurrentVersionWithDraft}
              />
            </InputContainer>
            <InputContainer>
              <Controller
                control={control}
                name="law_added_at"
                render={({ field }) => (
                  <Calendar
                    label="Date law was added"
                    placeholder="MM/DD/YYYY"
                    startDate={field.value}
                    selectsRange={false}
                    {...field}
                    disabled={isCurrentVersionWithDraft}
                  />
                )}
              />
            </InputContainer>
          </FormRow>
          <StrLaws>Additional STR Laws</StrLaws>
          <FormRow>
            <ContainerWithNotes>
              <Box>
                <Controller
                  control={control}
                  name="is_owner_occupancy_required"
                  render={({ field }) => {
                    return (
                      <MultiSelect
                        multiple={false}
                        options={yes_no_options}
                        label="Is Owner Occupancy Required?"
                        labelIcon={
                          !draftId && entityToView?.is_owner_occupancy_changed
                            ? ChangesIcon
                            : null
                        }
                        noItemsSelectedLabel="Select an option"
                        canClear
                        {...field}
                        onClear={() => {
                          setValue(field.name, "", { shouldDirty: true });
                          setValue("owner_occupancy_required_notes", "", {
                            shouldDirty: true,
                          });
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          if (!e.target.value) {
                            setValue("owner_occupancy_required_notes", "", {
                              shouldDirty: true,
                            });
                          }
                        }}
                        disabled={isCurrentVersionWithDraft}
                      />
                    );
                  }}
                />
              </Box>
              {watch("is_owner_occupancy_required") === true ? (
                <InputContainer disabled={isCurrentVersionWithDraft}>
                  <InputController
                    control={control}
                    label="Notes"
                    name="owner_occupancy_required_notes"
                    placeholder="Enter notes"
                    multiline
                    showErrorMessage
                    disabled={isCurrentVersionWithDraft}
                  />
                </InputContainer>
              ) : null}
            </ContainerWithNotes>
            <ContainerWithNotes>
              <Box>
                <Controller
                  control={control}
                  name="is_entity_limits_number_permit"
                  render={({ field }) => {
                    return (
                      <MultiSelect
                        multiple={false}
                        options={yes_no_options}
                        label="Entity Limits on Number of Permit?"
                        labelIcon={
                          !draftId &&
                          entityToView?.is_entity_limits_number_changed
                            ? ChangesIcon
                            : null
                        }
                        noItemsSelectedLabel="Select an option"
                        canClear
                        {...field}
                        onClear={() => {
                          setValue(field.name, "", { shouldDirty: true });
                          setValue("entity_limits_number_permit_notes", "", {
                            shouldDirty: true,
                          });
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          if (!e.target.value) {
                            setValue("entity_limits_number_permit_notes", "", {
                              shouldDirty: true,
                            });
                          }
                        }}
                        disabled={isCurrentVersionWithDraft}
                      />
                    );
                  }}
                />
              </Box>
              {watch("is_entity_limits_number_permit") === true ? (
                <InputContainer disabled={isCurrentVersionWithDraft}>
                  <InputController
                    control={control}
                    label="Notes"
                    name="entity_limits_number_permit_notes"
                    placeholder="Enter notes"
                    multiline
                    showErrorMessage
                    disabled={isCurrentVersionWithDraft}
                  />
                </InputContainer>
              ) : null}
            </ContainerWithNotes>
          </FormRow>
          <FormRow>
            <ContainerWithNotes>
              <Box>
                <Controller
                  control={control}
                  name="is_limit_length_stay"
                  render={({ field }) => {
                    return (
                      <MultiSelect
                        multiple={false}
                        options={yes_no_options}
                        label="Limit on number or length of stays?"
                        labelIcon={
                          !draftId && entityToView?.is_limit_length_changed
                            ? ChangesIcon
                            : null
                        }
                        noItemsSelectedLabel="Select an option"
                        canClear
                        {...field}
                        onClear={() => {
                          setValue(field.name, "", { shouldDirty: true });
                          setValue("limit_length_stay_notes", "", {
                            shouldDirty: true,
                          });
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          if (!e.target.value) {
                            setValue("limit_length_stay_notes", "", {
                              shouldDirty: true,
                            });
                          }
                        }}
                        disabled={isCurrentVersionWithDraft}
                      />
                    );
                  }}
                />
              </Box>
              {watch("is_limit_length_stay") === true ? (
                <InputContainer disabled={isCurrentVersionWithDraft}>
                  <InputController
                    control={control}
                    label="Notes"
                    name="limit_length_stay_notes"
                    placeholder="Enter notes"
                    multiline
                    showErrorMessage
                  />
                </InputContainer>
              ) : null}
            </ContainerWithNotes>
            <ContainerWithNotes>
              <Box>
                <Controller
                  control={control}
                  name="is_license_transferability"
                  render={({ field }) => {
                    return (
                      <MultiSelect
                        multiple={false}
                        options={yes_no_options}
                        label="License Transferability to other Parties?"
                        labelIcon={
                          !draftId && entityToView?.is_license_changed
                            ? ChangesIcon
                            : null
                        }
                        noItemsSelectedLabel="Select an option"
                        canClear
                        {...field}
                        onClear={() => {
                          setValue(field.name, "");
                          setValue("license_transferability_notes", "", {
                            shouldDirty: true,
                          });
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          if (!e.target.value) {
                            setValue("license_transferability_notes", "", {
                              shouldDirty: true,
                            });
                          }
                        }}
                        disabled={isCurrentVersionWithDraft}
                      />
                    );
                  }}
                />
              </Box>
              {watch("is_license_transferability") === true ? (
                <InputContainer disabled={isCurrentVersionWithDraft}>
                  <InputController
                    control={control}
                    label="Notes"
                    name="license_transferability_notes"
                    placeholder="Enter notes"
                    multiline
                    showErrorMessage
                  />
                </InputContainer>
              ) : null}
            </ContainerWithNotes>
          </FormRow>
          <FormRow>
            <ContainerWithNotes>
              <Box>
                <Controller
                  control={control}
                  name="is_entity_limits_permit_owner"
                  render={({ field }) => {
                    return (
                      <MultiSelect
                        multiple={false}
                        options={yes_no_options}
                        label="Entity Limits on Permits per Owner?"
                        labelIcon={
                          !draftId &&
                          entityToView?.is_entity_limits_permit_changed
                            ? ChangesIcon
                            : null
                        }
                        noItemsSelectedLabel="Select an option"
                        canClear
                        {...field}
                        onClear={() => {
                          setValue(field.name, "");
                          setValue("entity_limits_permit_owner_notes", "", {
                            shouldDirty: true,
                          });
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          if (!e.target.value) {
                            setValue("entity_limits_permit_owner_notes", "", {
                              shouldDirty: true,
                            });
                          }
                        }}
                        disabled={isCurrentVersionWithDraft}
                      />
                    );
                  }}
                />
              </Box>
              {watch("is_entity_limits_permit_owner") === true ? (
                <InputContainer disabled={isCurrentVersionWithDraft}>
                  <InputController
                    control={control}
                    label="Notes"
                    name="entity_limits_permit_owner_notes"
                    placeholder="Enter notes"
                    multiline
                    showErrorMessage
                  />
                </InputContainer>
              ) : null}
            </ContainerWithNotes>
            <ContainerWithNotes>
              <Box>
                <Controller
                  control={control}
                  name="is_zone_restricted"
                  render={({ field }) => {
                    return (
                      <MultiSelect
                        multiple={false}
                        options={yes_no_options}
                        label="Are there Zoning Restrictions?"
                        labelIcon={
                          !draftId && entityToView?.is_zone_changed
                            ? ChangesIcon
                            : null
                        }
                        noItemsSelectedLabel="Select an option"
                        canClear
                        {...field}
                        onClear={() => {
                          setValue(field.name, "");
                          setValue("zone_restriction_notes", "", {
                            shouldDirty: true,
                          });
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          if (!e.target.value) {
                            setValue("zone_restriction_notes", "", {
                              shouldDirty: true,
                            });
                          }
                        }}
                        disabled={isCurrentVersionWithDraft}
                      />
                    );
                  }}
                />
              </Box>
              {watch("is_zone_restricted") === true ? (
                <InputContainer disabled={isCurrentVersionWithDraft}>
                  <InputController
                    control={control}
                    label="Notes"
                    name="zone_restriction_notes"
                    placeholder="Enter notes"
                    multiline
                    showErrorMessage
                  />
                </InputContainer>
              ) : null}
            </ContainerWithNotes>
          </FormRow>
          <FormRow>
            <InputContainer disabled={isCurrentVersionWithDraft}>
              <InputController
                control={control}
                label="Notes"
                name="notes"
                labelIcon={
                  !draftId && entityToView?.is_notes_changed
                    ? ChangesIcon
                    : null
                }
                placeholder="Enter notes"
                multiline
                showErrorMessage
              />
            </InputContainer>
          </FormRow>
          <FormRow justifyContent={"center"}>
            {draftId === selectedVersion && editMode ? (
              <>
                <ButtonContainer>
                  <LAButton
                    text="Update"
                    variant="secondary"
                    type="submit"
                    onClick={handleSubmit(onSubmitDraft)}
                    fullWidth
                    loading={isLoading}
                    disabled={isLoading}
                  />
                </ButtonContainer>
                <ButtonContainer>
                  <LAButton
                    text={"Publish"}
                    fullWidth
                    loading={isLoading}
                    onClick={handleSubmit(onSubmitVersion)}
                    loadingPercent={imageLoadingPercent}
                    disabled={isLoading}
                  />
                </ButtonContainer>
              </>
            ) : null}
            {isCurrentVersionWithDraft || draftId === selectedVersion ? null : (
              <>
                <ButtonContainer>
                  {editMode ? (
                    <LAButton
                      text="Save as a draft"
                      variant="secondary"
                      onClick={handleSubmit(onSubmitDraft)}
                      fullWidth
                      loading={isLoading}
                      disabled={isLoading}
                    />
                  ) : (
                    <LAButton
                      text="Cancel"
                      variant="secondary"
                      onClick={onClose}
                      fullWidth
                      loading={isLoading}
                      disabled={isLoading}
                    />
                  )}
                </ButtonContainer>
                <ButtonContainer>
                  <LAButton
                    text={editMode ? "Publish" : "Publish Entity"}
                    fullWidth
                    loading={isLoading}
                    onClick={handleSubmit(onSubmitVersion)}
                    loadingPercent={imageLoadingPercent}
                    disabled={isLoading || (!isDirty && !isImagesChanged)}
                  />
                </ButtonContainer>
              </>
            )}
            {!editMode ? (
              <>
                <ButtonContainer>
                  <LAButton
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                    fullWidth
                    loading={isLoading}
                    disabled={isLoading}
                  />
                </ButtonContainer>
                <ButtonContainer>
                  <LAButton
                    text={"Publish Entity"}
                    fullWidth
                    onClick={handleSubmit(onSubmitVersion)}
                    loadingPercent={imageLoadingPercent}
                    disabled={isLoading || (!isDirty && !isImagesChanged)}
                  />
                </ButtonContainer>
              </>
            ) : null}
          </FormRow>
        </StyledForm>
      )}
      {discardWarningDialog.isOpen ? (
        <DiscardDraftPopupWarning
          onClose={discardWarningDialog.close}
          onDiscard={discardCompliance}
          loading={isDiscardLoading}
        />
      ) : null}
      {saveDraftWarningDialog.isOpen ? (
        <SaveDraftWarning
          onClose={saveDraftWarningDialog.close}
          onProceed={saveDraft}
          loadingPercent={imageLoadingPercent}
          loading={isLoading}
        />
      ) : null}
      {saveVersionWarningDialog.isOpen ? (
        <CreateVersionWarning
          onClose={saveVersionWarningDialog.close}
          loadingPercent={imageLoadingPercent}
          loading={isLoading}
          onProceed={handleSubmit((values) => handleRunQuery(values))}
          isEditMode={editMode}
        />
      ) : null}
    </ModalDialog>
  );
};
