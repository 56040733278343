import { Box, IconButton, Typography, styled } from "@mui/material";
import DatePicker from "react-datepicker";

import xMark from "../../assets/icons/XmarkIcon.svg";

export const InputLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.NEUTRAL7,
  marginBottom: theme.spacing(1),
  textAlign: "left",
}));

export const CustomDatePicker = styled(DatePicker)(({ theme, ...props }) => ({
  width: "100%",
  height: 44,
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.COLORS.NEUTRAL05,
  ...theme.typography.body3,
  overflow: "hidden",
  padding: theme.spacing(1.25, 2),
  textAlign: "left",
  color: theme.palette.COLORS.NEUTRAL9,
  fontFamily: ["Inter"],
  border: `1px solid ${theme.palette.COLORS.NEUTRAL2}`,
  "&:focus": {
    border: `1px solid ${theme.palette.COLORS.BLUE6}`,
    outline: "none",
  },
}));

export const CalenderContainer = styled(Box)(({ theme, ...props }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  filter: props.disabled && "opacity(40%)",
  ".calendarPopper": {
    top: "-8px !important",
    width: "100%",
  },
  ".react-datepicker": {
    width: "100%",
    padding: theme.spacing(1.5, 1.5, 2, 1.5),
    boxShadow: `0px 8px 20px 0px #21364826`,
    border: "none",
    borderRadius: theme.spacing(0.25),
  },
  ".react-datepicker__month-container": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  ".react-datepicker__header": {
    backgroundColor: theme.palette.COLORS.NEUTRAL0,
    borderBottom: "none",
    width: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  ".react-datepicker__day-names": {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1.25),
    marginBottom: 0,
  },
  ".react-datepicker__day-name": {
    fontFamily: ["Inter"],
    color: theme.palette.COLORS.NEUTRAL9,
    ...theme.typography.body6,
    margin: 0,
  },
  ".react-datepicker__month": {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  ".react-datepicker__week": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0, 1.25),
    marginBottom: 0,
    height: 29,
  },
  ".react-datepicker__day": {
    margin: 0,
    ...theme.typography.body6,
    lineHeight: "30px",
    height: 30,
  },
  ".react-datepicker__day--in-range": {
    position: "relative",
    borderRadius: 0,
    backgroundColor: theme.palette.COLORS.BLUE05,
    color: theme.palette.COLORS.NEUTRAL9,
    lineHeight: "21px",
    height: 21,
  },
  ".react-datepicker__day--in-range:before": {
    content: "''",
    position: "absolute",
    width: 13,
    backgroundColor: theme.palette.COLORS.BLUE05,
    zIndex: 1,
    height: 21,
    left: -13,
  },
  ".react-datepicker__day--in-range:after": {
    content: "''",
    position: "absolute",
    width: 13,
    backgroundColor: theme.palette.COLORS.BLUE05,
    zIndex: 1,
    height: 21,
    right: -13,
  },
  ".react-datepicker__day--range-start, .react-datepicker__day--range-end, .react-datepicker__day--range-end.react-datepicker__day--today":
    {
      backgroundColor: theme.palette.COLORS.BLUE6,
      position: "relative",
      color: theme.palette.COLORS.NEUTRAL0,
      height: 29,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: theme.spacing(0.5),
    },
  ".react-datepicker__day--range-start": {
    width: 30,
    "&::before": {
      display: "none",
    },
  },
  ".react-datepicker__day--range-end": {
    width: 30,
    "&::after": {
      display: "none",
    },
  },
  ".disabled-day": {
    color: theme.palette.COLORS.NEUTRAL4,
  },
  ".react-datepicker__day--in-selecting-range": {
    backgroundColor: theme.palette.COLORS.BLUE2,
    height: 21,
    lineHeight: "21px",
    "&.react-datepicker__day--outside-month": {
      color: theme.palette.COLORS.NEUTRAL0,
    },
  },

  ".react-datepicker__day--today": {
    backgroundColor: theme.palette.COLORS.NEUTRAL0,
    position: "relative",
    "&::after": {
      content: "''",
      position: "absolute",
      width: 6,
      backgroundColor: theme.palette.COLORS.BLUE6,
      zIndex: 1,
      height: 6,
      left: "50%",
      bottom: -6,
      transform: "translate(-50%,0)",
      borderRadius: "50%",
    },
  },
  ".react-datepicker__day--selected": {
    backgroundColor: theme.palette.COLORS.BLUE6,
    position: "relative",
    color: theme.palette.COLORS.NEUTRAL0,
    height: 29,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.spacing(0.5),
  },
  ".react-datepicker__day--today.react-datepicker__day--in-selecting-range": {
    backgroundColor: theme.palette.COLORS.BLUE2,
  },
  ".react-datepicker__day--outside-month": {
    color: theme.palette.COLORS.NEUTRAL4,
    "&.react-datepicker__day--in-range": {
      color: theme.palette.COLORS.NEUTRAL9,
      "&.react-datepicker__day--range-start, &.react-datepicker__day--range-end":
        {
          color: theme.palette.COLORS.NEUTRAL0,
        },
    },
  },
  ".react-datepicker__day--selecting-range-start": {
    backgroundColor: theme.palette.COLORS.BLUE6,
    position: "relative",
    color: theme.palette.COLORS.NEUTRAL0,
    height: 29,
    width: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.spacing(0.5),
  },
  ".react-datepicker__close-icon": {
    right: 50,
    "&::after": {
      backgroundColor: "transparent",
      content: `url(${xMark})`,
    },
  },
  ".react-datepicker__calendar-icon": {
    padding: 0,
  },
  ".react-datepicker__view-calendar-icon input": {
    padding: theme.spacing(1, 2),
  },
}));

export const CalendarHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  fontFamily: ["Inter"],
  color: theme.palette.COLORS.NEUTRAL9,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: 0,
}));
export const CalendarIcon = styled("img")(({ theme }) => ({
  width: 24,
  height: 24,
  right: 16,
  top: "50%",
  padding: 0,
  transform: "translate(0,-50%)",
  "&:hover": {
    cursor: "pointer",
  },
  pointerEvents: "none",
}));

export const CalendarButton = styled(({ position: _position, ...props }) => (
  <img {...props} alt={props.alt} />
))(({ position }) => ({
  height: 20,
  transform: position ? "rotate(90deg)" : "rotate(-90deg)",
}));

export const ErrorLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.COLORS.RED5,
  position: "absolute",
  top: 76,
  left: 0,
  textAlign: "left",
}));
