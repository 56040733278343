import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const deleteEntityDraft = (set) => async (id) => {
  try {
    set({ isLoading: true });
    await api.delete(`/compliances/${id}/`);
    set({ isLoading: false });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default deleteEntityDraft;
