import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

export const sendEmail = async (email, set) => {
  try {
    set({ isLoading: true });
    await api.post("/users/password_reset/", email);
    set({ isLoading: false });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};
