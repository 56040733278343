import { toast } from "sonner";
import Toaster from "../components/Toaster/Toaster";

export const errorApiHandler = (
  err,
  navigate,
  setError,
  customField,
  errorField,
  onRecall
) => {
  if (err.status === 404 && !err?.data?.detail) {
    navigate("/404", { state: { error: 404 } });
  }
  if (err.status === 403) {
    navigate("/403", { state: { error: 403 } });
  }
  if (err.status >= 500) {
    navigate(`/${err.status}`, { state: { error: 500 } });
  }
  if (err.status >= 400) {
    if (typeof err?.data?.detail === "string") {
      if (err.status === 404 && err?.data?.detail === "Invalid page.") {
        onRecall && onRecall();
        return;
      }
      toast.custom((t) => <Toaster t={t} message={err?.data?.detail} />);
      return;
    }
    if (err?.data?.non_field_errors) {
      toast.custom((t) => (
        <Toaster t={t} message={err?.data?.non_field_errors} />
      ));
    }
    if (!setError) {
      return null;
    }

    if (customField) {
      setError(customField, {
        type: "custom",
        message: err?.data?.[errorField],
      });
    } else {
      Object.entries(err?.data).forEach(([field, error]) =>
        setError(field, { type: "custom", message: error })
      );
    }
  }
};

export const errorFormater = (response) => {
  return {
    status: response.status,
    data: response.data,
  };
};
