import zoomIcon from "../../assets/icons/ZoomIcon.svg";
import { LabelContainer } from "../../pages/HomePage/AddQuerryPopup/AddQueryPopup.styles";
import {
  Address,
  Container,
  CustomCircularProgress,
  Entries,
  EntriesContainer,
  ImgContainer,
  InputLabel,
  StyledAutocomplete,
  StyledError,
  StyledInput,
  StyledPaper,
  Title,
} from "./Autocomplete.styles";
import { useDisclosure } from "../../hooks/useDisclosure";
import { Box } from "@mui/material";
import ChevronIcon from "../../assets/CustomIcons/ChevronIcon";
import { COLORS } from "../../theme/colors";

export default function CustomAutocomplete({
  label,
  name,
  options,
  isLoading,
  onChangeOption,
  onChange,
  clearFunction,
  showErrorMessage,
  error,
  refer,
  disabled,
  selectedAddress,
  ...props
}) {
  const autocompleteState = useDisclosure();
  return (
    <>
      {label ? (
        <LabelContainer>
          <InputLabel variant="body7" htmlFor={name}>
            {label}
          </InputLabel>
        </LabelContainer>
      ) : null}
      <StyledAutocomplete
        fullWidth
        disabled={disabled}
        open={autocompleteState.isOpen}
        forcePopupIcon={false}
        onOpen={autocompleteState.open}
        onClose={autocompleteState.close}
        filterOptions={(options, params) => {
          const { inputValue } = params;
          const isExisting = options.some(
            (option) => inputValue === option.street
          );
          if (
            inputValue !== "" &&
            !isExisting &&
            !isLoading &&
            !selectedAddress
          ) {
            options.push({
              title: "Add your own address",
              value: inputValue,
              street: "",
              city: "",
              state: "",
              zipcode: "",
            });
          }

          return options;
        }}
        isOptionEqualToValue={(option, value) => {
          return JSON.stringify(option) === JSON.stringify(value);
        }}
        getOptionLabel={(option) => {
          return option?.value || `${option.street}`;
        }}
        onInputChange={(_, _1, reason) => {
          if (reason === "clear" || reason === "reset") {
            clearFunction();
          }
        }}
        onChange={onChangeOption}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              onClick={(e, ...rest) => {
                props?.onClick(e, ...rest);
                if (option?.entries > 1) {
                  autocompleteState.open();
                }
              }}
              key={`${option?.street} ${option?.city}, ${option?.state} ${
                option?.zipcode
              } ${option?.secondary} ${Math.random()}`}
            >
              <Address
                title={
                  option?.title ||
                  `${option.street} ${option?.secondary} ${option.city}, ${option.state} ${option.zipcode}`
                }
              >
                {option?.title ||
                  `${option.street} ${option?.secondary} ${option.city}, ${option.state} ${option.zipcode}`}
              </Address>
              {option?.entries > 1 && (
                <EntriesContainer>
                  <Entries> {`+ ${option?.entries} addresses`}</Entries>
                  <ChevronIcon color={COLORS.BLUE6} />
                </EntriesContainer>
              )}
            </li>
          );
        }}
        PaperComponent={StyledPaper}
        options={options}
        loading={isLoading}
        loadingText={<Title>Loading...</Title>}
        noOptionsText={
          selectedAddress || isLoading ? (
            <Title>Loading...</Title>
          ) : (
            <Container>
              <ImgContainer>
                <img src={zoomIcon} alt={"zoomIcon"} />
              </ImgContainer>
              <Title>No Data Found...</Title>
            </Container>
          )
        }
        renderInput={(params) => (
          <Box position={"relative"}>
            <StyledInput
              {...params}
              inputRef={refer}
              placeholder="Address"
              error={!!error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CustomCircularProgress size={22} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              inputProps={{
                ...params.inputProps,
                value: props.value,
              }}
              onChange={onChange}
            />
            {showErrorMessage && (
              <StyledError variant="small1">{error?.message}</StyledError>
            )}
          </Box>
        )}
      />
    </>
  );
}
