import dayjs from "dayjs";
import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const createEntity = (set) => async (data, is_draft) => {
  const entity = {
    ...data,
  };
  Object.entries(entity).forEach(([key, value]) => {
    if (key.startsWith("is_") && value === "") {
      delete entity[key];
    }
  });
  if (!entity.law_added_at) {
    delete entity.law_added_at;
  } else {
    entity.law_added_at = dayjs(entity.law_added_at).format("YYYY-MM-DD");
  }
  try {
    set({ isLoading: true });
    const { data } = await api.post("/compliances/", {
      ...entity,
      is_draft: !!is_draft,
    });
    set({ isLoading: false });
    return data;
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default createEntity;
