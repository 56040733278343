import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";

const overrideData = {
  is_str_allowed: true,
  is_permit_required: true,
  is_owner_occupancy_required: true,
  owner_occupancy_required_notes: true,
  is_entity_limits_number_permit: true,
  entity_limits_number_permit_notes: true,
  is_limit_length_stay: true,
  limit_length_stay_notes: true,
  is_license_transferability: true,
  license_transferability_notes: true,
  is_entity_limits_permit_owner: true,
  entity_limits_permit_owner_notes: true,
  is_zone_restricted: true,
  zone_restriction_notes: true,
  notes: true,
};

const overrideEntity =
  (set) =>
  async ({ id, type, data }) => {
    const entity = {
      ...data,
    };
    Object.entries(entity).forEach(([key, value]) => {
      if (!overrideData[key]) {
        delete entity[key];
      }
    });

    try {
      set({ isLoading: true });
      await api.post(`/responses/${id}/override/${type}/`, data);
      set({ isLoading: false });
    } catch (error) {
      set({ isLoading: false });
      throw errorFormater(error.response);
    }
  };

export default overrideEntity;
