import { Box, Typography, styled } from "@mui/material";
import { COLORS } from "../../theme/colors";

const statuses = {
  progress: {
    color: COLORS.YELLOW3,
    text: "In progress",
  },
  complete: {
    color: COLORS.GREEN3,
    text: "Complete",
  },
  received: {
    color: COLORS.BLUE3,
    text: "Received",
  },
};
export const Status = ({ status }) => {
  return (
    <Container>
      <Point color={statuses[status].color} />
      <Text variant="body4">{statuses[status].text}</Text>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));
const Text = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    ...theme.typography.body7,
  },
}));

export const Point = styled(({ color: _color, ...props }) => (
  <Box {...props} />
))(({ theme, color }) => ({
  minHeight: theme.spacing(2),
  minWidth: theme.spacing(2),
  height: theme.spacing(2),
  width: theme.spacing(2),
  backgroundColor: color ?? theme.palette.COLORS.YELLOW3,
  borderRadius: theme.spacing(1),
}));
