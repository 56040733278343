import { Box } from "@mui/material";
import { ModalDialog } from "../../../../components/ModalDialog/ModalDialog";
import {
  ButtonContainer,
  Subtitle,
} from "../../../CompliancePage/DeleteEntityPopup/DeleteEntityPopup.styles";
import { LAButton } from "../../../../components/LAButton/LAButton";

export const ConfirmSubmitModal = ({
  onClose,
  onConfirm,
  isLoading,
  submitResponse,
}) => {
  return (
    <ModalDialog
      title={
        submitResponse
          ? "Are you sure you want to proceed with saving the changes to the report?"
          : "Are you sure you want to submit the report?"
      }
      onClose={onClose}
      width={712}
    >
      <Box mt={-0.5}>
        <Subtitle>
          {submitResponse
            ? "Please note that once saved, the client will automatically receive an email notification regarding this modification."
            : `Once submitted, the report will be forwarded to the client. Please
          ensure that all necessary information has been included and that you
          are ready to proceed.`}
        </Subtitle>
        <Box
          display={"flex"}
          gap={2.5}
          width={"100%"}
          justifyContent={"center"}
          mt={5}
          mb={8}
        >
          <ButtonContainer>
            <LAButton
              text="Cancel"
              variant="secondary"
              fullWidth
              onClick={onClose}
              loading={isLoading}
            />
          </ButtonContainer>
          <ButtonContainer>
            <LAButton
              text="Confirm"
              fullWidth
              onClick={onConfirm}
              loading={isLoading}
            />
          </ButtonContainer>
        </Box>
      </Box>
    </ModalDialog>
  );
};
