import { Box } from "@mui/material";
import { ModalDialog } from "../../../components/ModalDialog/ModalDialog";
import { ButtonModalContainer } from "./NotesSection.styles";
import { LAButton } from "../../../components/LAButton/LAButton";

export const DeleteNotePopup = ({ onClose, onDelete }) => {
  return (
    <ModalDialog
      title="Are you sure you want to delete
      this note?"
      onClose={onClose}
      width={617}
    >
      <Box mt={-0.5}>
        <Box
          display={"flex"}
          gap={2.5}
          width={"100%"}
          justifyContent={"center"}
          mt={5}
          mb={8}
        >
          <ButtonModalContainer>
            <LAButton
              text="Cancel"
              variant="secondary"
              fullWidth
              onClick={onClose}
            />
          </ButtonModalContainer>
          <ButtonModalContainer>
            <LAButton text="Delete" fullWidth onClick={onDelete} />
          </ButtonModalContainer>
        </Box>
      </Box>
    </ModalDialog>
  );
};
