import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const updatedConfig = { ...config };
  const accessToken = localStorage.getItem("@Auth:access");

  if (accessToken) {
    updatedConfig.headers.Authorization = `Bearer ${accessToken}`;
  }

  return updatedConfig;
});

const handleErrorResponse = async (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      if (error.response.data?.code === "user_inactive") {
        localStorage.removeItem("@Auth:access");
        localStorage.removeItem("@Auth:refresh");
        window.location.href = "/signin";
      }

      if (!error.response.config.url.includes("/token/refresh/")) {
        const refreshToken = localStorage.getItem("@Auth:refresh");

        if (refreshToken) {
          try {
            const response = await api.post("/token/refresh/", {
              refresh: refreshToken,
            });
            const { access } = response.data;
            localStorage.setItem("@Auth:access", access);

            const updatedRequest = { ...error.config };
            updatedRequest.headers.Authorization = `Bearer ${access}`;

            return axios(updatedRequest);
          } catch (error) {
            localStorage.removeItem("@Auth:access");
            localStorage.removeItem("@Auth:refresh");
            window.location.href = "/signin";
          }
        }
      }
    }
  }
  return Promise.reject(error);
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return handleErrorResponse(error);
  }
);

export default api;
