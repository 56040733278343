import {
  AdditionalHeader,
  AdditionalHeaderText,
  Header,
  HeaderText,
  RegularDialogStyled,
  StyledIconButton,
  Subheader,
  XButton,
} from "./ModalDialog.styles";
import Xmark from "../../assets/icons/XMarkBig.svg";
import { CircularProgress } from "@mui/material";
import { StyledLoading } from "./ModalDialog.styles";

export const ModalDialog = ({
  title,
  subheader,
  onClose,
  children,
  additionalTopHeader,
  width,
  isLoading,
}) => {
  return (
    <RegularDialogStyled open width={width} isLoading={isLoading}>
      {additionalTopHeader ? (
        <AdditionalHeader>
          <AdditionalHeaderText>{additionalTopHeader}</AdditionalHeaderText>
        </AdditionalHeader>
      ) : null}

      <Header>
        <StyledIconButton onClick={onClose}>
          <XButton src={Xmark} alt="menuIcon" />
        </StyledIconButton>
        <HeaderText variant="h2">{title}</HeaderText>
        <Subheader>{subheader}</Subheader>
      </Header>
      {children}
      {isLoading ? (
        <StyledLoading>
          <CircularProgress color="primary" />
        </StyledLoading>
      ) : null}
    </RegularDialogStyled>
  );
};
