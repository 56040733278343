export const isFileIsImage = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const arr = new Uint8Array(e.target.result).subarray(0, 12);
      let header = "";
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16).padStart(2, "0");
      }
      let type = "unknown";

      if (header.startsWith("89504e47")) {
        type = "image/png";
      } else if (header.startsWith("ffd8")) {
        type = "image/jpeg";
      } else if (header.startsWith("47494638")) {
        type = "image/gif";
      } else if (header.startsWith("52494646")) {
        const subArr = new Uint8Array(e.target.result).subarray(8, 12);
        let subHeader = "";
        for (let i = 0; i < subArr.length; i++) {
          subHeader += subArr[i].toString(16).padStart(2, "0");
        }
        if (subHeader.startsWith("57454250")) {
          type = "image/webp";
        }
      }

      resolve(type !== "unknown");
    };
    reader.readAsArrayBuffer(file);
  });
};
