import api from "../../../services/api";
import { errorFormater } from "../../../services/errorHandler";
import { COMPLIANCE_PER_PAGE } from "../state";

const getList = (set, get) => async () => {
  const { page, sortingField, sortingOrder, searchField } = get();
  const sorting =
    sortingField === "default"
      ? ""
      : `${sortingOrder ? "-" : ""}${sortingField}`;
  try {
    set({ isLoading: true });

    const response = await api.get(`/compliances/`, {
      params: {
        page,
        order_by: sorting,
        q: searchField,
      },
    });

    const { count: totalEntities, results } = response.data;
    set({
      totalEntities,
      pageCount: Math.ceil(totalEntities / COMPLIANCE_PER_PAGE),
      entities: results,
      rows: results.map((entity) => ({
        is_active: entity.is_active,
        id: entity.id,
      })),
      isLoading: false,
    });
  } catch (error) {
    set({ isLoading: false });
    throw errorFormater(error.response);
  }
};

export default getList;
