import { forwardRef } from "react";

import { Box, ButtonBase, CircularProgress, styled } from "@mui/material";
import {
  BORDER_CLICKED_COLOR,
  BORDER_COLOR,
  BORDER_HOVER_COLOR,
  BORDER_SIZE,
  BORDER_DISABLED_COLOR,
  BUTTON_CLICKED_COLOR,
  BUTTON_COLOR,
  BUTTON_DISABLED_COLOR,
  BUTTON_HEIGHT,
  BUTTON_HOVER_COLOR,
  TEXT_CLICKED_COLOR,
  TEXT_COLOR,
  TEXT_COLOR_DISABLED,
  TEXT_HOVER_COLOR,
} from "./constants";

export const LAButton = forwardRef(
  (
    {
      text,
      disabled,
      size = "lg",
      variant = "primary",
      fullWidth,
      loading,
      icon,
      children,
      loadingPercent,
      ...rest
    },
    forwardedRef
  ) => {
    const propsToAdd = loadingPercent
      ? { value: loadingPercent, variant: "determinate" }
      : {};
    return (
      <StyledButton
        buttonVariant={variant}
        buttonSize={size}
        fullWidth={fullWidth}
        disabled={disabled || loading}
        loading={loading}
        {...rest}
        ref={forwardedRef}
      >
        {loading ? (
          <>
            <StyledCircularProgress size={20} {...propsToAdd} />
            {loadingPercent && (
              <StyledButtonText>
                {`${loadingPercent?.toFixed(2)} %`}
              </StyledButtonText>
            )}
          </>
        ) : children ? (
          children
        ) : (
          <>
            {!!icon ? icon : null}
            {text ? <StyledButtonText>{text}</StyledButtonText> : null}
          </>
        )}
      </StyledButton>
    );
  }
);

const BaseButton = forwardRef(
  (
    {
      as,
      buttonVariant: _buttonVariant,
      buttonSize: _buttonSize,
      fullWidth: _fullWidth,
      loading: _loading,
      ...props
    },
    forwardedRef
  ) => <ButtonBase {...props} component={as} ref={forwardedRef} disableRipple />
);

const StyledButton = styled(BaseButton)(
  ({ theme, buttonVariant, buttonSize, fullWidth, loading }) => {
    const variant = buttonVariant.toUpperCase();
    const size = buttonSize.toUpperCase();

    const borderSize = BORDER_SIZE[variant];
    const borderSizeHover = BORDER_SIZE[variant];
    const defaultBorder = "1px solid transparent";

    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      width: fullWidth ? "100%" : "auto",
      height: BUTTON_HEIGHT[size],
      backgroundColor: BUTTON_COLOR[variant],
      border: borderSize
        ? `${borderSize}px solid ${BORDER_COLOR[variant]}`
        : defaultBorder,
      color: TEXT_COLOR[variant],
      borderRadius: 8,
      textAlign: "center",
      verticalAlign: "middle",
      padding: "8px 16px",
      gap: theme.spacing(1.5),
      "&:hover": {
        backgroundColor: BUTTON_HOVER_COLOR[variant],
        border: borderSizeHover
          ? `${BORDER_SIZE[variant]}px solid ${BORDER_HOVER_COLOR[variant]}`
          : defaultBorder,
        color: TEXT_HOVER_COLOR[variant],
      },
      "&:active": {
        backgroundColor: BUTTON_CLICKED_COLOR[variant],
        color: TEXT_CLICKED_COLOR[variant],
        border: borderSize
          ? `${borderSize}px solid ${BORDER_CLICKED_COLOR[variant]}`
          : defaultBorder,
      },
      ...(!loading && {
        "&:disabled": {
          backgroundColor: BUTTON_DISABLED_COLOR[variant],
          border: BORDER_DISABLED_COLOR[variant] || defaultBorder,
          color: TEXT_COLOR_DISABLED[variant],
        },
      }),
    };
  }
);

const StyledCircularProgress = styled(CircularProgress)({
  color: "inherit",
});

const StyledButtonText = styled(Box)(({ theme }) => ({
  textDecoration: "none",
  ...theme.typography.body2,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.body5,
  },
}));
