import { Box } from "@mui/material";
import {
  ButtonContainer,
  Subtitle,
} from "../../CompliancePage/DeleteEntityPopup/DeleteEntityPopup.styles";
import { ModalDialog } from "../../../components/ModalDialog/ModalDialog";
import { LAButton } from "../../../components/LAButton/LAButton";

export const ExportReportsPopup = ({ onClose, onExport, isLoading }) => {
  return (
    <ModalDialog title="Export Reports" onClose={onClose} width={712}>
      <Box mt={-0.5}>
        <Subtitle>
          You are about to download all completed reports. Please note that this
          action may take some time to complete due to the volume of reports.
          Are you sure you want to proceed?
        </Subtitle>
        <Box
          display={"flex"}
          gap={2.5}
          width={"100%"}
          justifyContent={"center"}
          mt={5}
          mb={8}
        >
          <ButtonContainer>
            <LAButton
              text="Cancel"
              variant="secondary"
              fullWidth
              onClick={onClose}
              loading={isLoading}
            />
          </ButtonContainer>
          <ButtonContainer>
            <LAButton
              text="Export"
              fullWidth
              onClick={onExport}
              loading={isLoading}
            />
          </ButtonContainer>
        </Box>
      </Box>
    </ModalDialog>
  );
};
