import { useCallback, useMemo, useState } from "react";

export const useDisclosure = (initialState = false, callbacks = {}) => {
  const { onOpen, onClose } = callbacks;
  const [isOpen, setIsOpen] = useState(initialState);

  const open = useCallback(() => {
    setIsOpen(true);

    onOpen?.();
  }, [onOpen]);

  const close = useCallback(() => {
    setIsOpen(false);

    onClose?.();
  }, [onClose]);

  const toggle = useCallback(() => {
    isOpen ? close() : open();
  }, [close, isOpen, open]);

  return useMemo(
    () => ({ isOpen, open, close, toggle }),
    [close, isOpen, open, toggle]
  );
};
