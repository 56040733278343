import { Box, styled } from "@mui/material";
import { HEADER_HEIGHT } from "../styles";
import { Header } from "../components/Header/Header";

const PagesLayout = ({ children }) => {
  return (
    <>
      <Header withBorder={false} />
      <Container>{children}</Container>
    </>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  minHeight: `calc(100svh - ${HEADER_HEIGHT})`,
  padding: theme.spacing(8),
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(7, 6),
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(6, 5),
  },
}));

export default PagesLayout;
