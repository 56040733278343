import * as yup from "yup";

export const reportScheme = yup.object().shape({
  city: yup.string(),
  county: yup.string(),
  state: yup.string(),
  hoa: yup.string(),
  city_has_permit: yup.string(),
  county_has_permit: yup.string(),
  state_has_permit: yup.string(),
  hoa_has_permit: yup.string(),
  city_permit_expiry_at: yup.string().nullable(),
  county_permit_expiry_at: yup.string().nullable(),
  state_permit_expiry_at: yup.string().nullable(),
  hoa_permit_expiry_at: yup.string().nullable(),
  city_notes: yup.string().max(2000, "Max 2000 characters"),
  county_notes: yup.string().max(2000, "Max 2000 characters"),
  state_notes: yup.string().max(2000, "Max 2000 characters"),
  hoa_notes: yup.string().max(2000, "Max 2000 characters"),
});

export const defaultReport = {
  city: "",
  county: "",
  state: "",
  hoa: "",
  city_has_permit: "",
  county_has_permit: "",
  state_has_permit: "",
  hoa_has_permit: "",
  city_permit_expiry_at: "",
  county_permit_expiry_at: "",
  state_permit_expiry_at: "",
  hoa_permit_expiry_at: "",
  city_notes: "",
  county_notes: "",
  state_notes: "",
  hoa_notes: "",
};
